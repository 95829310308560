#about>#header>.l-header>.l-header-desktop>.l-container-fluid>.l-row>.l-header-desktop__right>.l-header-desktop__right__inner>.l-header-desktop__nav>div>ul>li>a {
  color: #000000;
}

#project_details>#header>.l-header>.l-header-desktop>.l-container-fluid>.l-row>.l-header-desktop__right>.l-header-desktop__right__inner>.l-header-desktop__nav>div>ul>li>a {
  color: #000000;
}

#service-details>#header>.l-header>.l-header-desktop>.l-container-fluid>.l-row>.l-header-desktop__right>.l-header-desktop__right__inner>.l-header-desktop__nav>div>ul>li>a {
  color: #000000;
}

#offshore>#header>.l-header>.l-header-desktop>.l-container-fluid>.l-row>.l-header-desktop__right>.l-header-desktop__right__inner>.l-header-desktop__nav>div>ul>li>a {
  color: #000000;
}

#project>#header>.l-header>.l-header-desktop>.l-container-fluid>.l-row>.l-header-desktop__right>.l-header-desktop__right__inner>.l-header-desktop__nav>div>ul>li>a {
  color: #000000;
}

#news_details>#header>.l-header>.l-header-desktop>.l-container-fluid>.l-row>.l-header-desktop__right>.l-header-desktop__right__inner>.l-header-desktop__nav>div>ul>li>a {
  color: #000000;
}

#contact>#header>.l-header>.l-header-desktop>.l-container-fluid>.l-row>.l-header-desktop__right>.l-header-desktop__right__inner>.l-header-desktop__nav>div>ul>li>a {
  color: #000000;
}

#news>#header>.l-header>.l-header-desktop>.l-container-fluid>.l-row>.l-header-desktop__right>.l-header-desktop__right__inner>.l-header-desktop__nav>div>ul>li>a {
  color: #000000;
}

#service>#header>.l-header>.l-header-desktop>.l-container-fluid>.l-row>.l-header-desktop__right>.l-header-desktop__right__inner>.l-header-desktop__nav>div>ul>li>a {
  color: #000000;
}

#solution>#header>.l-header>.l-header-desktop>.l-container-fluid>.l-row>.l-header-desktop__right>.l-header-desktop__right__inner>.l-header-desktop__nav>div>ul>li>a {
  color: #000000;
}

#about>#header>.l-header>.l-header-desktop>.l-container-fluid>.l-row>.l-header-desktop__right>.l-header-desktop__right__inner>.l-header-desktop__nav>div>div>a {
  font-size: 14px;
  color: #000000;
}

#service>#header>.l-header>.l-header-desktop>.l-container-fluid>.l-row>.l-header-desktop__right>.l-header-desktop__right__inner>.l-header-desktop__nav>div>div>a {
  font-size: 14px;
  color: #000000;
}

#news>#header>.l-header>.l-header-desktop>.l-container-fluid>.l-row>.l-header-desktop__right>.l-header-desktop__right__inner>.l-header-desktop__nav>div>div>a {
  font-size: 14px;
  color: #000000;
}

#contact>#header>.l-header>.l-header-desktop>.l-container-fluid>.l-row>.l-header-desktop__right>.l-header-desktop__right__inner>.l-header-desktop__nav>div>div>a {
  font-size: 14px;
  color: #000000;
}

#project>#header>.l-header>.l-header-desktop>.l-container-fluid>.l-row>.l-header-desktop__right>.l-header-desktop__right__inner>.l-header-desktop__nav>div>div>a {
  font-size: 14px;
  color: #000000;
}

#offshore>#header>.l-header>.l-header-desktop>.l-container-fluid>.l-row>.l-header-desktop__right>.l-header-desktop__right__inner>.l-header-desktop__nav>div>div>a {
  font-size: 14px;
  color: #000000;
}

#service-details>#header>.l-header>.l-header-desktop>.l-container-fluid>.l-row>.l-header-desktop__right>.l-header-desktop__right__inner>.l-header-desktop__nav>div>div>a {
  font-size: 14px;
  color: #000000;
}

#project_details>#header>.l-header>.l-header-desktop>.l-container-fluid>.l-row>.l-header-desktop__right>.l-header-desktop__right__inner>.l-header-desktop__nav>div>div>a {
  font-size: 14px;
  color: #000000;
}

#service-details {
  background-color: #fff;
}

#service-details>.bgrColor {
  background-color: #fff;
}

#service>.bgrColor {
  background-color: #fff;
}

#about>.bgrColor {
  background-color: #fff;
}

#news_details>.bgrColor {
  background-color: #fff;
}

#news>.bgrColor {
  background-color: #fff;
}

#contact>.bgrColor {
  background-color: #fff;
}

#solution>.bgrColor {
  background-color: #fff;
}

#project>.bgrColor {
  background-color: #fff;
}

#offshore>.bgrColor {
  background-color: #fff;
}

#project_details>.bgrColor {
  background-color: #fff;
}

#home>.bgrColor {
  background-color: #fff;
}

#home>.bgrColor>.l-header>.l-header-desktop>.l-container-fluid>.l-row>.l-header-desktop__right>.l-header-desktop__right__inner>.l-header-desktop__nav>.l-header-desktop__menu>ul>li>a {
  color: #000;
}

#solution,
#project,
#home,
#news,
#contact {
  background: #fff !important;
}

/* ul {
    padding: 0 !important;
    margin: 0 !important;
} */

/* #home>#header>.l-header>.l-header-desktop>.l-container-fluid>.l-row>.l-header-desktop__right>.l-header-desktop__right__inner>.l-header-desktop__nav>div>div>a {
    font-size: 14px;
    color: #000000;
} */

#home>.bgrColor>.l-header>.l-header-desktop>.l-container-fluid>.l-row>.l-col-auto>img {
  /* background: url("/assets/img/slider/fig_logo_white_a.png") !important; */
}

#home .r-contact {
  padding: 0 0 100px 0;
}

#home .r-office-tcom {
  margin-bottom: 120px !important;
}

@media (max-width: 1199px) {
  #project>#header>.l-header>.l-header-mobile {
    background-color: #fff;
  }
}

.btn-slider-home {
  justify-content: space-between;
  align-items: center !important;
  background: none !important;
  width: 170px !important;
  border-radius: 2px;
  margin: 0 !important;
  padding: 0 !important;
}

.btn-slider-home>p {
  font-weight: 700 !important;
  font-size: 20px !important;
  line-height: 24px !important;
  /* identical to box height, or 120% */

  text-align: center !important;

  color: #ffffff !important;
}

.l-header-desktop__menu>ul {
  margin: 0;
  font-size: 14px;
}

.p-home-content {
  background-color: #f5f5f5;
}

.p-home_content--img {
  background-color: #fff !important;
  background: url('/assets/img/slider/dot_map.png');
  background-size: contain;
  background-position: top;
  background-repeat: no-repeat;
  width: 100%;
  height: auto;
}

.p-home_txt {
  padding: 50px 0 0 0;
  height: auto;
  margin: auto;
}

.p-home_txt--img>div {
  width: 100%;
}

.p-home_txt--img {
  position: absolute;
  background: linear-gradient(0deg,
      rgba(8, 62, 254, 0.15),
      rgba(8, 62, 254, 0.15)),
    linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url('/assets/img/slider/img_text-min.png');
  filter: blur(2px);
  border-radius: 32px;
  width: 80%;
  margin: auto;
  max-width: 1500px;
  height: 481px;
  background-repeat: no-repeat;
  left: 50%;
  transform: translateX(-50%);
}

.p-home_txt--img-text {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.p-home__text {
  width: 1103px;
  font-size: 32px;
  text-align: center;
  line-height: 40px;
  height: 160px;
  font-weight: 700;
  padding-top: 60px;
}

.p-home__icon-items {
  width: 100%;
  margin: auto;
  display: flex;
  max-width: 800px;
  justify-content: space-between;
}

.p-home__icon {
  padding-top: 95px;
}

.p-home__icon-item>div>p {
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  margin-top: 20px;
  line-height: 32px;
}

.p-home__icon-item {
  width: 250px;
  height: 250px;
  text-align: center;
}

.p-home__our-service {
  background-color: #fff;
  width: 1100px;
  margin: auto;
  padding-bottom: 83px;
}

.p-home__our-service-title>h1 {
  padding-top: 100px;
  position: relative;
  font-weight: 900;
  font-size: 40px;
  line-height: 47px;
  color: #0063aa;
}

.p-home__our-service-title>h1::after {
  position: absolute;
  content: '';
  background-color: #0063aa;
  width: 48px;
  height: 4px;
  border-radius: 30px;
  left: 0;
  bottom: -20px;
}

.p-home__our-service-title>div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 45px;
  padding-bottom: 20px;
  width: 1110px;
}

.p-home__our-service-title>div>p {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  color: #434343;
}

.p-home__our-service-read-icon {
  display: flex;
  align-items: center;
  margin-top: 25px;
  margin-bottom: 25px;
}

.p-home__our-service-read-icon>p {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */

  color: #0063aa;
  text-align: center;
  margin-right: 20px;
}

.p-home__our-service-read-icon>img {
  cursor: pointer;
}

.p-home__our-service-items {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 60px;
  width: calc(372px * 3);
  margin-right: auto;
  margin-left: auto;
}

.p-home__our-service-item {
  /* margin: 4px; */
  padding: 17px;
  background: #ffffff;

  /* border-radius: 9px; */
  /* width: calc(100%/3 - 6px); */
  width: 364px;
  height: 240px;
  cursor: pointer;
  transition: 0.5s;
}

.language_technology:nth-child(1),
.language_technology:nth-child(3),
.language_technology:nth-child(5) {
  margin: 7px 7px 7px 0;
}

.language_technology:nth-child(2),
.language_technology:nth-child(4),
.language_technology:nth-child(6) {
  margin: 7px 0px 7px 7px;
}

.p-home__our-service-item-icon {
  width: 57.21px;
  height: 57.21px;
}

.p-home__our-service-item-icon>img {
  width: 100%;
}

.p-home__our-service-item:hover {
  border: 1px solid #0063aa;
  box-shadow: 1px 5px 18px 4px rgba(156, 156, 156, 0.25);
}

.p-home__our-service-item-text>p {
  font-weight: 700;
  font-size: 20px;
  line-height: 22px;

  /* #121212 */

  color: #121212;
  margin: 12px 0 8px 0;
}

.p-home__our-service-item-des>p {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* or 143% */

  /* #686868 */
  margin-bottom: 15px;
  color: #686868;
}

.p-home__our-service-item-des>ul {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* or 143% */

  /* #686868 */
  padding-left: 24px !important;
  margin-bottom: 15px !important;
  color: #686868;
}

.p-home__our-service-item-des>ul>li {
  list-style-type: disc;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  padding: 2px 0;
  /* or 143% */

  /* #686868 */

  color: #686868;
}

.p-home__our-service-item-btn {
  display: flex;
  align-items: center;
  margin-top: 10px;
  cursor: pointer;
}

.p-home__our-service-item-btn>p {
  font-weight: 900;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  text-align: center;
  letter-spacing: 0.08em;
  /* text-transform: uppercase; */

  color: #0063aa;
  margin-right: 15px;
}

.p-home__our-service-item-btn>a {
  font-weight: 900;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  text-align: center;
  letter-spacing: 0.08em;
  text-transform: uppercase;

  color: #0063aa;
  margin-right: 15px;
}

.p-home__our-service-item-btn>div {
  width: 33px;
  display: flex;
  align-items: center;
}

.p-home__our-service-item-btn>div {
  text-align: center;
  align-items: center;
}

.p-home__our-service-item-btn>div>img {
  width: 100%;
}

.p-home__our-service-methods-item-title {
  /* height: 90px; */
}

.p-home__our-service-methods-item-title>p {
  display: inline-block;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #0063aa;
  padding: 12px 0px 0px 0;
  margin-bottom: 0;
  /* border-bottom: 2px solid #FCDF02; */
}

.p-home__our-service-methods-item-title>img {
  display: flex;
  margin-bottom: 8px;
}

.p-home__our-service-methods-item-title>p,
.p-home__our-service-methods-item-des>p {
  /* margin-left: 10px; */
}

/* 
.p-home__our-service-methods-item-title>p::after {
    position: absolute;
    content: "";
    width: 72.2px;
    height: 2px;
    background: #0063A9;
    bottom: -2px;
    left: 0;
}

.p-home__our-service-methods-item-title>p::before {
    position: absolute;
    content: "";
    width: 124.2px;
    height: 2px;
    background: #EC2726;
    bottom: -2px;
    left: 40%;
    transform: translateX(-50%);

} */

.p-home__our-service-methods-item-des>p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #0063aa;
  width: 245px;
  padding: 0px 0px 24px 0;
  margin-bottom: 0;
}

.p-home__our-service-methods-item {
  display: flex;
  /* align-items: center; */
}

.p-home__our-service-methods-item:nth-child(1) .p-home__our-service-methods-item-des>p {
  max-width: 194px;
}

.p-home__our-service-methods-items {
  position: relative;
  background: #f8fcff;
  border: 1px solid #0063aa;
  border-radius: 8px;
  /* padding: 20px 20px 0 20px; */
  display: flex;
  justify-content: space-between;
  /* width: 100%; */
  width: 1110px;
  max-width: 1110px;
  height: 325px;
}

.p-home__our-service-methods-item-icon {
  width: 68px;
  height: 68px;
}

.p-home__our-service-methods-items::after {
  position: absolute;
  content: '';
  width: 1px;
  height: 130px;
  background-color: #0063aa;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.p-home__our-service-methods-item {
  padding: 32px 0 32px 32px;
  width: calc(100% / 2);
}

.img-service {
  display: flex;
  align-items: center;
}

.p-home__our-service-methods-item>div {
  width: 50%;
}

.p-home__our-service-methods-item>.img-service {
  width: 45% !important;
}

.p-home__procedure-content {
  margin-top: 80px;
  position: relative;
  background-color: #0063aa;
  height: 980px;
  display: flex;
}

.p-home__procedure-content-left {
  position: relative;
  width: 50%;
}

.p-home__procedure-content-left>.img-procedure-1 {
  position: absolute;
  left: 50px;
  top: 0;
}

.p-home__procedure-content-left>.img-procedure-2 {
  position: absolute;
  left: 50px;
  bottom: 0;
  left: 0;
}

.p-home__procedure-content-left-text {
  position: absolute;
  right: 80px;
  top: 45px;
}

.p-home__procedure-content-left-text>.p-home__procedure-content-left-text-title {
  position: relative;
  font-weight: 700;
  font-size: 40px;
  line-height: 47px;
  /* identical to box height */
  text-align: start;
  text-transform: uppercase;
  color: #ffffff;
}

.p-home__procedure-content-left-text>.p-home__procedure-content-left-text-title::after {
  position: absolute;
  content: '';
  width: 48px;
  height: 4px;
  bottom: -15px;
  left: 0;
  background: #ffffff;
  border-radius: 8px;
}

.p-home__procedure-content-left-text>p {
  padding-top: 30px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #ffffff;
}

.p-home__procedure-content-bar {
  position: absolute;
  content: '';
  width: 1px;
  height: 100%;
  background-color: #ffffff;
  left: 50%;
  transform: translateX(-50%);
}

.p-home__procedure-content-right {
  height: 100%;
  position: absolute;
  width: 50%;
  right: 50%;
  top: 50%;
  transform: translate(95%, -50%);
  padding-left: 10px;
}

.p-home__procedure-content-right-content {
  cursor: pointer;
  height: calc(100% / 6);
  display: flex;
  align-items: center;
  width: 165px;
  transition: 0.5s;
}

.p-home__procedure-content-right-content-number>p {
  font-weight: 700;
  font-size: 48px;
  line-height: 56px;
  color: #ffffff;
}

.p-home__procedure-content-right-content-text {
  padding-left: 10px;
  border-left: 3.10315px solid #ffffff;
}

.p-home__procedure-content-right-content-text>h1 {
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #ffffff;
  padding-bottom: 10px;
}

.number-three>p {
  font-weight: 700;
  font-size: 87px !important;
  line-height: 102px;
  /* identical to box height */
  color: #ffffff;
}

.text-three>h1 {
  font-size: 32px;
}

.text-three>p {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  /* #E2E2E2 */

  color: #e2e2e2;
}

.p-home__procedure-content-right-content-text>p {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  width: 265px;
}

.p-home__procedure-content-right-content-number {
  margin: 0 25px;
}

.bg-three {
  margin: 25px 0 25px -25px;
  height: 126px;
  width: 635px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 500px;
}

.language_technology {
  position: relative;
  width: calc(100% / 2 - 10px);
  border-radius: 8px;
  height: 224px;
  max-height: 292px;
  padding: 18.18px 35.29px 0 35.29px;
  border: 1px solid #0063aa;
  background: #f8fcff;
}

.language_technologys {
  position: relative;
  width: calc(100% + 10px);
  border-radius: 8px;
  height: 224px;
  max-height: 292px;
  padding: 18.18px 35.29px 0 35.29px;
  border: 1px solid #0063aa;
  background: #f8fcff;
  margin-bottom: 8px;
}

.lg-img {
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.language_technology>.p-home__our-service-item-text>p {
  margin: 0;
  font-weight: 700;
  font-size: 32px;
  line-height: 25px;
  /* identical to box height, or 175% */
  letter-spacing: -0.02em;
  color: #0063aa;
  line-height: 56px;
}

.language_technologys>.p-home__our-service-item-text>p {
  margin: 0;
  font-weight: 700;
  font-size: 32px;
  line-height: 25px;
  /* identical to box height, or 175% */
  letter-spacing: -0.02em;
  color: #0063aa;
  line-height: 56px;
}

.lg-and-tech {
  /* margin-top: 10px; */
  /* padding: 15px 15px 0 15px; */
  display: flex;
  /* justify-content: space-between; */
  flex-wrap: wrap;
  width: 100%;
  padding-top: 22.24px;
}

.lg-and-tech-container {
  /* margin-top: 15px; */
  width: calc(100% / 5);
  text-align: center;
  padding: 5px;
}

.lg-and-tech-containers {
  /* margin-top: 15px; */
  width: calc(100% / 10 - 4px);
  text-align: center;
  padding: 5px;
}

.lg-and-tech-containers:nth-child(1)>p {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;

  color: #000000;
}

.lg-and-tech-containers:nth-child(2)>p {
  font-size: 24px;
  line-height: 28px;
}

.lg-and-tech-containers:nth-child(3)>p {
  font-size: 24px;
  line-height: 28px;
}

.lg-and-tech-container>p {
  /* padding-top: 15px; */
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */
  color: #000000;
  margin-bottom: 0px;
}

.lg-and-tech-container>div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
}

.lg-and-tech-container>div>img {
  margin-bottom: 20px;
}

.p-home__our-service-content-project {
  display: flex;
  justify-content: space-between;
}

.p-home__our-service-content-project-left>a {
  margin: unset;
}

.p-home__our-service-content-project-left>a>p {
  padding-right: unset !important;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500 !important;
  font-size: 20px !important;
  line-height: 24px !important;
  /* identical to box height, or 120% */

  text-align: center !important;

  color: #0063aa !important;
}

.p-home__our-service-content-project-left>a>div>img {
  margin-bottom: unset !important;
  margin-top: unset !important;
  height: auto !important;
}

.p-home__our-service-content-project-left>a .p-home__our-service-content-project-left-title>p {
  font-weight: 700;
  font-size: 40px;
  line-height: 47px;
  width: 250px;
  color: #303030;
  margin: 0 0 20px 0;
}

.p-home__our-service-content-project-left-text>p {
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  /* or 178% */
  /* #686868 */
  color: #686868;
  width: 330px;
}

.p-home__our-service-content-project {
  padding-top: 20px;
}

.project-btn {
  justify-content: space-between;
  align-items: center;
  width: 255px;
  padding: 16px 35px;
  background: #ffffff;
  border-radius: 2px;
  border-radius: 2px;
  cursor: pointer;
  transition: 0.3s;
}

.project-btn:hover {
  justify-content: space-between;
  align-items: center;
  width: 255px;
  padding: 16px 35px;
  background: #eef6ff;
  border-radius: 2px;
  border-radius: 2px;
  cursor: pointer;
}

.p-home__our-service-read-icon>div {
  margin-left: 20px;
}

.p-home__our-service-read-icon>div>img {
  cursor: pointer;
  margin: 0 10px;
}

.p-home__our-service-read-icon>div>span {
  font-style: normal;
  color: #0063aa;
}

.project-number {
  position: relative;
  width: 38px;
  height: 38px;
}

.project-number>span {
  position: absolute;
}

.project-number-three {
  top: 0;
  left: 0;
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;

  color: #0063aa;
}

.project-number-ten {
  bottom: 0;
  right: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */
  color: #0063aa;
}

.project-number-bar {
  content: '';
  width: 1px;
  height: 100%;
  background-color: #0063aa;
  border-radius: 4px;
  top: 0;
  bottom: 0;
  left: 45%;
  transform: translateX(-50%);
  transform: rotate(28deg);
}

.p-home__our-service-title>div>h1 {
  padding-top: 100px;
  position: relative;
  font-weight: 900;
  font-size: 40px;
  line-height: 47px;
  color: #0063aa;
}

.p-home__our-service-title>div>h1::after {
  position: absolute;
  content: '';
  background-color: #0063aa;
  width: 48px;
  height: 4px;
  border-radius: 30px;
  left: 0;
  bottom: -20px;
}

.p-home__achievement {
  width: 1100px;
  margin: auto;
  padding-top: 50px;
}

.p-home__achievement-title {
  padding-top: 20px;
  text-align: center;
}

.p-home__achievement-title>h1 {
  position: relative;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  font-size: 40px;
  line-height: 60px;
  /* identical to box height, or 150% */

  text-align: center;

  color: #0063aa;
}

.p-home__achievement-title>h1::after {
  position: absolute;
  content: '';
  background-color: #0063aa;
  width: 48px;
  height: 4px;
  border-radius: 30px;
  left: 50%;
  transform: translateX(-50%);
  bottom: -15px;
}

.p-home__achievement-title>p {
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  /* identical to box height, or 150% */
  color: #434343;
  margin-top: 25px;
}

.p-home__achievement-title-items {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.p-home__achievement-title-item {
  padding: 35px;
  width: 245px;
  height: 245px;
  justify-content: space-between;
  /* background: #0063AA; */
  box-shadow: inset 0px 0px 8.29464px rgba(0, 0, 0, 0.1);
  border-radius: 6.63571px;
}

.p-home__achievement-title-item-number {
  padding-top: 30px;
  font-weight: 900;
  font-size: 42px;
  line-height: 49px;

  color: #ffffff;
}

.p-home__achievement-title-item-text {
  font-weight: 500;
  font-size: 24px;
  line-height: 33px;
  /* or 137% */
  display: flex;
  align-items: center;
  color: #ffffff;
}

.p-home__our-service-content-project-right {
  width: 100%;
}

.p-home__our-service-content-project-right>img {
  width: 100%;
}

.p-home__our-service-content-project-left-logo {
  width: 180px;
  height: 180px;
}

.p-home__our-service-content-project-left-logo>img {
  width: 100%;
  object-fit: contain;
  aspect-ratio: 9/9;
}

.slick-next:before {
  display: none !important;
  content: '' !important;
}

.img__procedure {
  transition: 0.5s;
}

.img__procedure>img {
  transition: 0.5s;
}

.p-home__procedure-content-right-content:hover {
  position: relative;
  margin: 25px 0 25px -25px;
  height: 126px;
  width: 635px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 500px;
}

.p-home__procedure-content-right-content:hover::after {
  position: absolute;
  content: '';
  height: 100%;
  width: 5px;
  background-color: #fff;
  left: -25px;
  transition: 0.3s;
}

.p-home__procedure-content-right-content:hover>.p-home__procedure-content-right-content-number>p {
  font-weight: 700;
  font-size: 87px !important;
  line-height: 102px;
  color: #ffffff;
}

.p-home__procedure-content-right-content:hover>.img__procedure {
  width: 126px;
  height: 126px;
}

.p-home__procedure-content-right-content:hover>.img__procedure>img {
  width: 100%;
}

.p-home__our-service-read-icon>p {
  margin-bottom: 0;
}

.container-about {
  width: 100% !important;
  /* background: #F3FAFF !important; */
}

#about .r-company-profile-about {
  margin: 0 !important;
  background: #f3faff;
}

.about-containers {
  margin-top: 80px;
}

.r-office-tcoms {
  margin-bottom: 50px !important;
}

.p-home__achievement-title-item-icon {
  width: 85px;
  height: 85px;
}

.show {
  display: block !important;
}

.r-featured-solution {
  background: linear-gradient(0deg,
      rgba(0, 99, 170, 0.2),
      rgba(0, 99, 170, 0.2)),
    linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    url('/assets/img/slider/slider_solution.png');
  margin-top: 100px;
  position: relative;
  background-size: cover;
  height: 525px;
  max-height: 525px;
}

.r-featured-solution-content {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.r-featured-solution-content>h1 {
  font-weight: 900;
  font-size: 56px;
  line-height: 66px;
  /* identical to box height */
  color: #ffffff;
}

.r-featured-solution-content>p {
  padding-top: 30px;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  /* or 133% */
  text-align: center;
  color: #ffffff;
}

.title-box-solution>p {
  font-weight: 700 !important;
  font-size: 40px !important;
  line-height: 47px !important;

  color: #0063aa !important;
}

.p-offShore {
  background: url('/assets/img/slider/dot_map.png');
  background-position: top;
  background-repeat: no-repeat;
  background-size: contain;
  height: auto;
  width: 100%;
}

.p-offShore>p {
  padding-top: 20px;
  font-weight: 700;
  font-size: 80px;
  line-height: 100px;
  /* or 125% */
  text-align: center;
  color: #ffffff;
}

.p-offShore-icon {
  padding-top: 200px;
}

.text-offSore>p {
  font-weight: 700 !important;
  font-size: 50px !important;
  line-height: 66px !important;
  /* or 131% */

  text-align: center !important;

  color: #ffffff !important;
}

.p-offSore-items {
  max-width: 1000px !important;
}

.p-offShore__icon-item {
  width: 300px !important;
  height: 300px !important;
}

.our_quality {
  width: 1100px;
  margin-right: auto;
  margin-left: auto;
}

.our_quality_img {
  padding-top: 50px;
}

.our_quality_img>img {
  width: 100%;
}

.our_quality_content {
  padding-top: 100px;
  text-align: center;
}

.our_quality_title>p {
  font-weight: 900;
  font-size: 56px;
  line-height: 66px;
  /* identical to box height */
  color: #0063aa;
}

.our_quality_sub-title>p {
  font-weight: 900;
  font-size: 32px;
  line-height: 38px;
  /* identical to box height */
  color: #0063aa;
}

.our_quality_text>p {
  font-weight: 900;
  font-size: 24px;
  line-height: 40px;
  /* identical to box height, or 167% */
  text-align: center;
  color: #0063aa;
}

/* 
.p-offShore-img-awards {
    transform: translateY(-10%);
}



.p-offShore-img-awards-circle>img {
    width: 100%;
}

.p-offShore {
    position: relative;
    margin-top: 100px;
}





.p-offShore-img-award-img {
    width: 100%;
}

.p-offShore-img-award-img>img {
    width: 100%;
} */

.p-offShore-img {
  z-index: -1;
  position: absolute;
  margin: auto;
  width: 1600px;
  top: 15%;
  left: 50%;
  transform: translateX(-50%);
}

.p-offShore-img>img {
  width: 100%;
}

.p-offShore-content {
  height: auto;
  position: relative;
  height: 2400px;
}

.p-offShore-content-top {
  position: absolute;
  width: 100%;
  height: 1400px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
}

.p-offShore-content-bottom {
  position: absolute;
  bottom: 6%;
  z-index: 1;
}

.p-offShore-img-awards-circle {
  position: absolute;
  bottom: 38%;
  width: 100%;
  z-index: 5;
}

.our_quality_img {
  position: relative;
}

.our_quality_img::before {
  position: absolute;
  content: '';
  width: 100%;
  height: 100px;
  background-color: #f3faff;
  bottom: 0;
  z-index: -1;
}

.p-offShore-img-awards {
  position: relative;
}

.p-offShore-content-bottom-container {
  top: 30%;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
}

.p-offShore-content-bottom-containers {
  width: 1105px;
}

.p-offShore-content-bottom-containers>.title>h1 {
  text-align: center;
  font-weight: 900;
  font-size: 64px;
  line-height: 75px;
  /* identical to box height */

  color: #ffffff;
}

.p-offShore-content-bottom-containers>.content-os {
  padding-top: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.p-offShore-content-bottom-containers>.content-os>.content-os-left>ul>li {
  display: flex;
  align-items: center;
  margin: 35px 0;
}

.p-offShore-content-bottom-containers>.content-os>.content-os-left>ul {
  padding-left: 50px;
}

.p-offShore-content-bottom-containers>.content-os>.content-os-left>ul>li>p {
  font-weight: 900;
  font-size: 40px;
  line-height: 58px;
  color: #ffffff;
  margin-left: 35px;
}

.p-offShore-content-bottom-containers {
  position: relative;
}

.p-offShore-content-bottom-containers::before {
  position: absolute;
  bottom: 35%;
  left: -45%;
  /* background-image: url("/assets/img/slider/why.png"); */
  content: 'Why ?';
  color: transparent;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #fff;
  font-weight: 900;
  font-size: 200px;
  line-height: 1;
  transform: rotate(90deg);
}

#offshore .r-process .col-right ul {
  padding-left: 0;
}

#home .r-process .col-right ul {
  padding-top: 70px !important;
  padding-left: 0;
}

#service .r-process .col-right ul {
  padding-left: 0;
}

.field_s {
  position: relative;
}

a {
  text-decoration: none !important;
}

#project .r-project-list .content .tag {
  padding-left: 0 !important;
}

.p-home__our-service-item-more {
  display: flex;
  align-items: center;
  transition: 0.3s;
  opacity: 0;
}

.p-home__our-service-item:hover>.p-home__our-service-item-more {
  opacity: 1;
}

.p-home__our-service-item-more>p {
  margin: 0;
  margin-right: 15px;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */

  color: #0063aa;
}

.l-header-desktop__languages__dropdown>ul {
  padding: 0;
  margin: 0;
}

.p-home__our-service-methods-item-control {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border: 1px solid #0063aa;
  border-radius: 50px;
  transition: 0.3s;
  cursor: pointer;
}

.p-home__our-service-methods-item-control>img {
  display: flex;
  justify-content: center;
  align-items: center;
}

.p-home__our-service-methods-item-control:hover {
  background-color: #0063a9;
}

.p-home__our-service-methods-item-control:hover>img {
  filter: contrast(0%) brightness(200%);
}

.video-company {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  /* left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%) !important;
    transform: translate(-50%, -50%) !important; */
  align-items: center;
  z-index: 9998;
  width: 100%;
  height: 100%;
  z-index: 9999;
  transition: 0.3s;
  visibility: visible;
}

.bg-video {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9998;
  transition: 0.3s;
  visibility: visible;
}

.video-company-non-active {
  position: fixed;
  visibility: hidden;
  z-index: -99999;
  transition: 0.3s;
}

.bg-video-non-active {
  transition: 0.3s;
  visibility: hidden;
  z-index: 9998;
}

.video-company-container {
  -webkit-box-shadow: 0 0 1.5em rgba(0, 0, 0, 0.35);
  box-shadow: 0 0 1.5em rgba(0, 0, 0, 0.35);
  height: 570px;
  max-width: calc(100% - 30px);
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  width: 1055px;
  z-index: 9999;
}

.slick-active>.LiSlick {
  width: 20px !important;
  height: 20px !important;
  transform: 0.3s !important;
}

.slick-dots>ul {
  align-items: center;
}

.slick-dots>ul>li {
  align-items: center;
}

.p-home__slider__item-mobile {
  display: none;
}

.p-home__slider__item_a_mb {
  /* background-position-y: 75px; */
  position: relative;
  background: linear-gradient(146.28deg,
      #060e1b 28.34%,
      rgba(6, 14, 27, 0) 85.52%);
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 815px !important;
}

.p-home__slider__content-mb {
  position: absolute;
  bottom: 6%;
  left: 50%;
  transform: translateX(-50%);
}

.text-mb-slide {
  width: 283.1px;
  height: 120px;
  left: 45.95px;
  top: 472.28px;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  font-size: 32px;
  line-height: 40px;
  /* or 125% */

  display: flex;
  align-items: center;
  text-align: center;

  color: #ffffff;
}

.sub_text-mb-slide {
  width: 313px;
  height: 96px;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */
  text-align: center;
  color: #ffffff;
}

.btn-slider-home-mb {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
}

.btn-slider-home-mb>p {
  padding-right: 20px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  text-align: center;

  color: #ffffff;
}

.after-slide-mobile {
  background-image: url('/assets/img/slider/s4.png');
  height: 622px;
  transform: translateY(-8px);
  position: relative;
  /* background: linear-gradient(146.28deg, #060E1B 28.34%, rgba(6, 14, 27, 0) 85.52%); */
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
}

.after-slide-mobile-content {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

#solution .r-solution-list>.container>ul {
  padding: 0;
}

#news .r-news-list .content .tag {
  padding: 0;
  margin: 0;
}

.p-news-details>.l-container>.l-row {
  margin: 0;
}

.p-news-details>.l-container>.l-row>.p-news-details {
  padding: 0;
}

.box-off {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9;
}

.container-service {
  padding: 0;
}

.p-home-title-service-abc {

  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #434343;
}

.p-home-title-service-abc>p {
  margin: 0;
  color: #434343;
}

/* .r-service-box>.container {
    max-width: 1100px !important;
} */

.our-company>.our-company-items ul {
  padding: 0;
}

.slick-prev:before {
  content: '' !important;
}

.item-slide-office {
  /* width: 946px;
    height: 612px; */
}

.p-home__achievement-comtenet {
  padding-bottom: 50px;
}

#project .container {
  max-width: 1110px !important;
}

#news .container {
  padding: 0 24px !important;
}

#about .r-contact {
  padding-top: 0;
}

#news .container {
  max-width: 1140px;
}

.img-project-img {
  position: relative;
  background-size: cover;
  width: 540px;
  height: 725px;
}

.container-img-project {
  max-width: 90%;
  transform: translateX(25%);
}

#home .r-featured-project .title-left {
  max-width: 1100px;
  margin-bottom: 45.42px !important;
}

.img-project-bg {
  background: linear-gradient(0deg, #000 -60.33%, transparent);
  content: '';
  height: 50%;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  width: 100%;
  z-index: 8;
  bottom: 0;
}

.img-project-bg {
  position: absolute;
}

.img-project-bg>div {
  padding: 0 41px;
  position: absolute;
  z-index: 10;
  width: 100%;
  bottom: 38px;
}

.img-project-bg>div>h1 {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 47px;

  color: #ffffff;
  margin-bottom: 24.28px;
}

.img-project-bg>div>p {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  /* or 133% */
  color: #ffffff;
}

.ndahhqheq {
  max-width: 204px;
}

.ihgbqjbfbafs {
  color: #fff !important;
}

.mmakqrkqrkq {
  color: #fff;
}

/* .nnnjkqkq5 {
  margin: 0 20px 0 0;
} */

.block-contact {
  margin-top: 68px;
}

.block-contact>h3 {
  position: relative;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: -0.02em;

  color: #0063aa;
  margin-bottom: 34px;
  text-transform: uppercase;
}

.block-contact>h3::after {
  position: absolute;
  content: '';
  width: 24px;
  height: 5px;
  background-color: rgba(0, 99, 170, 1);
  bottom: -10px;
  left: 0;
  border-radius: 5px;
}

.block-contact>ul {
  padding: 0;
  margin: 0;
}

.block-contact>ul>li {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.block-contact>ul>li>a {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  letter-spacing: 0.01em;

  color: rgba(0, 0, 0, 0.8);
  margin-left: 10.89px;
}

#iqeihfhsf {
  display: flex;
  align-items: center;
}

#iqeihfhsf li {
  padding: 5px 0;
}

#iqeihfhsf li a {
  margin-left: 0;
  margin-right: 16px;
}

#iqeihfhsf li a img {
  width: 42px;
  height: 42px;
}

.member-system-container {
  margin-bottom: 80px;
  padding: 20px 0 50px 0;
}

.member-system-item>h1 {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 25px;
  /* identical to box height, or 156% */

  color: #0063aa;
}

.member-system-item>p {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  height: 72px;
  color: #000000;
  margin-bottom: 17px;
  max-width: 308px;
}

.member-system-items {
  display: flex;
  justify-content: space-between;
}

#contact .container {
  max-width: 1110px;
}

.kdqhfsiqihjfsf {
  margin-top: 20px;
}

.oqjbfbab1bbfbaf>span {
  width: 1px;
  height: 70.76px;
  background-color: #d6d6d6;
}

.l-header-mobile__menu {
  filter: invert(1);
}

.oadisjdh22 {
  display: block !important;
}

.iaihbaj1jsbdfbs {
  margin: 4px;
  border: 1px solid #d9d9d9;
  border-radius: 9px;
}

.slide-feature-mb {
  transform: translateX(24px);
}

.r-featured-project-mb {
  background: #0063aa;
}

.container-img-project-mb {
  padding: 0 24px;
}

.koquuruanv>.desc {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 18px !important;
  line-height: 24px;
  /* identical to box height, or 133% */

  color: #ffffff;
}

.r-featured-project-mb {
  display: none;
}

.iuqwehbsbbqbbfbfbas {
  width: 67px;
  height: 67px;
}

@media (min-width: 1725px) and (max-width: 1860px) {
  .container-img-project {
    max-width: 95%;
    transform: translateX(22%);
  }
}

@media (min-width: 1650px) and (max-width: 1724px) {
  .container-img-project {
    max-width: 100%;
    transform: translateX(18%);
  }
}

@media (min-width: 1415px) and (max-width: 1650px) {
  .container-img-project {
    max-width: 78%;
    transform: translateX(22%);
  }
}

@media (min-width: 1230px) and (max-width: 1415px) {
  .container-img-project {
    max-width: 88%;
    transform: translateX(12%);
  }
}

@media (min-width: 1130px) and (max-width: 1230px) {
  .container-img-project {
    max-width: 100%;
    transform: translateX(3%);
  }
}

@media (min-width: 1100px) and (max-width: 1130px) {
  .container-img-project {
    max-width: 100%;
    transform: translateX(5%);
  }
}

@media (min-width: 1024px) and (max-width: 1100px) {
  .img-project-img {
    position: relative;
    background-size: cover;
    width: 340px;
    height: 525px;
  }

  .container-img-project {
    max-width: 100%;
    transform: translateX(5%);
  }

  .img-project-bg>div {
    padding: 0 20px;
    position: absolute;
    z-index: 10;
    width: 100%;
    bottom: -1px;
  }
}

@media (min-width: 740px) and (max-width: 1024px) {
  .container-img-project {
    max-width: 100%;
    transform: translateX(3%);
  }

  .img-project-img {
    position: relative;
    background-size: cover;
    width: 340px;
    height: 525px;
  }

  .img-project-bg>div {
    padding: 0 20px;
    position: absolute;
    z-index: 10;
    width: 100%;
    bottom: -1px;
  }
}

.diqwfnsasdnasdnad {
  display: none;
}

.jjqjfsasfbshsafsfashf {
  width: 350px !important;
  height: 196.64px !important;
  overflow: hidden;
}

.jjqjfsasfbshsafsfashf>img {
  width: 100% !important;
}

.oqijfajajada-left {
  overflow: hidden;
  width: 730px !important;
  height: 410px;
  border-radius: 8px;
}

.oqijfajajada-left>img {
  width: 100%;
}

.adaiqwei-news {
  justify-content: space-between;
}

.ieqwheh1asbdbas {
  margin-bottom: 13px;
}

.l-header-mobile__canvas__menu>ul {
  padding: 0;
}

.ehjwbeb-mb {
  display: none;
}

.qnasbdb1bbasd {
  position: relative;
}

.djqhdsahdada {
  position: absolute;
  width: 334px;
  height: 334px;
  background-repeat: no-repeat;
  background-size: cover;
  top: -20%;
  right: 5%;
  opacity: 0.03;
  z-index: -1;
}

@media (max-width: 1200px) {
  .djqhdsahdada {
    display: none;
  }
}

.jndansdnasd {
  position: fixed;
  right: 42px;
  bottom: 10%;
  z-index: 9999;
  cursor: pointer;
}

.jndansdnasd>a>img {
  width: 72px;
  height: 72px;
}

.sanhqdbqbdabwqbdasb .customImgMb {
  text-align: center;
  margin: auto;
  width: 100% !important;
}

.p-project-service-title>h1 {
  padding-top: 0;
}

.oadisjdh22>div>p {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  /* or 133% */

  color: #505050;
  margin-bottom: 0;
}

.p-project-title-service-abc-mb {
  display: none;
}

#service-details .r-contact {
  padding: 65px 0 103px 0;
}


.lakhjwqhfhahashda {
  margin-right: 10px;
}


.method_offshore_tc {
  padding: 23px 31px;
}

.method_offshore_tc>h1 {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;

  color: #494949;
}

.method_offshore_tc>p {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  /* identical to box height */


  color: #494949;
}


.jjdhquduy2yyd {
  justify-content: center;
}

.jjdhquduy2yyd .l-header-desktop__languages>a span {
  ont-weight: 400;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: 0.09em;
  color: #a6a6a6;
  position: relative;
}

.jqyfshfywyyssd>ul {
  padding: 0;
  margin: 0;
}

.LiSlick-f {
  align-items: center !important;
  font-size: 0;
  width: 10px !important;
  height: 10px !important;
  border: 1.5px solid #ffffff !important;
  border-radius: 50%;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
  transition: 0.4s all;
}

.pagi-slick-f {
  justify-content: center;
  transform: translateX(-12%);
}

.pagi-slick-f>li {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;

}

.pagi-slick-f .slick-active .LiSlick-f {
  background-color: #fff !important;
  font-size: 0;
  width: 20px !important;
  height: 20px !important;
  border: 1.5px solid #ffffff;
  border-radius: 50%;
  padding: 0;
  cursor: pointer;
  transition: 0.4s all;
}

.i182ywehehqehq {
  position: relative;
}

.i182ywehehqehq:hover>.next-f-qfhqw {
  opacity: 1;
  transition: .3s;
}

.next-f-qfhqw {
  cursor: pointer;
  opacity: 0;
  position: absolute;
  z-index: 999;
  left: 75%;
  top: 50%;
  transform: translateY(-50%);
  transition: .3s;
}

@media (min-width: 920px) and (max-width: 1250px) {
  #solution .r-solution-list>.container {
    width: 90% !important;
  }

  #contact #footer .main {
    padding-top: 100px !important;
  }
}