@charset "UTF-8";

/***** mixin *****/

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;600;900&display=swap');
/* @import './pages/home.css'; */

.mb-1 {
  margin-bottom: 1px !important;
}

.mt-1 {
  margin-top: 1px !important;
}

.ml-1 {
  margin-left: 1px !important;
}

.mr-1 {
  margin-right: 1px !important;
}

.pb-1 {
  padding-bottom: 1px !important;
}

.pt-1 {
  padding-top: 1px !important;
}

.pl-1 {
  padding-left: 1px !important;
}

.pr-1 {
  padding-right: 1px !important;
}

.mb-2 {
  margin-bottom: 2px !important;
}

.mt-2 {
  margin-top: 2px !important;
}

.ml-2 {
  margin-left: 2px !important;
}

.mr-2 {
  margin-right: 2px !important;
}

.pb-2 {
  padding-bottom: 2px !important;
}

.pt-2 {
  padding-top: 2px !important;
}

.pl-2 {
  padding-left: 2px !important;
}

.pr-2 {
  padding-right: 2px !important;
}

.mb-3 {
  margin-bottom: 3px !important;
}

.mt-3 {
  margin-top: 3px !important;
}

.ml-3 {
  margin-left: 3px !important;
}

.mr-3 {
  margin-right: 3px !important;
}

.pb-3 {
  padding-bottom: 3px !important;
}

.pt-3 {
  padding-top: 3px !important;
}

.pl-3 {
  padding-left: 3px !important;
}

.pr-3 {
  padding-right: 3px !important;
}

.mb-4 {
  margin-bottom: 4px !important;
}

.mt-4 {
  margin-top: 4px !important;
}

.ml-4 {
  margin-left: 4px !important;
}

.mr-4 {
  margin-right: 4px !important;
}

.pb-4 {
  padding-bottom: 4px !important;
}

.pt-4 {
  padding-top: 4px !important;
}

.pl-4 {
  padding-left: 4px !important;
}

.pr-4 {
  padding-right: 4px !important;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.mt-5 {
  margin-top: 5px !important;
}

.ml-5 {
  margin-left: 5px !important;
}

.mr-5 {
  margin-right: 5px !important;
}

.pb-5 {
  padding-bottom: 5px !important;
}

.pt-5 {
  padding-top: 5px !important;
}

.pl-5 {
  padding-left: 5px !important;
}

.pr-5 {
  padding-right: 5px !important;
}

.mb-6 {
  margin-bottom: 6px !important;
}

.mt-6 {
  margin-top: 6px !important;
}

.ml-6 {
  margin-left: 6px !important;
}

.mr-6 {
  margin-right: 6px !important;
}

.pb-6 {
  padding-bottom: 6px !important;
}

.pt-6 {
  padding-top: 6px !important;
}

.pl-6 {
  padding-left: 6px !important;
}

.pr-6 {
  padding-right: 6px !important;
}

.mb-7 {
  margin-bottom: 7px !important;
}

.mt-7 {
  margin-top: 7px !important;
}

.ml-7 {
  margin-left: 7px !important;
}

.mr-7 {
  margin-right: 7px !important;
}

.pb-7 {
  padding-bottom: 7px !important;
}

.pt-7 {
  padding-top: 7px !important;
}

.pl-7 {
  padding-left: 7px !important;
}

.pr-7 {
  padding-right: 7px !important;
}

.mb-8 {
  margin-bottom: 8px !important;
}

.mt-8 {
  margin-top: 8px !important;
}

.ml-8 {
  margin-left: 8px !important;
}

.mr-8 {
  margin-right: 8px !important;
}

.pb-8 {
  padding-bottom: 8px !important;
}

.pt-8 {
  padding-top: 8px !important;
}

.pl-8 {
  padding-left: 8px !important;
}

.pr-8 {
  padding-right: 8px !important;
}

.mb-9 {
  margin-bottom: 9px !important;
}

.mt-9 {
  margin-top: 9px !important;
}

.ml-9 {
  margin-left: 9px !important;
}

.mr-9 {
  margin-right: 9px !important;
}

.pb-9 {
  padding-bottom: 9px !important;
}

.pt-9 {
  padding-top: 9px !important;
}

.pl-9 {
  padding-left: 9px !important;
}

.pr-9 {
  padding-right: 9px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.pb-10 {
  padding-bottom: 10px !important;
}

.pt-10 {
  padding-top: 10px !important;
}

.pl-10 {
  padding-left: 10px !important;
}

.pr-10 {
  padding-right: 10px !important;
}

.mb-11 {
  margin-bottom: 11px !important;
}

.mt-11 {
  margin-top: 11px !important;
}

.ml-11 {
  margin-left: 11px !important;
}

.mr-11 {
  margin-right: 11px !important;
}

.pb-11 {
  padding-bottom: 11px !important;
}

.pt-11 {
  padding-top: 11px !important;
}

.pl-11 {
  padding-left: 11px !important;
}

.pr-11 {
  padding-right: 11px !important;
}

.mb-12 {
  margin-bottom: 12px !important;
}

.mt-12 {
  margin-top: 12px !important;
}

.ml-12 {
  margin-left: 12px !important;
}

.mr-12 {
  margin-right: 12px !important;
}

.pb-12 {
  padding-bottom: 12px !important;
}

.pt-12 {
  padding-top: 12px !important;
}

.pl-12 {
  padding-left: 12px !important;
}

.pr-12 {
  padding-right: 12px !important;
}

.mb-13 {
  margin-bottom: 13px !important;
}

.mt-13 {
  margin-top: 13px !important;
}

.ml-13 {
  margin-left: 13px !important;
}

.mr-13 {
  margin-right: 13px !important;
}

.pb-13 {
  padding-bottom: 13px !important;
}

.pt-13 {
  padding-top: 13px !important;
}

.pl-13 {
  padding-left: 13px !important;
}

.pr-13 {
  padding-right: 13px !important;
}

.mb-14 {
  margin-bottom: 14px !important;
}

.mt-14 {
  margin-top: 14px !important;
}

.ml-14 {
  margin-left: 14px !important;
}

.mr-14 {
  margin-right: 14px !important;
}

.pb-14 {
  padding-bottom: 14px !important;
}

.pt-14 {
  padding-top: 14px !important;
}

.pl-14 {
  padding-left: 14px !important;
}

.pr-14 {
  padding-right: 14px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.ml-15 {
  margin-left: 15px !important;
}

.mr-15 {
  margin-right: 15px !important;
}

.pb-15 {
  padding-bottom: 15px !important;
}

.pt-15 {
  padding-top: 15px !important;
}

.pl-15 {
  padding-left: 15px !important;
}

.pr-15 {
  padding-right: 15px !important;
}

.mb-16 {
  margin-bottom: 16px !important;
}

.mt-16 {
  margin-top: 16px !important;
}

.ml-16 {
  margin-left: 16px !important;
}

.mr-16 {
  margin-right: 16px !important;
}

.pb-16 {
  padding-bottom: 16px !important;
}

.pt-16 {
  padding-top: 16px !important;
}

.pl-16 {
  padding-left: 16px !important;
}

.pr-16 {
  padding-right: 16px !important;
}

.mb-17 {
  margin-bottom: 17px !important;
}

.mt-17 {
  margin-top: 17px !important;
}

.ml-17 {
  margin-left: 17px !important;
}

.mr-17 {
  margin-right: 17px !important;
}

.pb-17 {
  padding-bottom: 17px !important;
}

.pt-17 {
  padding-top: 17px !important;
}

.pl-17 {
  padding-left: 17px !important;
}

.pr-17 {
  padding-right: 17px !important;
}

.mb-18 {
  margin-bottom: 18px !important;
}

.mt-18 {
  margin-top: 18px !important;
}

.ml-18 {
  margin-left: 18px !important;
}

.mr-18 {
  margin-right: 18px !important;
}

.pb-18 {
  padding-bottom: 18px !important;
}

.pt-18 {
  padding-top: 18px !important;
}

.pl-18 {
  padding-left: 18px !important;
}

.pr-18 {
  padding-right: 18px !important;
}

.mb-19 {
  margin-bottom: 19px !important;
}

.mt-19 {
  margin-top: 19px !important;
}

.ml-19 {
  margin-left: 19px !important;
}

.mr-19 {
  margin-right: 19px !important;
}

.pb-19 {
  padding-bottom: 19px !important;
}

.pt-19 {
  padding-top: 19px !important;
}

.pl-19 {
  padding-left: 19px !important;
}

.pr-19 {
  padding-right: 19px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.ml-20 {
  margin-left: 20px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.pl-20 {
  padding-left: 20px !important;
}

.pr-20 {
  padding-right: 20px !important;
}

.mb-21 {
  margin-bottom: 21px !important;
}

.mt-21 {
  margin-top: 21px !important;
}

.ml-21 {
  margin-left: 21px !important;
}

.mr-21 {
  margin-right: 21px !important;
}

.pb-21 {
  padding-bottom: 21px !important;
}

.pt-21 {
  padding-top: 21px !important;
}

.pl-21 {
  padding-left: 21px !important;
}

.pr-21 {
  padding-right: 21px !important;
}

.mb-22 {
  margin-bottom: 22px !important;
}

.mt-22 {
  margin-top: 22px !important;
}

.ml-22 {
  margin-left: 22px !important;
}

.mr-22 {
  margin-right: 22px !important;
}

.pb-22 {
  padding-bottom: 22px !important;
}

.pt-22 {
  padding-top: 22px !important;
}

.pl-22 {
  padding-left: 22px !important;
}

.pr-22 {
  padding-right: 22px !important;
}

.mb-23 {
  margin-bottom: 23px !important;
}

.mt-23 {
  margin-top: 23px !important;
}

.ml-23 {
  margin-left: 23px !important;
}

.mr-23 {
  margin-right: 23px !important;
}

.pb-23 {
  padding-bottom: 23px !important;
}

.pt-23 {
  padding-top: 23px !important;
}

.pl-23 {
  padding-left: 23px !important;
}

.pr-23 {
  padding-right: 23px !important;
}

.mb-24 {
  margin-bottom: 24px !important;
}

.mt-24 {
  margin-top: 24px !important;
}

.ml-24 {
  margin-left: 24px !important;
}

.mr-24 {
  margin-right: 24px !important;
}

.pb-24 {
  padding-bottom: 24px !important;
}

.pt-24 {
  padding-top: 24px !important;
}

.pl-24 {
  padding-left: 24px !important;
}

.pr-24 {
  padding-right: 24px !important;
}

.mb-25 {
  margin-bottom: 25px !important;
}

.mt-25 {
  margin-top: 25px !important;
}

.ml-25 {
  margin-left: 25px !important;
}

.mr-25 {
  margin-right: 25px !important;
}

.pb-25 {
  padding-bottom: 25px !important;
}

.pt-25 {
  padding-top: 25px !important;
}

.pl-25 {
  padding-left: 25px !important;
}

.pr-25 {
  padding-right: 25px !important;
}

.mb-26 {
  margin-bottom: 26px !important;
}

.mt-26 {
  margin-top: 26px !important;
}

.ml-26 {
  margin-left: 26px !important;
}

.mr-26 {
  margin-right: 26px !important;
}

.pb-26 {
  padding-bottom: 26px !important;
}

.pt-26 {
  padding-top: 26px !important;
}

.pl-26 {
  padding-left: 26px !important;
}

.pr-26 {
  padding-right: 26px !important;
}

.mb-27 {
  margin-bottom: 27px !important;
}

.mt-27 {
  margin-top: 27px !important;
}

.ml-27 {
  margin-left: 27px !important;
}

.mr-27 {
  margin-right: 27px !important;
}

.pb-27 {
  padding-bottom: 27px !important;
}

.pt-27 {
  padding-top: 27px !important;
}

.pl-27 {
  padding-left: 27px !important;
}

.pr-27 {
  padding-right: 27px !important;
}

.mb-28 {
  margin-bottom: 28px !important;
}

.mt-28 {
  margin-top: 28px !important;
}

.ml-28 {
  margin-left: 28px !important;
}

.mr-28 {
  margin-right: 28px !important;
}

.pb-28 {
  padding-bottom: 28px !important;
}

.pt-28 {
  padding-top: 28px !important;
}

.pl-28 {
  padding-left: 28px !important;
}

.pr-28 {
  padding-right: 28px !important;
}

.mb-29 {
  margin-bottom: 29px !important;
}

.mt-29 {
  margin-top: 29px !important;
}

.ml-29 {
  margin-left: 29px !important;
}

.mr-29 {
  margin-right: 29px !important;
}

.pb-29 {
  padding-bottom: 29px !important;
}

.pt-29 {
  padding-top: 29px !important;
}

.pl-29 {
  padding-left: 29px !important;
}

.pr-29 {
  padding-right: 29px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.ml-30 {
  margin-left: 30px !important;
}

.mr-30 {
  margin-right: 30px !important;
}

.pb-30 {
  padding-bottom: 30px !important;
}

.pt-30 {
  padding-top: 30px !important;
}

.pl-30 {
  padding-left: 30px !important;
}

.pr-30 {
  padding-right: 30px !important;
}

.mb-31 {
  margin-bottom: 31px !important;
}

.mt-31 {
  margin-top: 31px !important;
}

.ml-31 {
  margin-left: 31px !important;
}

.mr-31 {
  margin-right: 31px !important;
}

.pb-31 {
  padding-bottom: 31px !important;
}

.pt-31 {
  padding-top: 31px !important;
}

.pl-31 {
  padding-left: 31px !important;
}

.pr-31 {
  padding-right: 31px !important;
}

.mb-32 {
  margin-bottom: 32px !important;
}

.mt-32 {
  margin-top: 32px !important;
}

.ml-32 {
  margin-left: 32px !important;
}

.mr-32 {
  margin-right: 32px !important;
}

.pb-32 {
  padding-bottom: 32px !important;
}

.pt-32 {
  padding-top: 32px !important;
}

.pl-32 {
  padding-left: 32px !important;
}

.pr-32 {
  padding-right: 32px !important;
}

.mb-33 {
  margin-bottom: 33px !important;
}

.mt-33 {
  margin-top: 33px !important;
}

.ml-33 {
  margin-left: 33px !important;
}

.mr-33 {
  margin-right: 33px !important;
}

.pb-33 {
  padding-bottom: 33px !important;
}

.pt-33 {
  padding-top: 33px !important;
}

.pl-33 {
  padding-left: 33px !important;
}

.pr-33 {
  padding-right: 33px !important;
}

.mb-34 {
  margin-bottom: 34px !important;
}

.mt-34 {
  margin-top: 34px !important;
}

.ml-34 {
  margin-left: 34px !important;
}

.mr-34 {
  margin-right: 34px !important;
}

.pb-34 {
  padding-bottom: 34px !important;
}

.pt-34 {
  padding-top: 34px !important;
}

.pl-34 {
  padding-left: 34px !important;
}

.pr-34 {
  padding-right: 34px !important;
}

.mb-35 {
  margin-bottom: 35px !important;
}

.mt-35 {
  margin-top: 35px !important;
}

.ml-35 {
  margin-left: 35px !important;
}

.mr-35 {
  margin-right: 35px !important;
}

.pb-35 {
  padding-bottom: 35px !important;
}

.pt-35 {
  padding-top: 35px !important;
}

.pl-35 {
  padding-left: 35px !important;
}

.pr-35 {
  padding-right: 35px !important;
}

.mb-36 {
  margin-bottom: 36px !important;
}

.mt-36 {
  margin-top: 36px !important;
}

.ml-36 {
  margin-left: 36px !important;
}

.mr-36 {
  margin-right: 36px !important;
}

.pb-36 {
  padding-bottom: 36px !important;
}

.pt-36 {
  padding-top: 36px !important;
}

.pl-36 {
  padding-left: 36px !important;
}

.pr-36 {
  padding-right: 36px !important;
}

.mb-37 {
  margin-bottom: 37px !important;
}

.mt-37 {
  margin-top: 37px !important;
}

.ml-37 {
  margin-left: 37px !important;
}

.mr-37 {
  margin-right: 37px !important;
}

.pb-37 {
  padding-bottom: 37px !important;
}

.pt-37 {
  padding-top: 37px !important;
}

.pl-37 {
  padding-left: 37px !important;
}

.pr-37 {
  padding-right: 37px !important;
}

.mb-38 {
  margin-bottom: 38px !important;
}

.mt-38 {
  margin-top: 38px !important;
}

.ml-38 {
  margin-left: 38px !important;
}

.mr-38 {
  margin-right: 38px !important;
}

.pb-38 {
  padding-bottom: 38px !important;
}

.pt-38 {
  padding-top: 38px !important;
}

.pl-38 {
  padding-left: 38px !important;
}

.pr-38 {
  padding-right: 38px !important;
}

.mb-39 {
  margin-bottom: 39px !important;
}

.mt-39 {
  margin-top: 39px !important;
}

.ml-39 {
  margin-left: 39px !important;
}

.mr-39 {
  margin-right: 39px !important;
}

.pb-39 {
  padding-bottom: 39px !important;
}

.pt-39 {
  padding-top: 39px !important;
}

.pl-39 {
  padding-left: 39px !important;
}

.pr-39 {
  padding-right: 39px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.ml-40 {
  margin-left: 40px !important;
}

.mr-40 {
  margin-right: 40px !important;
}

.pb-40 {
  padding-bottom: 40px !important;
}

.pt-40 {
  padding-top: 40px !important;
}

.pl-40 {
  padding-left: 40px !important;
}

.pr-40 {
  padding-right: 40px !important;
}

.mb-41 {
  margin-bottom: 41px !important;
}

.mt-41 {
  margin-top: 41px !important;
}

.ml-41 {
  margin-left: 41px !important;
}

.mr-41 {
  margin-right: 41px !important;
}

.pb-41 {
  padding-bottom: 41px !important;
}

.pt-41 {
  padding-top: 41px !important;
}

.pl-41 {
  padding-left: 41px !important;
}

.pr-41 {
  padding-right: 41px !important;
}

.mb-42 {
  margin-bottom: 42px !important;
}

.mt-42 {
  margin-top: 42px !important;
}

.ml-42 {
  margin-left: 42px !important;
}

.mr-42 {
  margin-right: 42px !important;
}

.pb-42 {
  padding-bottom: 42px !important;
}

.pt-42 {
  padding-top: 42px !important;
}

.pl-42 {
  padding-left: 42px !important;
}

.pr-42 {
  padding-right: 42px !important;
}

.mb-43 {
  margin-bottom: 43px !important;
}

.mt-43 {
  margin-top: 43px !important;
}

.ml-43 {
  margin-left: 43px !important;
}

.mr-43 {
  margin-right: 43px !important;
}

.pb-43 {
  padding-bottom: 43px !important;
}

.pt-43 {
  padding-top: 43px !important;
}

.pl-43 {
  padding-left: 43px !important;
}

.pr-43 {
  padding-right: 43px !important;
}

.mb-44 {
  margin-bottom: 44px !important;
}

.mt-44 {
  margin-top: 44px !important;
}

.ml-44 {
  margin-left: 44px !important;
}

.mr-44 {
  margin-right: 44px !important;
}

.pb-44 {
  padding-bottom: 44px !important;
}

.pt-44 {
  padding-top: 44px !important;
}

.pl-44 {
  padding-left: 44px !important;
}

.pr-44 {
  padding-right: 44px !important;
}

.mb-45 {
  margin-bottom: 45px !important;
}

.mt-45 {
  margin-top: 45px !important;
}

.ml-45 {
  margin-left: 45px !important;
}

.mr-45 {
  margin-right: 45px !important;
}

.pb-45 {
  padding-bottom: 45px !important;
}

.pt-45 {
  padding-top: 45px !important;
}

.pl-45 {
  padding-left: 45px !important;
}

.pr-45 {
  padding-right: 45px !important;
}

.mb-46 {
  margin-bottom: 46px !important;
}

.mt-46 {
  margin-top: 46px !important;
}

.ml-46 {
  margin-left: 46px !important;
}

.mr-46 {
  margin-right: 46px !important;
}

.pb-46 {
  padding-bottom: 46px !important;
}

.pt-46 {
  padding-top: 46px !important;
}

.pl-46 {
  padding-left: 46px !important;
}

.pr-46 {
  padding-right: 46px !important;
}

.mb-47 {
  margin-bottom: 47px !important;
}

.mt-47 {
  margin-top: 47px !important;
}

.ml-47 {
  margin-left: 47px !important;
}

.mr-47 {
  margin-right: 47px !important;
}

.pb-47 {
  padding-bottom: 47px !important;
}

.pt-47 {
  padding-top: 47px !important;
}

.pl-47 {
  padding-left: 47px !important;
}

.pr-47 {
  padding-right: 47px !important;
}

.mb-48 {
  margin-bottom: 48px !important;
}

.mt-48 {
  margin-top: 48px !important;
}

.ml-48 {
  margin-left: 48px !important;
}

.mr-48 {
  margin-right: 48px !important;
}

.pb-48 {
  padding-bottom: 48px !important;
}

.pt-48 {
  padding-top: 48px !important;
}

.pl-48 {
  padding-left: 48px !important;
}

.pr-48 {
  padding-right: 48px !important;
}

.mb-49 {
  margin-bottom: 49px !important;
}

.mt-49 {
  margin-top: 49px !important;
}

.ml-49 {
  margin-left: 49px !important;
}

.mr-49 {
  margin-right: 49px !important;
}

.pb-49 {
  padding-bottom: 49px !important;
}

.pt-49 {
  padding-top: 49px !important;
}

.pl-49 {
  padding-left: 49px !important;
}

.pr-49 {
  padding-right: 49px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.ml-50 {
  margin-left: 50px !important;
}

.mr-50 {
  margin-right: 50px !important;
}

.pb-50 {
  padding-bottom: 50px !important;
}

.pt-50 {
  padding-top: 50px !important;
}

.pl-50 {
  padding-left: 50px !important;
}

.pr-50 {
  padding-right: 50px !important;
}

.mb-51 {
  margin-bottom: 51px !important;
}

.mt-51 {
  margin-top: 51px !important;
}

.ml-51 {
  margin-left: 51px !important;
}

.mr-51 {
  margin-right: 51px !important;
}

.pb-51 {
  padding-bottom: 51px !important;
}

.pt-51 {
  padding-top: 51px !important;
}

.pl-51 {
  padding-left: 51px !important;
}

.pr-51 {
  padding-right: 51px !important;
}

.mb-52 {
  margin-bottom: 52px !important;
}

.mt-52 {
  margin-top: 52px !important;
}

.ml-52 {
  margin-left: 52px !important;
}

.mr-52 {
  margin-right: 52px !important;
}

.pb-52 {
  padding-bottom: 52px !important;
}

.pt-52 {
  padding-top: 52px !important;
}

.pl-52 {
  padding-left: 52px !important;
}

.pr-52 {
  padding-right: 52px !important;
}

.mb-53 {
  margin-bottom: 53px !important;
}

.mt-53 {
  margin-top: 53px !important;
}

.ml-53 {
  margin-left: 53px !important;
}

.mr-53 {
  margin-right: 53px !important;
}

.pb-53 {
  padding-bottom: 53px !important;
}

.pt-53 {
  padding-top: 53px !important;
}

.pl-53 {
  padding-left: 53px !important;
}

.pr-53 {
  padding-right: 53px !important;
}

.mb-54 {
  margin-bottom: 54px !important;
}

.mt-54 {
  margin-top: 54px !important;
}

.ml-54 {
  margin-left: 54px !important;
}

.mr-54 {
  margin-right: 54px !important;
}

.pb-54 {
  padding-bottom: 54px !important;
}

.pt-54 {
  padding-top: 54px !important;
}

.pl-54 {
  padding-left: 54px !important;
}

.pr-54 {
  padding-right: 54px !important;
}

.mb-55 {
  margin-bottom: 55px !important;
}

.mt-55 {
  margin-top: 55px !important;
}

.ml-55 {
  margin-left: 55px !important;
}

.mr-55 {
  margin-right: 55px !important;
}

.pb-55 {
  padding-bottom: 55px !important;
}

.pt-55 {
  padding-top: 55px !important;
}

.pl-55 {
  padding-left: 55px !important;
}

.pr-55 {
  padding-right: 55px !important;
}

.mb-56 {
  margin-bottom: 56px !important;
}

.mt-56 {
  margin-top: 56px !important;
}

.ml-56 {
  margin-left: 56px !important;
}

.mr-56 {
  margin-right: 56px !important;
}

.pb-56 {
  padding-bottom: 56px !important;
}

.pt-56 {
  padding-top: 56px !important;
}

.pl-56 {
  padding-left: 56px !important;
}

.pr-56 {
  padding-right: 56px !important;
}

.mb-57 {
  margin-bottom: 57px !important;
}

.mt-57 {
  margin-top: 57px !important;
}

.ml-57 {
  margin-left: 57px !important;
}

.mr-57 {
  margin-right: 57px !important;
}

.pb-57 {
  padding-bottom: 57px !important;
}

.pt-57 {
  padding-top: 57px !important;
}

.pl-57 {
  padding-left: 57px !important;
}

.pr-57 {
  padding-right: 57px !important;
}

.mb-58 {
  margin-bottom: 58px !important;
}

.mt-58 {
  margin-top: 58px !important;
}

.ml-58 {
  margin-left: 58px !important;
}

.mr-58 {
  margin-right: 58px !important;
}

.pb-58 {
  padding-bottom: 58px !important;
}

.pt-58 {
  padding-top: 58px !important;
}

.pl-58 {
  padding-left: 58px !important;
}

.pr-58 {
  padding-right: 58px !important;
}

.mb-59 {
  margin-bottom: 59px !important;
}

.mt-59 {
  margin-top: 59px !important;
}

.ml-59 {
  margin-left: 59px !important;
}

.mr-59 {
  margin-right: 59px !important;
}

.pb-59 {
  padding-bottom: 59px !important;
}

.pt-59 {
  padding-top: 59px !important;
}

.pl-59 {
  padding-left: 59px !important;
}

.pr-59 {
  padding-right: 59px !important;
}

.mb-60 {
  margin-bottom: 60px !important;
}

.mt-60 {
  margin-top: 60px !important;
}

.ml-60 {
  margin-left: 60px !important;
}

.mr-60 {
  margin-right: 60px !important;
}

.pb-60 {
  padding-bottom: 60px !important;
}

.pt-60 {
  padding-top: 60px !important;
}

.pl-60 {
  padding-left: 60px !important;
}

.pr-60 {
  padding-right: 60px !important;
}

.mb-61 {
  margin-bottom: 61px !important;
}

.mt-61 {
  margin-top: 61px !important;
}

.ml-61 {
  margin-left: 61px !important;
}

.mr-61 {
  margin-right: 61px !important;
}

.pb-61 {
  padding-bottom: 61px !important;
}

.pt-61 {
  padding-top: 61px !important;
}

.pl-61 {
  padding-left: 61px !important;
}

.pr-61 {
  padding-right: 61px !important;
}

.mb-62 {
  margin-bottom: 62px !important;
}

.mt-62 {
  margin-top: 62px !important;
}

.ml-62 {
  margin-left: 62px !important;
}

.mr-62 {
  margin-right: 62px !important;
}

.pb-62 {
  padding-bottom: 62px !important;
}

.pt-62 {
  padding-top: 62px !important;
}

.pl-62 {
  padding-left: 62px !important;
}

.pr-62 {
  padding-right: 62px !important;
}

.mb-63 {
  margin-bottom: 63px !important;
}

.mt-63 {
  margin-top: 63px !important;
}

.ml-63 {
  margin-left: 63px !important;
}

.mr-63 {
  margin-right: 63px !important;
}

.pb-63 {
  padding-bottom: 63px !important;
}

.pt-63 {
  padding-top: 63px !important;
}

.pl-63 {
  padding-left: 63px !important;
}

.pr-63 {
  padding-right: 63px !important;
}

.mb-64 {
  margin-bottom: 64px !important;
}

.mt-64 {
  margin-top: 64px !important;
}

.ml-64 {
  margin-left: 64px !important;
}

.mr-64 {
  margin-right: 64px !important;
}

.pb-64 {
  padding-bottom: 64px !important;
}

.pt-64 {
  padding-top: 64px !important;
}

.pl-64 {
  padding-left: 64px !important;
}

.pr-64 {
  padding-right: 64px !important;
}

.mb-65 {
  margin-bottom: 65px !important;
}

.mt-65 {
  margin-top: 65px !important;
}

.ml-65 {
  margin-left: 65px !important;
}

.mr-65 {
  margin-right: 65px !important;
}

.pb-65 {
  padding-bottom: 65px !important;
}

.pt-65 {
  padding-top: 65px !important;
}

.pl-65 {
  padding-left: 65px !important;
}

.pr-65 {
  padding-right: 65px !important;
}

.mb-66 {
  margin-bottom: 66px !important;
}

.mt-66 {
  margin-top: 66px !important;
}

.ml-66 {
  margin-left: 66px !important;
}

.mr-66 {
  margin-right: 66px !important;
}

.pb-66 {
  padding-bottom: 66px !important;
}

.pt-66 {
  padding-top: 66px !important;
}

.pl-66 {
  padding-left: 66px !important;
}

.pr-66 {
  padding-right: 66px !important;
}

.mb-67 {
  margin-bottom: 67px !important;
}

.mt-67 {
  margin-top: 67px !important;
}

.ml-67 {
  margin-left: 67px !important;
}

.mr-67 {
  margin-right: 67px !important;
}

.pb-67 {
  padding-bottom: 67px !important;
}

.pt-67 {
  padding-top: 67px !important;
}

.pl-67 {
  padding-left: 67px !important;
}

.pr-67 {
  padding-right: 67px !important;
}

.mb-68 {
  margin-bottom: 68px !important;
}

.mt-68 {
  margin-top: 68px !important;
}

.ml-68 {
  margin-left: 68px !important;
}

.mr-68 {
  margin-right: 68px !important;
}

.pb-68 {
  padding-bottom: 68px !important;
}

.pt-68 {
  padding-top: 68px !important;
}

.pl-68 {
  padding-left: 68px !important;
}

.pr-68 {
  padding-right: 68px !important;
}

.mb-69 {
  margin-bottom: 69px !important;
}

.mt-69 {
  margin-top: 69px !important;
}

.ml-69 {
  margin-left: 69px !important;
}

.mr-69 {
  margin-right: 69px !important;
}

.pb-69 {
  padding-bottom: 69px !important;
}

.pt-69 {
  padding-top: 69px !important;
}

.pl-69 {
  padding-left: 69px !important;
}

.pr-69 {
  padding-right: 69px !important;
}

.mb-70 {
  margin-bottom: 70px !important;
}

.mt-70 {
  margin-top: 70px !important;
}

.ml-70 {
  margin-left: 70px !important;
}

.mr-70 {
  margin-right: 70px !important;
}

.pb-70 {
  padding-bottom: 70px !important;
}

.pt-70 {
  padding-top: 70px !important;
}

.pl-70 {
  padding-left: 70px !important;
}

.pr-70 {
  padding-right: 70px !important;
}

.mb-71 {
  margin-bottom: 71px !important;
}

.mt-71 {
  margin-top: 71px !important;
}

.ml-71 {
  margin-left: 71px !important;
}

.mr-71 {
  margin-right: 71px !important;
}

.pb-71 {
  padding-bottom: 71px !important;
}

.pt-71 {
  padding-top: 71px !important;
}

.pl-71 {
  padding-left: 71px !important;
}

.pr-71 {
  padding-right: 71px !important;
}

.mb-72 {
  margin-bottom: 72px !important;
}

.mt-72 {
  margin-top: 72px !important;
}

.ml-72 {
  margin-left: 72px !important;
}

.mr-72 {
  margin-right: 72px !important;
}

.pb-72 {
  padding-bottom: 72px !important;
}

.pt-72 {
  padding-top: 72px !important;
}

.pl-72 {
  padding-left: 72px !important;
}

.pr-72 {
  padding-right: 72px !important;
}

.mb-73 {
  margin-bottom: 73px !important;
}

.mt-73 {
  margin-top: 73px !important;
}

.ml-73 {
  margin-left: 73px !important;
}

.mr-73 {
  margin-right: 73px !important;
}

.pb-73 {
  padding-bottom: 73px !important;
}

.pt-73 {
  padding-top: 73px !important;
}

.pl-73 {
  padding-left: 73px !important;
}

.pr-73 {
  padding-right: 73px !important;
}

.mb-74 {
  margin-bottom: 74px !important;
}

.mt-74 {
  margin-top: 74px !important;
}

.ml-74 {
  margin-left: 74px !important;
}

.mr-74 {
  margin-right: 74px !important;
}

.pb-74 {
  padding-bottom: 74px !important;
}

.pt-74 {
  padding-top: 74px !important;
}

.pl-74 {
  padding-left: 74px !important;
}

.pr-74 {
  padding-right: 74px !important;
}

.mb-75 {
  margin-bottom: 75px !important;
}

.mt-75 {
  margin-top: 75px !important;
}

.ml-75 {
  margin-left: 75px !important;
}

.mr-75 {
  margin-right: 75px !important;
}

.pb-75 {
  padding-bottom: 75px !important;
}

.pt-75 {
  padding-top: 75px !important;
}

.pl-75 {
  padding-left: 75px !important;
}

.pr-75 {
  padding-right: 75px !important;
}

.mb-76 {
  margin-bottom: 76px !important;
}

.mt-76 {
  margin-top: 76px !important;
}

.ml-76 {
  margin-left: 76px !important;
}

.mr-76 {
  margin-right: 76px !important;
}

.pb-76 {
  padding-bottom: 76px !important;
}

.pt-76 {
  padding-top: 76px !important;
}

.pl-76 {
  padding-left: 76px !important;
}

.pr-76 {
  padding-right: 76px !important;
}

.mb-77 {
  margin-bottom: 77px !important;
}

.mt-77 {
  margin-top: 77px !important;
}

.ml-77 {
  margin-left: 77px !important;
}

.mr-77 {
  margin-right: 77px !important;
}

.pb-77 {
  padding-bottom: 77px !important;
}

.pt-77 {
  padding-top: 77px !important;
}

.pl-77 {
  padding-left: 77px !important;
}

.pr-77 {
  padding-right: 77px !important;
}

.mb-78 {
  margin-bottom: 78px !important;
}

.mt-78 {
  margin-top: 78px !important;
}

.ml-78 {
  margin-left: 78px !important;
}

.mr-78 {
  margin-right: 78px !important;
}

.pb-78 {
  padding-bottom: 78px !important;
}

.pt-78 {
  padding-top: 78px !important;
}

.pl-78 {
  padding-left: 78px !important;
}

.pr-78 {
  padding-right: 78px !important;
}

.mb-79 {
  margin-bottom: 79px !important;
}

.mt-79 {
  margin-top: 79px !important;
}

.ml-79 {
  margin-left: 79px !important;
}

.mr-79 {
  margin-right: 79px !important;
}

.pb-79 {
  padding-bottom: 79px !important;
}

.pt-79 {
  padding-top: 79px !important;
}

.pl-79 {
  padding-left: 79px !important;
}

.pr-79 {
  padding-right: 79px !important;
}

.mb-80 {
  margin-bottom: 80px !important;
}

.mt-80 {
  margin-top: 80px !important;
}

.ml-80 {
  margin-left: 80px !important;
}

.mr-80 {
  margin-right: 80px !important;
}

.pb-80 {
  padding-bottom: 80px !important;
}

.pt-80 {
  padding-top: 80px !important;
}

.pl-80 {
  padding-left: 80px !important;
}

.pr-80 {
  padding-right: 80px !important;
}

.mb-81 {
  margin-bottom: 81px !important;
}

.mt-81 {
  margin-top: 81px !important;
}

.ml-81 {
  margin-left: 81px !important;
}

.mr-81 {
  margin-right: 81px !important;
}

.pb-81 {
  padding-bottom: 81px !important;
}

.pt-81 {
  padding-top: 81px !important;
}

.pl-81 {
  padding-left: 81px !important;
}

.pr-81 {
  padding-right: 81px !important;
}

.mb-82 {
  margin-bottom: 82px !important;
}

.mt-82 {
  margin-top: 82px !important;
}

.ml-82 {
  margin-left: 82px !important;
}

.mr-82 {
  margin-right: 82px !important;
}

.pb-82 {
  padding-bottom: 82px !important;
}

.pt-82 {
  padding-top: 82px !important;
}

.pl-82 {
  padding-left: 82px !important;
}

.pr-82 {
  padding-right: 82px !important;
}

.mb-83 {
  margin-bottom: 83px !important;
}

.mt-83 {
  margin-top: 83px !important;
}

.ml-83 {
  margin-left: 83px !important;
}

.mr-83 {
  margin-right: 83px !important;
}

.pb-83 {
  padding-bottom: 83px !important;
}

.pt-83 {
  padding-top: 83px !important;
}

.pl-83 {
  padding-left: 83px !important;
}

.pr-83 {
  padding-right: 83px !important;
}

.mb-84 {
  margin-bottom: 84px !important;
}

.mt-84 {
  margin-top: 84px !important;
}

.ml-84 {
  margin-left: 84px !important;
}

.mr-84 {
  margin-right: 84px !important;
}

.pb-84 {
  padding-bottom: 84px !important;
}

.pt-84 {
  padding-top: 84px !important;
}

.pl-84 {
  padding-left: 84px !important;
}

.pr-84 {
  padding-right: 84px !important;
}

.mb-85 {
  margin-bottom: 85px !important;
}

.mt-85 {
  margin-top: 85px !important;
}

.ml-85 {
  margin-left: 85px !important;
}

.mr-85 {
  margin-right: 85px !important;
}

.pb-85 {
  padding-bottom: 85px !important;
}

.pt-85 {
  padding-top: 85px !important;
}

.pl-85 {
  padding-left: 85px !important;
}

.pr-85 {
  padding-right: 85px !important;
}

.mb-86 {
  margin-bottom: 86px !important;
}

.mt-86 {
  margin-top: 86px !important;
}

.ml-86 {
  margin-left: 86px !important;
}

.mr-86 {
  margin-right: 86px !important;
}

.pb-86 {
  padding-bottom: 86px !important;
}

.pt-86 {
  padding-top: 86px !important;
}

.pl-86 {
  padding-left: 86px !important;
}

.pr-86 {
  padding-right: 86px !important;
}

.mb-87 {
  margin-bottom: 87px !important;
}

.mt-87 {
  margin-top: 87px !important;
}

.ml-87 {
  margin-left: 87px !important;
}

.mr-87 {
  margin-right: 87px !important;
}

.pb-87 {
  padding-bottom: 87px !important;
}

.pt-87 {
  padding-top: 87px !important;
}

.pl-87 {
  padding-left: 87px !important;
}

.pr-87 {
  padding-right: 87px !important;
}

.mb-88 {
  margin-bottom: 88px !important;
}

.mt-88 {
  margin-top: 88px !important;
}

.ml-88 {
  margin-left: 88px !important;
}

.mr-88 {
  margin-right: 88px !important;
}

.pb-88 {
  padding-bottom: 88px !important;
}

.pt-88 {
  padding-top: 88px !important;
}

.pl-88 {
  padding-left: 88px !important;
}

.pr-88 {
  padding-right: 88px !important;
}

.mb-89 {
  margin-bottom: 89px !important;
}

.mt-89 {
  margin-top: 89px !important;
}

.ml-89 {
  margin-left: 89px !important;
}

.mr-89 {
  margin-right: 89px !important;
}

.pb-89 {
  padding-bottom: 89px !important;
}

.pt-89 {
  padding-top: 89px !important;
}

.pl-89 {
  padding-left: 89px !important;
}

.pr-89 {
  padding-right: 89px !important;
}

.mb-90 {
  margin-bottom: 90px !important;
}

.mt-90 {
  margin-top: 90px !important;
}

.ml-90 {
  margin-left: 90px !important;
}

.mr-90 {
  margin-right: 90px !important;
}

.pb-90 {
  padding-bottom: 90px !important;
}

.pt-90 {
  padding-top: 90px !important;
}

.pl-90 {
  padding-left: 90px !important;
}

.pr-90 {
  padding-right: 90px !important;
}

.mb-91 {
  margin-bottom: 91px !important;
}

.mt-91 {
  margin-top: 91px !important;
}

.ml-91 {
  margin-left: 91px !important;
}

.mr-91 {
  margin-right: 91px !important;
}

.pb-91 {
  padding-bottom: 91px !important;
}

.pt-91 {
  padding-top: 91px !important;
}

.pl-91 {
  padding-left: 91px !important;
}

.pr-91 {
  padding-right: 91px !important;
}

.mb-92 {
  margin-bottom: 92px !important;
}

.mt-92 {
  margin-top: 92px !important;
}

.ml-92 {
  margin-left: 92px !important;
}

.mr-92 {
  margin-right: 92px !important;
}

.pb-92 {
  padding-bottom: 92px !important;
}

.pt-92 {
  padding-top: 92px !important;
}

.pl-92 {
  padding-left: 92px !important;
}

.pr-92 {
  padding-right: 92px !important;
}

.mb-93 {
  margin-bottom: 93px !important;
}

.mt-93 {
  margin-top: 93px !important;
}

.ml-93 {
  margin-left: 93px !important;
}

.mr-93 {
  margin-right: 93px !important;
}

.pb-93 {
  padding-bottom: 93px !important;
}

.pt-93 {
  padding-top: 93px !important;
}

.pl-93 {
  padding-left: 93px !important;
}

.pr-93 {
  padding-right: 93px !important;
}

.mb-94 {
  margin-bottom: 94px !important;
}

.mt-94 {
  margin-top: 94px !important;
}

.ml-94 {
  margin-left: 94px !important;
}

.mr-94 {
  margin-right: 94px !important;
}

.pb-94 {
  padding-bottom: 94px !important;
}

.pt-94 {
  padding-top: 94px !important;
}

.pl-94 {
  padding-left: 94px !important;
}

.pr-94 {
  padding-right: 94px !important;
}

.mb-95 {
  margin-bottom: 95px !important;
}

.mt-95 {
  margin-top: 95px !important;
}

.ml-95 {
  margin-left: 95px !important;
}

.mr-95 {
  margin-right: 95px !important;
}

.pb-95 {
  padding-bottom: 95px !important;
}

.pt-95 {
  padding-top: 95px !important;
}

.pl-95 {
  padding-left: 95px !important;
}

.pr-95 {
  padding-right: 95px !important;
}

.mb-96 {
  margin-bottom: 96px !important;
}

.mt-96 {
  margin-top: 96px !important;
}

.ml-96 {
  margin-left: 96px !important;
}

.mr-96 {
  margin-right: 96px !important;
}

.pb-96 {
  padding-bottom: 96px !important;
}

.pt-96 {
  padding-top: 96px !important;
}

.pl-96 {
  padding-left: 96px !important;
}

.pr-96 {
  padding-right: 96px !important;
}

.mb-97 {
  margin-bottom: 97px !important;
}

.mt-97 {
  margin-top: 97px !important;
}

.ml-97 {
  margin-left: 97px !important;
}

.mr-97 {
  margin-right: 97px !important;
}

.pb-97 {
  padding-bottom: 97px !important;
}

.pt-97 {
  padding-top: 97px !important;
}

.pl-97 {
  padding-left: 97px !important;
}

.pr-97 {
  padding-right: 97px !important;
}

.mb-98 {
  margin-bottom: 98px !important;
}

.mt-98 {
  margin-top: 98px !important;
}

.ml-98 {
  margin-left: 98px !important;
}

.mr-98 {
  margin-right: 98px !important;
}

.pb-98 {
  padding-bottom: 98px !important;
}

.pt-98 {
  padding-top: 98px !important;
}

.pl-98 {
  padding-left: 98px !important;
}

.pr-98 {
  padding-right: 98px !important;
}

.mb-99 {
  margin-bottom: 99px !important;
}

.mt-99 {
  margin-top: 99px !important;
}

.ml-99 {
  margin-left: 99px !important;
}

.mr-99 {
  margin-right: 99px !important;
}

.pb-99 {
  padding-bottom: 99px !important;
}

.pt-99 {
  padding-top: 99px !important;
}

.pl-99 {
  padding-left: 99px !important;
}

.pr-99 {
  padding-right: 99px !important;
}

.mb-100 {
  margin-bottom: 100px !important;
}

.mt-100 {
  margin-top: 100px !important;
}

.ml-100 {
  margin-left: 100px !important;
}

.mr-100 {
  margin-right: 100px !important;
}

.pb-100 {
  padding-bottom: 100px !important;
}

.pt-100 {
  padding-top: 100px !important;
}

.pl-100 {
  padding-left: 100px !important;
}

.pr-100 {
  padding-right: 100px !important;
}

/***** base *****/

body {
  font-family: 'Roboto', sans-serif;
  line-height: 24px;
  font-size: 14px;
  font-weight: 400;
  color: #434343;
  overflow-x: hidden;
  background: #fdfdfd !important;
}

.container {
  position: relative;
}

.row {
  margin-left: -7px;
  margin-right: -7px;
}

.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
  padding-left: 7px;
  padding-right: 7px;
}

.modal {
  display: block;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s all;
}

.modal.show {
  visibility: visible;
  opacity: 1;
}

a {
  transition: 0.2s all;
  cursor: pointer;
}

a:hover {
  text-decoration: none;
}

svg,
path,
button {
  transition: 0.2s all;
}

ul {
  list-style: none;
  margin-bottom: 0;
  padding: 0;
}

ul li {
  list-style: none;
}

.main-content {
  overflow-x: hidden;
  overflow-y: hidden;
}

#header .header-desktop {
  padding: 0;
  display: block;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

#header.bgrColor {
  -webkit-box-shadow: 0 0 15px rgb(0 0 0 / 15%);
  box-shadow: 0 0 15px rgb(0 0 0 / 15%);
}

#header.sticky {
  -webkit-animation-duration: 1.3s;
  animation-duration: 1.3s;
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-timing-function: ease;
  animation-timing-function: ease;
  /* background-color: #fff; */
  /* -webkit-box-shadow: 0 0 15px rgb(0 0 0 / 15%); */
  /* box-shadow: 0 0 15px rgb(0 0 0 / 15%); */
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 100;
  transition: 1.3s all;
}

div#header.sticky {
  transition: 1.3s all;
}

#offshore #header.sticky {
  background-color: #fff;
  transition: 1.3s all;
}

#header.stiky {
  transition: 1.3s all;
}

/*@media screen and (min-width: 200px) and (max-width: 767px) {*/
/*    #header .header-desktop {*/
/*        display: none;*/
/*    }*/
/*}*/
@media screen and (min-width: 200px) and (max-width: 1199px) {
  #header .header-desktop {
    display: none;
  }
}

#header .header-desktop .logo {
  display: flex;
  height: 100%;
  align-items: center;
  padding-bottom: 7px;
}

#header .header-desktop .container-fluid {
  padding: 0;
  padding-left: calc((100% - 1110px) / 2);
}

#header .header-mobile {
  -webkit-animation-duration: 1.3s;
  animation-duration: 1.3s;
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-timing-function: ease;
  animation-timing-function: ease;
  display: none;
  background: #fff;
  padding: 20px 0 20px;
}

/*@media screen and (min-width: 200px) and (max-width: 767px) {*/
/*    #header .header-mobile {*/
/*        display: block;*/
/*    }*/
/*}*/
/*@media screen and (min-width: 769px) {*/
/*  #header .header-mobile {*/
/*    display: none;*/
/*  }*/
/*}*/

#header .header-mobile .js-open-canvas svg>path {
  fill: #000;
}

#header .header-mobile .canvas-menu {
  padding: 39px 16px;
  position: fixed;
  top: 0;
  left: 100%;
  bottom: 100%;
  background-color: #fff;
  width: 100%;
  height: 100%;
  transition: 0.4s all;
  z-index: 10;
}

#header .header-mobile .canvas-menu.open {
  left: 0;
}

#header .header-mobile .canvas-menu .top-menu {
  display: flex;
  justify-content: space-between;
}

#header .header-mobile .canvas-menu .top-menu .js-close-canvas {
  padding: 0 14px;
}

#header .header-mobile .canvas-menu .megamenu {
  text-align: center;
}

#header .header-mobile .canvas-menu .megamenu ul li {
  margin-bottom: 15px;
}

#header .header-mobile .canvas-menu .megamenu ul li.active a {
  color: #0063aa;
}

#header .header-mobile .canvas-menu .megamenu ul li a {
  font-size: 16px;
  color: #a6a6a6;
  font-weight: 500;
}

#header .header-mobile .canvas-menu .language {
  text-align: center;
}

#header .header-mobile .canvas-menu .language .dropdown-menu {
  left: 50%;
  transform: translateX(-50%);
}

#header .col-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

#header .col-right .megamenu {
  padding-right: 15px;
}

#header .col-right .megamenu ul {
  display: flex;
}

#header .col-right .megamenu ul li a::after {
  content: '';
  width: 97px;
  border-radius: 15px;
  height: 4px;
  background-color: #0063aa;
  position: absolute;
  bottom: 0;
  justify-content: center;
  align-items: center;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: 0.4s;
}

#header .col-right .megamenu ul li.active a,
#header .col-right .megamenu ul li:hover a {
  color: #696969;
  position: relative;
}

#header .col-right .megamenu ul li.active a::after,
#header .col-right .megamenu ul li:hover a::after {
  opacity: 1;
  background-color: #0063aa;
}

#header .col-right .megamenu ul li a {
  font-size: 17px;
  line-height: 81px;
  color: #696969;
  font-weight: 500;
  position: relative;
  padding: 0 19px;
  display: block;
}

#header .col-right .language {
  margin-left: 14px;
  margin-right: 7px;
}

#header .col-right .language button {
  border: none;
  background: transparent;
  padding: 0;
}

#header .col-right .language button span {
  margin-right: 4px;
}

#header .col-right .language .dropdown-menu {
  background: #ffffff;
  box-shadow: 0px 12px 14px rgba(189, 189, 189, 0.25);
  border-radius: 5px;
  border: none;
  width: 106px;
  min-width: 106px;
  left: -27px;
  top: calc(100% + 7px);
  padding: 4px 0;
}

#header .col-right .language .dropdown-menu a {
  font-size: 12px;
  font-weight: 500;
  color: #121212;
  padding: 0 15px;
  position: relative;
}

#header .col-right .language .dropdown-menu a:active {
  background-color: transparent;
}

#header .col-right .language .dropdown-menu a+a::before {
  content: '';
  width: calc(100% - 30px);
  top: -1px;
  left: 15px;
  right: 15px;
  position: absolute;
  height: 1px;
  background-color: #e2e2e2;
  z-index: 1;
}

#header .col-right .language .dropdown-menu a img {
  margin-right: 5px;
}

#header .language {
  margin-left: 14px;
  margin-right: 7px;
}

#header .language button {
  border: none;
  background: transparent;
  padding: 0;
}

#header .language button span {
  margin-right: 4px;
}

#header .language .dropdown-menu {
  background: #ffffff;
  box-shadow: 0px 12px 14px rgba(189, 189, 189, 0.25);
  border-radius: 5px;
  border: none;
  width: 106px;
  min-width: 106px;
  left: -27px;
  top: calc(100% + 7px);
  padding: 4px 0;
}

#header .language .dropdown-menu a {
  font-size: 12px;
  font-weight: 500;
  color: #121212;
  padding: 0 15px;
  position: relative;
}

#header .language .dropdown-menu a:active {
  background-color: transparent;
}

#header .language .dropdown-menu a+a::before {
  content: '';
  width: calc(100% - 30px);
  top: -1px;
  left: 15px;
  right: 15px;
  position: absolute;
  height: 1px;
  background-color: #e2e2e2;
  z-index: 1;
}

#header .language .dropdown-menu a img {
  margin-right: 5px;
}

/* @media (max-width: 1800px) {
    #header .col-right .megamenu ul li a {
        font-size: 18px;
        padding: 0 20px;
    }
} */

/***** fonts *****/

/***** pages *****/

/* @media screen and (min-width: 200px) and (max-width: 767px) {
  #home .r-process .col-right ul li:hover .content {
    padding-left: 23px;
  }
} */

/* @media (max-width: 575px) {
  #home .r-featured-project .customSlickSlider .slick-arrow.slick-next {
    opacity: 1;
    left: calc(100% - 68px);
  }
} */

@media (min-width: 1200px) {
  .r-recruitment .col-left {
    flex: 0 0 29%;
    max-width: 29%;
  }

  .r-recruitment .col-right {
    flex: 0 0 71%;
    max-width: 71%;
    padding-left: 69px;
    margin-top: -14px;
  }

  .r-moment .col-left {
    flex: 0 0 53.5%;
    max-width: 53.5%;
  }

  .r-moment .col-right {
    flex: 0 0 46.5%;
    max-width: 46.5%;
  }
}

#about .title-center {
  margin-bottom: 58px;
  text-align: center;
}

#about .title-center h3 {
  font-size: 48px;
  font-weight: 900;
  color: #0063aa;
  position: relative;
  margin-bottom: 29px;
}

#about .r-company-profile .row-mobile {
  display: none;
}

#about .img-about-us-sp {
  display: none;
}

#about .r-top-banner .content-sp {
  display: none;
}

@media (max-width: 767px) {
  #about .r-top-banner img {
    display: none;
  }

  #about .r-top-banner .content-sp {
    display: block;
    text-align: center;
    padding-top: 100px;
    position: relative;
  }

  #about .r-top-banner .content-sp h3 {
    font-weight: 900;
    font-size: 39.8954px;
    line-height: 47px;
    position: relative;
    color: #0063aa;
  }

  #about .r-top-banner .content-sp::after {
    content: '';
    position: absolute;
    width: 117.98px;
    height: 117.98px;
    right: -49px;
    top: -26px;
    background: #0063aa;
    border-radius: 50%;
  }

  #about .r-top-banner .content-sp p {
    font-weight: 900;
    font-size: 32px;
    line-height: 38px;
    color: #0063aa;
  }

  #about .col-left .img-about-us-pc {
    display: none;
  }

  /* #about .r-company-profile .row-pc {
        display: none;
    } */

  #about .img-about-us-sp {
    display: block;
  }

  #about .r-company-profile .row-mobile {
    display: block;
    margin-top: 70px;
  }

  #about .r-company-profile .row-mobile .ant-tabs-nav-list {
    display: flex;
    gap: 60px;
    margin-bottom: 14px;
    align-items: center;
    margin-left: 22px;
  }

  #about .r-company-profile .row-mobile .ant-tabs-nav-list .ant-tabs-tab {
    position: relative;
    padding-left: 50px;
    font-weight: 700;
    font-size: 16px;
    line-height: 140%;
    color: #968f8f;
    transition: 0.4s all;
    white-space: nowrap;
  }

  #about .r-company-profile .row-mobile .ant-tabs-nav-list .ant-tabs-tab::before {
    content: url(/assets/images/about/icon-tabs-japan.svg);
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    top: calc(50% - 6px);
    transform: translateY(-50%);
  }

  #about .r-company-profile .row-mobile .ant-tabs-nav-list .ant-tabs-tab+.ant-tabs-tab::before {
    content: url(/assets/images/about/icon-tabs-vn.svg);
  }

  #about .r-company-profile .row-mobile .ant-tabs-nav-list .ant-tabs-tab-active {
    color: #0063aa;
    transition: 0.4s all;
    font-weight: 700;
  }

  #about .r-company-profile .row-mobile .ant-tabs-nav-list .ant-tabs-tab::after {
    content: '';
    position: absolute;
    width: 32px;
    height: 4px;
    left: 0;
    bottom: -7px;
    border-radius: 3px;
    background: #0063aa;
    opacity: 0;
  }

  #about .r-company-profile .row-mobile .ant-tabs-nav-list .ant-tabs-tab-active::after {
    opacity: 1;
    transition: 0.4s all;
  }
}

@media screen and (min-width: 200px) and (max-width: 1199px) {
  #about .title-center h3 {
    font-size: 18px;
    line-height: 26px;
  }
}

#about .title-center h3::after {
  content: '';
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -10px;
  width: 48px;
  height: 4px;
  background-color: #0063aa;
  z-index: 1;
  border-radius: 19px;
}

@media screen and (min-width: 200px) and (max-width: 1199px) {
  #about .title-center h3::after {
    width: 14px;
    height: 1px;
  }
}

#about .title-center .desc {
  font-size: 20px;
  color: #686868;
}

#about .title-left {
  margin-bottom: 58px;
}

#about .title-left h3 {
  font-size: 48px;
  font-weight: 900;
  color: #0063aa;
  position: relative;
  margin-bottom: 29px;
}

@media screen and (min-width: 200px) and (max-width: 1199px) {
  #about .title-left h3 {
    font-size: 18px;
    line-height: 26px;
  }
}

#about .title-left h3::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -23px;
  border-radius: 5px;
  width: 48px;
  height: 4px;
  background-color: #0063aa;
  z-index: 1;
}

@media screen and (min-width: 200px) and (max-width: 1199px) {
  #about .title-left h3::after {
    bottom: -5px;
    width: 14px;
    height: 1px;
  }
}

#about .title-left .desc {
  font-size: 20px;
  color: #686868;
}

@media screen and (min-width: 200px) and (max-width: 767px) {
  #about .title-left .desc {
    font-size: 14px;
    line-height: 24px;
  }
}

#about .r-top-banner {
  padding-top: 100px;
  margin-top: 12px;
}

@media screen and (min-width: 200px) and (max-width: 1199px) {
  #about .r-top-banner img {
    width: 100%;
  }
}

#about .r-about-us {
  padding-top: 51px;
  background: #f3faff;
}

#about .r-about-us .container {
  max-width: 1420px;
}

#about .r-about-us .container .row {
  align-items: flex-end;
}

#about .r-about-us .col-left img {
  width: 128%;
}

@media screen and (min-width: 200px) and (max-width: 1199px) {
  #about .r-about-us .col-left img {
    max-width: 100%;
  }
}

#about .r-about-us .col-right .box {
  background: #fff;
  padding: 50px 80px 40px;
  margin-left: 79px;
  margin-bottom: 45px;
}

@media screen and (min-width: 200px) and (max-width: 1199px) {
  #about .r-about-us .col-right .box {
    margin-left: 0;
  }
}

#about .r-about-us .col-right .title-left {
  margin-bottom: 0;
}

#about .r-about-us .col-right .title-left h3 {
  margin-bottom: 50px;
}

#about .r-about-us .col-right p {
  color: #000;
  font-size: 24px;
  line-height: 32px;
  width: 550px;
  max-width: 100%;
  margin-bottom: 19px;
}

@media screen and (min-width: 200px) and (max-width: 1199px) {
  #about .r-about-us .col-right p {
    color: #000;
    font-size: 14px;
    line-height: 24px;
  }

  #about .r-about-us .col-right .title-left h3 {
    margin-bottom: 26px;
  }
}

#about .r-company-profile {
  margin-top: 108px;
}

@media screen and (min-width: 200px) and (max-width: 767px) {
  #about .r-company-profile {
    margin-top: 58px;
  }
}

#about .r-company-profile .title-center {
  margin-bottom: 102px;
}

@media screen and (min-width: 200px) and (max-width: 767px) {
  #about .r-company-profile .title-center {
    margin-bottom: 52px;
  }
}

#about .r-company-profile .inner {
  padding-right: 70px;
}

#about .r-company-profile h5 {
  font-size: 24px;
  line-height: 28px;
  color: #0063aa;
  font-weight: 900;
  margin-bottom: 51px;
}

@media screen and (min-width: 200px) and (max-width: 1199px) {
  #about .r-company-profile h5 {
    font-size: 14px;
    line-height: 24px;
  }

  #about .r-company-profile .inner {
    padding-right: 0px;
  }
}

#about .r-company-profile .list {
  display: flex;
  flex-wrap: wrap;
  font-family: 'Noto Sans JP';
}

@media (max-width: 767px) {
  #about .custonBgTabs {
    background: #f8f8f8;
    padding: 0;
  }

  #about .r-company-profile .container {
    max-width: 100%;
    padding: 0;
  }

  /* dung */
  #about .r-company-profile .list {
    background: #f8f8f8;
    padding: 23px 5px 20px 23px;
  }

  /* dung */

  #about .r-company-profile .list {
    background: #f8f8f8;
  }
}

#about .r-company-profile .list dt {
  font-size: 16px;
  font-weight: 700;
  color: #000;
  width: 95px;
  padding-right: 15px;
}

@media screen and (min-width: 200px) and (max-width: 1199px) {
  #about .r-company-profile .list dt {
    font-size: 14px;
    line-height: 24px;
  }
}

#about .r-company-profile .list dt:nth-of-type(n + 2) {
  border-top: 1px solid #d9d9d9;
  margin-top: 11px;
  padding-top: 22px;
}

#about .r-company-profile .list dd {
  font-size: 18px;
  line-height: 25.2px;
  color: #000;
  width: calc(100% - 100px);
}

@media screen and (min-width: 200px) and (max-width: 1199px) {
  #about .r-company-profile .list dd {
    font-size: 15px;
    line-height: 24px;
    margin-left: -10px;
  }
}

#about .r-company-profile .list dd p {
  margin-bottom: 0;
}

#about .r-company-profile .list dd p+p {
  margin-top: 19px;
}

#about .r-company-profile .list dd:nth-of-type(n + 2) {
  border-top: 1px solid #d9d9d9;
  margin-top: 11px;
  padding-top: 22px;
}

#about .r-some-image {
  margin-top: 154px;
}

@media (max-width: 1199px) {
  #about .r-some-image {
    margin-top: 32px;
  }
}

@media (max-width: 991px) {
  .height-three-rows {
    padding-bottom: 0 !important;
  }
}

#about .r-some-image .title-center {
  margin-bottom: 29px;
}

#about .r-some-image .title-center h3::after {
  bottom: -18px;
}

#about .r-some-image .container-fluid {
  padding: 0;
}

#about .r-some-image .inner {
  padding: 73px 0 66px;
  background-color: #f3faff;
  position: relative;
}

#about .r-some-image .inner::before {
  content: '';
  width: 645px;
  height: 100%;
  background: url('/assets/images/about/blur-1.png') no-repeat center;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
}

#about .r-some-image .inner::after {
  content: '';
  width: 654px;
  height: 100%;
  background: url('/assets/images/about/blur-2.png') no-repeat center;
  background-size: cover;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 9;
}

#about .r-some-image .some-image-slider .slick-slide {
  padding: 0 7px;
}

@media (max-width: 767px) {
  #about .r-some-image .some-image-slider .slick-slide {
    padding: 0px;
  }
}

#about .r-some-image .some-image-slider .slick-slide img {
  transform: scale(0.93) translateX(50px);
  transition: 0.4s all;
}

#about .r-some-image .some-image-slider .slick-slide.slick-center {
  margin: 0;
  position: relative;
  z-index: 10;
}

#about .r-some-image .some-image-slider .slick-slide.slick-center img {
  transform: scale(1);
}

#about .r-some-image .some-image-slider .slick-slide.slick-center+.slick-slide img {
  transform: scale(0.93) translateX(-50px);
}

#about .r-some-image .some-image-slider img {
  width: 100%;
}

#about .r-some-image .slick-arrow {
  border: none;
  background: transparent;
  position: absolute;
  top: 50%;
  z-index: 10;
  transform: translateY(-50%);
}

#about .r-some-image .slick-arrow:hover {
  opacity: 0.8;
}

#about .r-some-image .slick-arrow.slick-prev {
  left: 100px;
}

#about .r-some-image .slick-arrow.slick-next {
  right: 100px;
}

#about .r-our-team {
  margin-top: 151px;
}

@media screen and (min-width: 200px) and (max-width: 1199px) {
  #about .r-our-team {
    margin-top: 36px;
  }
}

#about .r-our-team .container {
  max-width: 100%;
}

#about .r-our-team .title-center {
  margin-bottom: 36px;
}

#about .r-our-team .title-center h3::after {
  bottom: -20px;
}

#about .r-our-team .inner {
  background: #ebf7ff;
  padding: 86px 0 71px;
  position: relative;
}

@media screen and (min-width: 200px) and (max-width: 1199px) {
  #about .r-our-team .inner {
    padding-top: 0;
  }
}

#about .r-our-team .inner .label {
  font-size: 12.6vw;
  font-weight: 900;
  color: #ebf7ff;
  position: absolute;
  bottom: calc(100% - 26px);
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: -1;
  width: 100%;
  text-align: center;
  line-height: 1;
  text-transform: uppercase;
}

#about .r-our-team .inner .box-content {
  max-width: 1597px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

#about .r-our-team .inner ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

@media screen and (min-width: 200px) and (max-width: 1199px) {
  #about .r-our-team .inner ul {
    justify-content: center;
    padding: 0;
  }
}

#about .r-our-team .inner ul li {
  transition: 0.4s all;
  flex: 0 0 20%;
  max-width: 20%;
  text-align: center;
  position: relative;
  cursor: pointer;
}

@media screen and (min-width: 200px) and (max-width: 1620px) {
  #about .r-our-team .inner ul li {
    flex: 0 0 25%;
    max-width: 25%;
  }
}

@media screen and (min-width: 200px) and (max-width: 1520px) {
  #about .r-our-team .inner ul li {
    flex: 0 0 30%;
    max-width: 30%;
  }
}

@media screen and (min-width: 200px) and (max-width: 991px) {
  #about .r-our-team .inner ul li {
    flex: 0 0 50%;
    max-width: 50%;
    margin-bottom: 0;
  }
}

@media screen and (min-width: 200px) and (max-width: 767px) {
  #about .r-our-team .inner ul li {
    flex: 0 0 100%;
    max-width: 50%;
  }
}

#about .r-our-team .inner ul li:hover::before {
  transition: 0.4s all;
  content: '';
  position: absolute;
  background: #fff;
  width: 383px;
  height: 383px;
  transform: translateX(-50%);
  left: 50%;
  border-radius: 50%;
  border: 1px solid #0063aa;
  box-shadow: 1px 2px 18px 6px rgba(0, 0, 0, 0.15);
}

@media screen and (min-width: 767.5px) and (max-width: 1770px) {
  #about .r-our-team .inner ul li:hover::before {
    width: 375px;
    height: 375px;
  }
}

@media screen and (min-width: 767.5px) and (max-width: 1199px) {
  #about .r-our-team .inner ul li:hover::before {
    width: 360px;
    height: 360px;
  }
}

@media screen and (min-width: 767.5px) and (max-width: 991px) {
  #about .r-our-team .inner ul li:hover::before {
    width: 370px;
    height: 370px;
  }
}

@media screen and (min-width: 767.5px) and (max-width: 390px) {
  #about .r-our-team .inner ul li:hover::before {
    width: 330px;
    height: 330px;
  }
}

#about .r-our-team .inner ul li .box {
  display: block;
  padding: 5px 0px;
  overflow: hidden;
  transition: 0.2s all;
  border: 1px solid transparent;
  margin-bottom: -14px;
  position: relative;
}

@media screen and (min-width: 200px) and (max-width: 1199px) {
  #about .r-our-team .inner ul li .box {
    overflow: inherit;
  }
}

#about .r-our-team .inner ul li .image {
  margin-top: 70px;
  margin-bottom: -13px;
  height: 201px;
  position: relative;
}

#about .r-our-team .inner ul li .image img {
  position: relative;
  z-index: 1;
}

@media screen and (min-width: 200px) and (max-width: 390px) {
  #about .r-our-team .inner ul li .image {
    margin-top: 0;
  }
}

#about .r-our-team .inner ul li .content {
  position: relative;
  z-index: 1;
}

#about .r-our-team .inner ul li .content .name {
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
  text-transform: uppercase;
  margin-bottom: 4px;
  color: #0063aa;
}

@media screen and (min-width: 200px) and (max-width: 1199px) {
  #about .r-our-team .inner ul li .content .name {
    font-size: 13px;
    line-height: 24px;
  }
}

#about .r-our-team .inner ul li .content p {
  font-size: 16px;
  line-height: 32px;
  color: #292929;
}

@media screen and (min-width: 200px) and (max-width: 1199px) {
  #about .r-our-team .inner ul li .content p {
    font-size: 11px;
    line-height: 24px;
  }
}

#about #exampleModal {
  background: rgba(0, 0, 0, 0.25);
  padding-right: 0 !important;
}

#about #exampleModal .modal-dialog {
  width: 1110px !important;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  max-width: 100%;
  margin: 0 auto;
  transition: 0.4s;
}

@media screen and (min-width: 200px) and (max-width: 1124px) {
  #about #exampleModal .modal-dialog {
    width: 800px;
  }
}

@media screen and (min-width: 200px) and (max-width: 767px) {
  #about #exampleModal .modal-dialog {
    width: auto;
  }
}

@media screen and (min-width: 200px) and (max-width: 767px) {
  #about #exampleModal .modal-dialog {
    padding: 0 15px;
    width: 100%;
  }
}

#about #exampleModal .modal-dialog .modal-content {
  border: none;
}

#about #exampleModal .modal-header {
  border: none;
}

#about #exampleModal .modal-header button {
  /* border: 1px solid #ccc; */
  background: url('/assets/images/about/button-about.svg') center no-repeat;
  background-size: cover;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  right: 30px;
  top: 30px;
  position: absolute;
  z-index: 1;
  border: none;
}

@media screen and (min-width: 200px) and (max-width: 767px) {
  #about #exampleModal .modal-header button {
    width: 25px;
    height: 25px;
    right: 24px;
    top: 23px;
  }
}

#about #exampleModal .modal-header button span {
  display: none;
}

#about #exampleModal .modal-body {
  padding-top: 30px;
  padding-bottom: 22px;
}

@media screen and (min-width: 200px) and (max-width: 767px) {
  #about #exampleModal .modal-body {
    padding-top: 0;
  }
}

@media screen and (min-width: 200px) and (max-width: 767px) {
  #about #exampleModal .modal-body {
    overflow: scroll;
    height: 550px;
  }
}

#about #exampleModal .modal-body .wrap-avatar {
  padding: 0 23px 35px 34px;
  position: relative;
}

@media screen and (min-width: 200px) and (max-width: 767px) {
  #about #exampleModal .modal-body .wrap-avatar {
    padding: 0;
    margin-top: 20px;
    padding: 0 25px;
    display: block !important;
  }

  #about #exampleModal .modal-body .wrap-avatar>.avatar {
    width: 100% !important;
  }

  #about #exampleModal .modal-body .wrap-avatar>.wrap-profiles {
    width: 100% !important;
  }
}

#about #exampleModal .modal-body .wrap-avatar .avatar::before {
  content: '';
  background: url('/assets/images/about/bg-avt.png') no-repeat;
  width: 100%;
  position: absolute;
  height: 100%;
  left: 33%;
  top: 0;
}

@media screen and (min-width: 200px) and (max-width: 1199px) {
  #about #exampleModal .modal-body .wrap-avatar .avatar::before {
    display: none;
  }
}

#about #exampleModal .modal-body .wrap-avatar .avatar {
  border: 5px solid #0063aa;
  padding-top: 38px;
  text-align: center;
  position: relative;
}

@media screen and (min-width: 200px) and (max-width: 767px) {
  #about #exampleModal .modal-body .wrap-avatar .avatar {
    border: 2px solid #0063aa;
    padding-top: 20px;
  }
}

#about #exampleModal .modal-body .wrap-avatar .avatar img {
  position: relative;
  z-index: 1;
}

@media screen and (min-width: 200px) and (max-width: 767px) {
  #about #exampleModal .modal-body .wrap-avatar .avatar img {
    max-height: 250px;
  }
}

#about #exampleModal .modal-body .wrap-profiles {
  padding: 0 65px 0 65px;
}

@media screen and (min-width: 200px) and (max-width: 767px) {
  #about #exampleModal .modal-body .wrap-profiles {
    padding: 25px 10px;
  }
}

#about #exampleModal .modal-body .wrap-profiles .profiles {
  text-align: left;
}

#about #exampleModal .modal-body .wrap-profiles .profiles h3 {
  font-weight: 700;
  font-size: 32px;
  line-height: 32px;
  color: #0063aa;
}

@media screen and (min-width: 200px) and (max-width: 1199px) {
  #about #exampleModal .modal-body .wrap-profiles .profiles h3 {
    font-size: 18px;
    line-height: 28px;
  }
}

#about #exampleModal .modal-body .wrap-profiles .profiles .position {
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  color: #0063aa;
  padding-bottom: 19px;
}

@media screen and (min-width: 200px) and (max-width: 1199px) {
  #about #exampleModal .modal-body .wrap-profiles .profiles .position {
    font-size: 14px;
    line-height: 24px;
  }
}

#about #exampleModal .modal-body .wrap-profiles .profiles p {
  font-weight: 400;
  font-size: 24px;
  line-height: 140%;
  color: #000;
}

@media screen and (min-width: 200px) and (max-width: 1199px) {
  #about #exampleModal .modal-body .wrap-profiles .profiles p {
    font-size: 14px;
    line-height: 24px;
  }
}

#about .r-company {
  background: url('/assets/images/about/bg-company.png') center no-repeat;
  background-size: cover;
  padding: 220px 0 241px;
}

@media screen and (min-width: 200px) and (max-width: 1199px) {
  #about .r-company {
    padding: 150px 0;
  }
}

@media screen and (min-width: 200px) and (max-width: 767px) {
  #about .r-company {
    padding: 100px 0;
  }
}

#about .r-company .title-center h3 {
  margin-bottom: 42px;
}

#about .r-company .title-center h3::after {
  background-color: #fff;
}

#about .r-company .title-center p {
  color: #fff;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  padding-bottom: 22px;
}

@media screen and (min-width: 200px) and (max-width: 1199px) {
  #about .r-company .title-center p {
    font-size: 14px;
    line-height: 24px;
  }
}

#about .r-company .handleDown {
  display: block;
  text-align: center;
  font-weight: 700;
  font-size: 32px;
  line-height: 24px;
}

@media screen and (min-width: 200px) and (max-width: 1199px) {
  #about .r-company .handleDown {
    font-size: 18px;
  }
}

#about .r-company .handleDown a {
  border: 2px solid #ffffff;
  border-radius: 200px;
  padding: 15px 36px;
}

#about .r-company .handleDown a span {
  margin-right: 11px;
}

@media screen and (min-width: 200px) and (max-width: 1199px) {
  #about .r-company .handleDown a {
    padding: 12px 24px;
  }

  #about .r-company .handleDown a svg {
    width: 40px;
  }
}

@media (min-width: 991.5px) {
  #about .r-company .handleDown a:hover {
    background: #0063aa;
    opacity: 0.8;
    border-color: #0063aa;
  }
}

#about .r-archivement {
  padding: 0 0 19px;
}

#about .r-archivement .title-center {
  margin-bottom: 49px;
}

#about .r-archivement .title-center p {
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: #000000;
}

@media screen and (min-width: 200px) and (max-width: 1199px) {
  #about .r-archivement .title-center p {
    font-size: 14px;
    line-height: 24px;
  }
}

#about .r-archivement .row {
  margin-left: -21px;
  margin-right: -21px;
}

#about .r-archivement .row>div {
  padding-left: 21px;
  padding-right: 21px;
}

#about .r-archivement .box {
  padding: 28px 33px 23px;
  box-shadow: inset 0px 0px 8.29464px rgba(0, 0, 0, 0.1);
  border-radius: 6.63571px;
  height: 100%;
}

#about .r-archivement .box.box-1 {
  background-color: #0063aa;
}

#about .r-archivement .box.box-2 {
  background-color: #fde002;
}

#about .r-archivement .box.box-2 h2,
#about .r-archivement .box.box-2 p {
  color: #0063aa;
}

#about .r-archivement .box.box-3 {
  background-color: #eb2725;
}

#about .r-archivement .box.box-4 {
  background-color: #00be90;
}

#about .r-archivement .box .icon {
  height: 81px;
  margin-bottom: 26px;
  display: flex;
  align-items: flex-end;
}

#about .r-archivement .box h2 {
  font-weight: 900;
  font-size: 42px;
  line-height: 49px;
  color: #fff;
  margin-bottom: 5px;
}

#about .r-archivement .box p {
  font-weight: 500;
  font-size: 24px;
  line-height: 33px;
  color: #fff;
  margin-bottom: 0;
}

#about .r-customer {
  padding: 66px 0 0;
  position: relative;
}

@media (max-width: 1199px) {
  #about .r-customer {
    padding: 0;
  }
}

#about .r-customer .title-center {
  margin-bottom: 54px;
}

#about .r-customer .title-center h3 {
  font-weight: 900;
  font-size: 48px;
  line-height: 56px;
}

@media screen and (min-width: 200px) and (max-width: 1199px) {
  #about .r-customer .title-center h3 {
    font-size: 18px;
    margin-bottom: 22px;
  }

  #about .r-customer .title-center h3::after {
    bottom: 10px;
    width: 14px;
    height: 1px;
  }
}

#about .r-customer .container-fluid {
  /* width: 1435px;
    max-width: 100%; */
  margin-top: 50px;
}

#about .r-customer .container-fluid .brands-list {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
}

#about .r-customer .container-fluid .brands-list li {
  margin: 0 57px 64px 57px;
}

@media screen and (min-width: 200px) and (max-width: 1199px) {
  #about .r-customer .container-fluid .brands-list li {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
    margin: 0 0 11px !important;
    text-align: center;
  }

  #about .r-customer .container-fluid .brands-list li img {
    width: 50%;
    aspect-ratio: 3/2;
    object-fit: contain;
    padding: 0 !important;
  }
}

@media (max-width: 991px) {
  #about .r-customer .map {
    margin-top: 0;
  }
}

@media (max-width: 1600px) {
  #about .r-our-team .inner .label {
    bottom: 99.5%;
  }

  #about .r-some-image .some-image-slider .slick-slide img {
    transform: scale(0.93) translateX(30px);
  }

  #about .r-some-image .some-image-slider .slick-slide.slick-center+.slick-slide img {
    transform: scale(0.93) translateX(-26px);
  }
}

@media (max-width: 1199px) {
  #about .r-archivement .box p {
    font-size: 14px;
    line-height: 24px;
  }

  #about .r-archivement .box h2 {
    font-size: 30px;
    line-height: 40px;
  }

  #about .r-archivement .row {
    margin-left: -10px;
    margin-right: -10px;
  }

  #about .r-archivement .row>div {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (max-width: 991px) {

  #about .r-some-image .inner::before,
  #about .r-some-image .inner::after {
    width: 200px;
  }

  #about .r-some-image .some-image-slider .slick-slide img {
    transform: scale(0.93) translateX(0);
  }

  #about .r-some-image .some-image-slider .slick-slide.slick-center+.slick-slide img {
    transform: scale(0.93) translateX(0);
  }
}

@media (max-width: 767px) {
  #about .r-about-us .col-right .box {
    padding: 30px 15px;
  }

  #about .r-some-image .slick-arrow.slick-prev {
    left: 0;
  }

  #about .r-some-image .slick-arrow.slick-next {
    right: 0;
  }

  #about .r-some-image .inner::before,
  #about .r-some-image .inner::after {
    width: 115px;
  }

  #about .r-archivement .box .icon {
    height: 50px;
    margin-bottom: 10px;
  }

  #about .r-archivement .box .icon img {
    width: 40px;
  }

  #about .r-archivement .box h2 {
    font-size: 18px;
    line-height: 28px;
  }

  #about .r-archivement .row>div {
    margin-bottom: 20px;
  }

  #about .r-archivement .title-center {
    margin-bottom: 30px;
  }

  #about .r-archivement .title-center h3::after {
    bottom: -10px;
  }

  #about .r-archivement {
    padding: 50px 0 30px;
  }
}

#project-details .r-treasure-slider {
  margin-top: 36px;
  margin-bottom: 69px;
  position: relative;
}

#project-details .r-treasure-slider>.container {
  max-width: 1140px;
}

#project-details .r-treasure-slider .slick-track {
  display: flex;
  align-items: center;
  margin: 0 -23px;
}

@media (max-width: 767px) {
  #project-details .r-treasure-slider .slick-track {
    margin: 0 -5px;
  }
}

#project-details .r-treasure-slider .slick-slider {
  margin-right: 285px;
}

@media (max-width: 767px) {
  #project-details .r-treasure-slider .slick-slider {
    margin-right: 85px;
  }
}

#project-details .r-treasure-slider .slick-list {
  overflow: visible;
}

#project-details .main-content .handleBlack {
  margin-top: 150px;
}

#project-details .main-content .handleBlack>.container {
  max-width: 1140px;
}

#project-details .r-treasure-slider .slick-slide {
  align-items: center;
  display: flex;
  height: 465px;
  /* height: auto; */
  object-fit: contain;
  padding: 0 23px;
  position: relative;
}

@media (max-width: 767px) {
  #project-details .r-treasure-slider .slick-slide {
    height: 165px;
    padding: 0 5px;
  }
}

#project-details .r-treasure-slider .slick-list:before {
  background-color: #fff;
  content: '';
  height: 100%;
  position: absolute;
  right: 100%;
  top: -4px;
  width: 1000%;
  z-index: 10;
}

#project-details .r-treasure-slider .slick-slide div {
  border-radius: 16px;
  overflow: hidden;
  width: 100%;
}

#project-details .r-treasure-slider .slick-slide img {
  max-width: 80%;
  object-fit: cover;
}

#project-details .r-treasure-slider .slick-active img {
  max-width: 100%;
}

#project-details .r-treasure-slider .slick-slide img {
  width: 100%;
  object-fit: cover;
  transition: 0.4s all;
}

#project-details .r-treasure-slider .slick-slide::after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 18px;
  background: -webkit-gradient(linear,
      left top,
      right top,
      from(rgba(255, 255, 255, 0)),
      color-stop(0.01%, rgba(255, 255, 255, 0)),
      color-stop(0.02%, rgba(255, 255, 255, 0)),
      color-stop(3.12%, rgba(255, 255, 255, 0)),
      color-stop(28.3%, rgba(255, 255, 255, 0)),
      color-stop(100%, #ffffff),
      to(#ffffff));
  background: linear-gradient(90deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0) 0.01%,
      rgba(255, 255, 255, 0) 0.02%,
      rgba(255, 255, 255, 0) 3.12%,
      rgba(255, 255, 255, 0) 28.3%,
      #ffffff 100%,
      #ffffff 100%);
  z-index: 10;
}

#project-details .r-treasure-slider .slick-arrow {
  display: none !important;
}

#project-details .r-treasure-slider .slick-slide.slick-active::after {
  display: none;
  max-height: 465px;
  object-fit: contain;
}

#project-details .main-content .handleBlack a {
  color: #3e3c3c;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  padding: 9px;
  cursor: pointer;
}

#project-details .main-content .handleBlack a svg {
  margin-right: 10px;
}

#project-details .r-treasure-slider .treasure-carousel {
  padding-right: 283px;
}

#project-details .main-content .handleBlack a:hover svg {
  margin-left: -10px;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

#project-details .r-treasure-slider .treasure-carousel::after {
  content: '';
  width: 100%;
  height: 1px;
  background-color: #0063aa;
  position: absolute;
  left: 0;
  bottom: 17px;
  z-index: 1;
}

#project-details .r-treasure-slider .owl-stage-outer {
  overflow: visible;
}

#project-details .r-treasure-slider .owl-stage-outer::before {
  content: '';
  width: 1000%;
  height: 100%;
  background-color: #fff;
  position: absolute;
  top: 0;
  right: 100%;
  z-index: 10;
}

#project-details .r-treasure-slider .item-2 a {
  display: block;
  position: relative;
}

#project-details .r-treasure-slider .item-2 a::after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 18px;
  background: linear-gradient(90deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0) 0.01%,
      rgba(255, 255, 255, 0) 0.02%,
      rgba(255, 255, 255, 0) 3.12%,
      rgba(255, 255, 255, 0) 28.3%,
      #ffffff 100%,
      #ffffff 100%);
  z-index: 10;
}

#project-details .r-treasure-slider .owl-stage {
  display: flex;
  align-items: center;
}

#project-details .r-treasure-slider .owl-nav {
  display: inline-block;
  margin-top: 50px;
  position: relative;
  background-color: #fff;
  padding-right: 9px;
  z-index: 2;
  margin-left: -2px;
}

#project-details .r-treasure-slider .owl-nav button {
  width: 32px;
  height: 32px;
  border: 1px solid #0063aa;
  border-radius: 50%;
  margin-right: 24px;
  transition: 0.2s all;
  position: relative;
}

#project-details .r-treasure-slider .owl-nav button:hover {
  background-color: #0063aa;
}

#project-details .r-treasure-slider .owl-nav button:hover path {
  fill: #fff;
}

#project-details .r-treasure-slider #counter {
  position: absolute;
  right: 7px;
  bottom: -8px;
  font-size: 48px;
  font-weight: 600;
  color: #fff;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #0063aa;
  z-index: 2;
  background: #fff;
  display: flex;
  height: 56px;
  align-items: center;
  justify-content: center;
  padding-left: 30px;
}

#project-details .r-treasure-slider .owl-dots.disabled {
  background-color: #0063aa;
  bottom: 17px;
  content: '';
  height: 1px;
  left: 15px;
  position: absolute;
  width: 92%;
  z-index: 1;
}

#project-details .r-treatsure {
  margin-bottom: -70px;
}

#project-details .r-treatsure>.container {
  max-width: 1140px;
}

#project-details .r-treatsure .col-left h3 {
  font-size: 40px;
  font-weight: 900;
  color: #121212;
  margin-bottom: 12px;
}

#project-details .r-treatsure .col-left p {
  font-size: 14px;
  color: #434343;
  padding-right: 65px;
}

#project-details .r-treatsure .col-right .inner {
  padding-top: 63px;
  margin-left: -4px;
}

#project-details .r-treatsure .col-right .block {
  display: flex;
  margin-bottom: 16px;
}

#project-details .r-treatsure .col-right .block.field {
  margin-top: -10px;
  margin-bottom: 16px;
}

#project-details .r-treatsure .col-right .block .icon {
  margin-right: 16px;
  margin-top: -4px;
}

#project-details .r-treatsure .col-right .block .content h5 {
  margin-bottom: 4px;
  font-size: 18px;
  line-height: 29px;
}

#project-details .r-treatsure .col-right .block .content ul {
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}

#project-details .r-treatsure .col-right .block .content ul li {
  margin-right: 8px;
  margin-bottom: 12px;
}

#project-details .r-treatsure .col-right .block .content ul li.active a,
#project-details .r-treatsure .col-right .block .content ul li:hover a {
  color: #fff;
  background-color: #0063aa;
}

#project-details .r-treatsure .col-right .block .content ul li a {
  font-size: 14px;
  color: #434343;
  background: #e2e2e2;
  border-radius: 55px;
  padding: 5px 24px 3px;
  display: inline-block;
}

#project-details .r-treatsure .col-right .block .content p {
  color: #000;
  margin-bottom: 0;
  margin-top: 0px;
}

@media (max-width: 1199px) {
  .r-treasure-slider .item {
    width: 100% !important;
  }

  #project-details .r-treasure-slider .treasure-carousel {
    padding-right: 0;
  }

  #project-details .r-treasure-slider .owl-stage-outer {
    overflow: hidden;
  }

  #project-details .r-treasure-slider .item-2 a::after {
    opacity: 0;
  }
}

@media (max-width: 767px) {
  #project-details .r-treasure-slider {
    margin-top: 36px;
    margin-bottom: 40px;
  }

  /* #project-details .r-treasure-slider #counter {
    right: 13px;
    bottom: -8px;
  } */
  #project-details .r-treatsure .col-left h3 {
    font-size: 25px;
  }

  #project-details .r-treatsure .col-left p {
    padding-right: 0;
  }

  #project-details .r-treatsure .col-right .block .content h5 {
    font-size: 16px;
  }
}

#offshore .title-left {
  margin-bottom: 58px;
}

#offshore .title-left h3 {
  font-size: 48px;
  font-weight: 900;
  color: #0063aa;
  position: relative;
  margin-bottom: 35px;
  text-transform: uppercase;
}

#offshore .title-left h3::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -11px;
  width: 48px;
  height: 4px;
  background-color: #0063aa;
  z-index: 1;
}

#offshore .title-left .desc {
  font-size: 18px;
  color: #686868;
}

#offshore .title-center {
  margin-bottom: 58px;
  text-align: center;
}

#offshore .title-center h3 {
  font-size: 48px;
  font-weight: 700;
  color: #0063aa;
  position: relative;
  margin-bottom: 29px;
  text-transform: uppercase;
}

#offshore .title-center h3::after {
  content: '';
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -11px;
  width: 48px;
  height: 4px;
  background-color: #0063aa;
  z-index: 1;
}

#offshore .title-center .desc {
  font-size: 20px;
  color: #686868;
}

#offshore .r-big-banner {
  max-width: 100%;
  margin-top: 10px;
  text-align: center;
}

@media screen and (min-width: 200px) and (max-width: 1770px) {
  #offshore .r-big-banner img {
    width: 100%;
  }
}

#offshore .r-page-title {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 265px;
}

#offshore .r-page-title h3 {
  color: #0063aa;
  font-style: normal;
  font-weight: 900;
  font-size: 56px;
  line-height: 66px;
  text-align: center;
  border-radius: 8px 8px 0px 0px;
}

#offshore .r-page-title h5 {
  font-style: normal;
  font-weight: 900;
  font-size: 32px;
  line-height: 38px;
  color: #0063aa;
  text-align: center;
  margin-top: -7px;
}

@media (max-width: 767px) {
  #offshore .r-page-title h5 {
    font-size: 24px;
  }
}

#offshore .r-page-title h5::after {
  background: #fde002;
  width: 61.67px;
  height: 61.67px;
  border-radius: 50%;
  position: absolute;
  content: '';
  left: 80px;
  bottom: -150px;
  transform: translateX(-50%);
}

#offshore .r-page-title h5::before {
  background: #e22928;
  width: 30.84px;
  height: 30.84px;
  border-radius: 50%;
  position: absolute;
  content: '';
  left: 48%;
  bottom: 140px;
  transform: translateX(-50%);
}

@media screen and (min-width: 200px) and (max-width: 1199px) {
  #offshore .r-page-title h5::after {
    display: none;
  }
}

#offshore .r-page-title .banner {
  margin-top: 84px;
  position: relative;
}

@media screen and (min-width: 200px) and (max-width: 1366px) {
  #offshore .r-page-title .banner img {
    width: 1100px;
  }
}

@media screen and (min-width: 200px) and (max-width: 1199px) {
  #offshore .r-page-title .banner img {
    width: 800px;
  }
}

#offshore .r-page-title .content-mb {
  display: none;
}

@media screen and (min-width: 200px) and (max-width: 767px) {
  #offshore .r-page-title .banner img {
    width: 520px;
    max-width: 100%;
    display: none;
  }

  #offshore .r-page-title .content-mb {
    display: block;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #0063aa;
    padding: 0 50px;
    margin-top: -66px;
  }
}

@media screen and (min-width: 200px) and (max-width: 520px) {
  #offshore .r-page-title .banner {
    width: 390px !important;
    max-width: 100%;
  }
}

#offshore .r-page-title .banner::after {
  content: '';
  background: #0063aa;
  border-radius: 50%;
  position: absolute;
  bottom: 125px;
  width: 117px;
  height: 117px;
  left: 760px;
}

@media screen and (min-width: 200px) and (max-width: 1199px) {
  #offshore .r-page-title .banner::after {
    display: none;
  }
}

#offshore .r-page-title .banner::before {
  content: '';
  position: absolute;
  border-radius: 50%;
  width: 27.99px;
  height: 27.99px;
  bottom: 70px;
  left: -340px;
  /* right: 351px; */
  background: #e22928;
}

@media screen and (min-width: 200px) and (max-width: 1199px) {
  #offshore .r-page-title .banner::before {
    display: none;
  }
}

#offshore .r-what {
  margin-top: -200px;
  margin-left: -10px;
}

@media screen and (min-width: 200px) and (max-width: 1199px) {
  #offshore .r-what {
    margin-top: 140px !important;
  }
}

@media screen and (min-width: 200px) and (max-width: 767px) {
  #offshore .r-what {
    margin-top: 90px !important;
  }
}

#offshore .r-what .container-fluid {
  width: 1630px;
  max-width: 100%;
}

#offshore .r-what .box {
  display: flex;
  align-items: center;
}

#offshore .r-what .box .image {
  width: 50%;
  /* width: 555px; */
  margin-right: 121px;
}

#offshore .r-what .box .image>img {
  width: 100%;
}

#offshore .r-what .box .content {
  width: calc(100% - 676px);
}

#offshore .r-what .box .content h3 {
  font-weight: 700;
  font-size: 56px;
  line-height: 90px;
  color: #0063aa;
  margin-bottom: 55px;
  margin-top: -20px;
}

#offshore .r-what .box .content p {
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
  color: #0063aa;
  width: 699px;
  max-width: 100%;
  margin-bottom: -11px;
}

#offshore .r-bring {
  margin-top: 127px;
}

#offshore .r-bring .container-fluid {
  width: 1771px;
  max-width: 100%;
}

#offshore .r-bring .box {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

#offshore .r-bring .box .content {
  flex: 0 0 44.5%;
  max-width: 44.5%;
}

#offshore .r-bring .box .content h3 {
  font-weight: 700;
  font-size: 56px;
  line-height: 90px;
  color: #0063aa;
  margin-bottom: 55px;
  margin-top: -20px;
}

#offshore .r-bring .box .content p {
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
  color: #0063aa;
  max-width: 100%;
  margin-bottom: -8px;
  padding-right: 11px;
}

#offshore .r-service {
  margin-top: 42px;
  padding-top: 81px;
  padding-bottom: 52px;
  position: relative;
  text-align: center;
  color: #fff;
  margin-bottom: 50px;
}

#offshore .r-service .inner {
  position: absolute;
  height: 100%;
  top: 29px;
  left: -1px;
  right: 0;
  background: url(/assets/images//offshore/service-bg.png) no-repeat left center;
  background-size: cover;
  z-index: -1;
}

@media (max-width: 575px) {
  #offshore .r-service .inner {
    background: url(/assets/images//offshore/service-bg-mb.png) no-repeat left center;
    background-size: cover;
  }
}

#offshore .r-service h3.title {
  /* width: 1366px; */
  width: 80%;
  left: 50%;
  transform: translateX(-50%);
  /* top: 108px; */
  position: relative;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 80px;
  line-height: 100px;
  /* or 125% */

  text-align: center;

  color: #ffffff;
}

@media screen and (min-width: 200px) and (max-width: 1770px) {
  #offshore .r-service h3.title {
    font-size: 70px;
  }
}

@media screen and (min-width: 200px) and (max-width: 1440px) {
  #offshore .r-service h3.title {
    font-size: 50px;
    position: relative;
  }
}

@media screen and (min-width: 200px) and (max-width: 1199px) {
  #offshore .r-service h3.title {
    position: relative;
  }
}

@media screen and (min-width: 200px) and (max-width: 390px) {
  #offshore .r-service h3.title br {
    display: none;
  }
}

@media screen and (min-width: 200px) and (max-width: 1199px) {
  #offshore .r-service .box {
    margin-top: 10px;
  }
}

#offshore .r-service .row>div:nth-child(1) h5 {
  margin-top: 25px !important;
  margin: 0 96px;
}

#offshore .r-service .box h5 {
  margin: 0 55px;
  font-weight: 700;
  font-size: 50px;
  line-height: 66px;
  margin-top: 25px;
}

#offshore .r-archivement {
  margin-top: 128px;
  position: relative;
}

#offshore .r-archivement::after {
  content: '';
  background: url('/assets/images/offshore/img-footer-inner.png') no-repeat;
  position: absolute;
  bottom: -752px;
  width: 100%;
  height: 100%;
  z-index: 1;
}

@media screen and (min-width: 200px) and (max-width: 1770px) {
  #offshore .r-archivement::after {
    display: none;
  }
}

@media screen and (min-width: 200px) and (max-width: 1199px) {
  #offshore .r-archivement::after {
    display: none;
  }
}

#offshore .r-archivement .title {
  text-align: center;
  color: #0063aa;
  margin-bottom: 78px;
  font-style: normal;
  font-weight: 900;
  font-size: 48px;
  line-height: 56px;
  position: relative;
}

#offshore .r-archivement .title::after {
  content: '';
  position: absolute;
  width: 48px;
  height: 4px;
  border-radius: 8px;
  background: #0063aa;
  bottom: -12px;
  left: 50%;
  transform: translateX(-50%);
}

#offshore .r-archivement .row {
  margin-left: -17px;
  margin-right: -21px;
}

#offshore .r-archivement .row>div {
  padding-left: 35px;
  padding-right: 6px;
}

#offshore .r-archivement .box {
  color: #0063aa;
  position: relative;
  padding: 26px 0 10px 32px;
  border-radius: 7px;
}

#offshore .r-archivement .box.box-1 {
  background-color: #fde002;
}

#offshore .r-archivement .box.box-1 img {
  width: 86px;
  height: 63px;
  margin-top: 17px;
  margin-bottom: 26px;
}

@media (max-width: 1199px) {
  #offshore .r-archivement .box.box-1 img {
    width: 50px;
    height: auto;
  }
}

#offshore .r-archivement .box.box-2 {
  background-color: #0063aa;
}

#offshore .r-archivement .box.box-2 img {
  width: 65px;
  height: 81px;
  margin-bottom: 26px;
}

@media (max-width: 1199px) {
  #offshore .r-archivement .box.box-2 img {
    width: 44px;
    height: auto;
  }
}

#offshore .r-archivement .box.box-3 {
  background-color: #eb2725;
}

#offshore .r-archivement .box.box-3 img {
  width: 83px;
  height: 83px;
  margin-bottom: 25px;
}

@media (max-width: 1199px) {
  #offshore .r-archivement .box.box-3 img {
    width: 50px;
    height: auto;
  }
}

#offshore .r-archivement .box.box-4 {
  background-color: #00be90;
}

#offshore .r-archivement .box.box-4 img {
  width: 80px;
  height: 83px;
  margin-bottom: 24px;
}

@media (max-width: 1199px) {
  #offshore .r-archivement .box.box-4 img {
    width: 47px;
    height: auto;
  }
}

#offshore .r-archivement .box::before {
  content: '';
  width: 11px;
  height: 159px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

#offshore .r-archivement .box h2 {
  font-weight: 900;
  font-size: 42px;
  line-height: 49px;
  margin-bottom: 3px;
}

#offshore .r-archivement .box p {
  font-weight: 500;
  font-size: 26px;
  line-height: 33px;
}

@media screen and (min-width: 200px) and (max-width: 1770px) {
  #offshore .r-archivement .inner img {
    width: 100%;
    position: relative;
    z-index: -1;
  }
}

#offshore .r-archivement .inner .label-dot {
  position: absolute;
  right: -390px;
}

@media screen and (min-width: 200px) and (max-width: 1770px) {
  #offshore .r-archivement .inner .label-dot {
    display: none;
  }
}

#offshore .r-archivement .inner .content {
  text-align: center;
  margin-top: -610px;
}

@media screen and (min-width: 200px) and (max-width: 1770px) {
  #offshore .r-archivement .inner .content {
    margin-top: -310px;
  }
}

@media screen and (min-width: 200px) and (max-width: 767px) {
  #offshore .r-archivement .inner .content {
    margin-top: -230px;
  }
}

@media screen and (min-width: 200px) and (max-width: 520px) {
  #offshore .r-archivement .inner .content {
    margin-top: -189px;
  }
}

#offshore .r-archivement .inner .content .text-content {
  color: #0063aa;
}

#offshore .r-archivement .inner .content .text-content h3 {
  font-size: 56px;
  line-height: 66px;
  margin-bottom: 0;
  font-weight: 900;
}

@media screen and (min-width: 200px) and (max-width: 767px) {
  #offshore .r-archivement .inner .content .text-content h3 {
    font-size: 20px;
  }
}

#offshore .r-archivement .inner .content .text-content h5 {
  font-size: 32px;
  line-height: 38px;
  margin-bottom: 15px;
  font-weight: 900;
}

@media screen and (min-width: 200px) and (max-width: 767px) {
  #offshore .r-archivement .inner .content .text-content h5 {
    font-size: 16px;
  }
}

#offshore .r-archivement .inner .content .text-content p {
  font-size: 24px;
  line-height: 40px;
  margin-bottom: 36px;
  font-weight: 900;
}

@media screen and (min-width: 200px) and (max-width: 767px) {
  #offshore .r-archivement .inner .content .text-content p {
    font-size: 14px;
  }
}

#offshore .r-archivement .inner .content img {
  /* margin-left: 37px; */
  position: relative;
  z-index: 1;
  background-color: #fff;
}

@media screen and (min-width: 200px) and (max-width: 1770px) {
  #offshore .r-archivement .inner .content img {
    width: 100%;
    background: none;
    z-index: -1;
  }
}

@media screen and (min-width: 200px) and (max-width: 1199px) {
  #offshore .r-archivement .inner .content img {
    display: none;
  }
}

#offshore .r-reason {
  color: #fff;
  padding: 283px 0 84px;
  background: url('/assets/images//home/reason-bg.png') no-repeat center;
  background-size: cover;
  margin-top: -105px;
}

@media screen and (min-width: 200px) and (max-width: 1199px) {
  #offshore .r-reason {
    padding: 128px 0 84px;
  }
}

#offshore .r-reason .label {
  position: absolute;
  right: calc(100% + 91px);
  top: calc(50% + 31px);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

@media screen and (min-width: 200px) and (max-width: 1770px) {
  #offshore .r-reason .label {
    right: calc(100% + 31px);
  }
}

@media screen and (min-width: 200px) and (max-width: 1620px) {
  #offshore .r-reason .label {
    right: calc(100% - 24px);
  }
}

@media screen and (min-width: 200px) and (max-width: 1520px) {
  #offshore .r-reason .label {
    display: none;
  }
}

#offshore .r-reason .title {
  font-weight: 900;
  font-size: 64px;
  line-height: 75px;
  text-align: center;
  margin-bottom: 86px;
  margin-top: 30px;
  margin-left: -10px;
}

@media screen and (min-width: 200px) and (max-width: 1199px) {
  #offshore .r-reason .title {
    font-size: 50px;
  }
}

@media screen and (min-width: 200px) and (max-width: 767px) {
  #offshore .r-reason .title {
    font-size: 18px;
  }
}

#offshore .r-reason .box {
  display: flex;
  align-items: center;
}

#offshore .r-reason .box .group-reason {
  margin-right: 71px;
}

@media screen and (min-width: 200px) and (max-width: 767px) {
  #offshore .r-reason .box .group-reason {
    margin-right: 15px;
    margin-bottom: 23px;
  }
}

#offshore .r-reason .box .group-reason img {
  width: 377px;
  margin-top: -14px;
}

@media screen and (min-width: 200px) and (max-width: 1199px) {
  #offshore .r-reason .box .group-reason img {
    display: none;
  }

  #offshore .r-reason .box .group-reason img {
    max-width: 60%;
    margin-left: auto;
    margin-right: auto;
    margin-top: -14px;
  }
}

#offshore .r-reason .box ul li {
  display: flex;
  align-items: center;
}

#offshore .r-reason .box ul li+li {
  margin-top: 62px;
}

#offshore .r-reason .box ul li .icon {
  margin-right: 32px;
  min-width: 95px;
}

@media screen and (min-width: 200px) and (max-width: 767px) {
  #offshore .r-reason .box ul li .icon {
    margin-right: 0;
  }
}

@media screen and (min-width: 200px) and (max-width: 767px) {
  #offshore .r-reason .box ul li .icon img {
    width: 80%;
  }
}

#offshore .r-reason .box ul li p {
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 900;
  font-size: 38px;
  line-height: 58px;
  margin-bottom: 0;
}

@media screen and (min-width: 200px) and (max-width: 1199px) {
  #offshore .r-reason .box ul li p {
    font-size: 25px;
  }
}

@media screen and (min-width: 200px) and (max-width: 767px) {
  #offshore .r-reason .box ul li p {
    font-size: 14px;
    line-height: 24px;
  }
}

#offshore .r-cooperate {
  padding: 160px 0 86px 0;
}

#offshore .r-cooperate>.container {
  margin-top: 50px;
}

#offshore .r-cooperate .title-left {
  margin-bottom: 76px;
}

#offshore .r-cooperate .step.step-1 {
  padding-bottom: 133px;
}

#offshore .r-cooperate .step.step-1 .inner .number {
  margin-left: 13px;
  margin-top: 59px;
}

#offshore .r-cooperate .step.step-1 .inner .number::after {
  content: '';
  width: 1px;
  height: 440px;
  background-color: #0063aa;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}

#offshore .r-cooperate .step.step-1 .inner .content {
  width: 370px;
  max-width: 100%;
  margin-top: 47px;
  margin-left: 10px;
}

#offshore .r-cooperate .step.step-2 .inner {
  justify-content: flex-start;
}

#offshore .r-cooperate .step.step-2 .inner .number {
  margin-left: 13px;
}

#offshore .r-cooperate .step.step-2 .inner .number::after {
  content: '';
  width: 1px;
  height: 290px;
  background-image: linear-gradient(to bottom,
      rgb(0, 99, 170),
      rgba(0, 99, 170, 0));
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}

#offshore .r-cooperate .step.step-2 .inner .content {
  margin-top: -8px;
  margin-left: 39px;
  margin-bottom: 39px;
}

#offshore .r-cooperate .step.step-2 .inner .content p {
  width: 370px;
  max-width: 100%;
}

#offshore .r-cooperate .step.step-2 .inner .image {
  margin-left: 93px;
}

#offshore .r-cooperate .step.step-2 .inner .image .box {
  max-width: 500px;
  max-height: 408px;
  background: #ededed;
  border-radius: 16px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#offshore .r-cooperate .step.step-2 .inner .image .box .top {
  padding: 23px 32px 50px 23px;
  margin-bottom: -7px;
}

#offshore .r-cooperate .step.step-2 .inner .image .box .top h5 {
  font-size: 24px;
  font-weight: 700;
  color: #494949;
  margin-bottom: 6px;
}

#offshore .r-cooperate .step.step-2 .inner .image .box .top p {
  font-size: 15px;
  color: #494949;
}

#offshore .r-cooperate .step .inner {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

#offshore .r-cooperate .step .inner .number {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background: #0063aa;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 24px;
  font-weight: 900;
  position: relative;
  z-index: 2;
}

#offshore .r-cooperate .step .inner .content h3 {
  font-size: 48px;
  font-weight: 700;
  color: #121212;
  margin-bottom: 16px;
}

#offshore .r-cooperate .step .inner .content p {
  font-size: 18px;
  line-height: 32px;
  color: #434343;
}

#offshore .r-services {
  padding: 158px 0 118px;
  position: relative;
  background: #fdfdfd;
}

#offshore .r-services::before {
  content: url('/assets/images/offshore/label-services.png');
  position: absolute;
  left: 0px;
  top: 24px;
  z-index: 1;
}

#offshore .r-services .title-left h3 {
  font-weight: 900;
  font-size: 48px;
  line-height: 56px;
}

@media screen and (min-width: 200px) and (max-width: 767px) {
  #offshore .r-services .title-left h3 {
    font-size: 18px;
  }
}

#offshore .r-services .row {
  margin: 0 -11px;
}

#offshore .r-services .row>div {
  padding: 0 11px;
  margin-bottom: 46px;
}

#offshore .r-services .box {
  text-decoration: none;
  display: flex;
  align-items: center;
  background: #ffffff;
  border: 1px solid transparent;
  transition: 0.2s all;
}

#offshore .r-services .box:hover {
  border-color: #d9d9d9;
  box-shadow: 0px 4px 17px 4px rgba(0, 0, 0, 0.1);
  border-radius: 9px;
}

#offshore .r-services .box:hover .icon {
  border: none;
}

#offshore .r-services .box:hover .arrow {
  opacity: 1;
}

#offshore .r-services .box .icon {
  margin-right: 15px;
  width: 94px;
  height: 94px;
  border: 1px solid #d9d9d9;
  border-radius: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#offshore .r-services .box h3 {
  font-size: 18px;
  font-weight: 700;
  color: #000;
  margin-bottom: 0;
  padding-top: 2px;
  /* width: 200px; */
  max-width: 100%;
}

#offshore .r-services .box .arrow {
  margin-left: auto;
  margin-right: 12px;
  opacity: 0;
  transition: 0.2s all;
}

#offshore .r-process {
  padding-bottom: 90px;
  background: #0063aa;
  /* margin-bottom: 150px; */
}

/* @media (max-width: 1199px) {
  #offshore .r-process {
    margin-bottom: 100px;
  }
} */

#offshore .r-process .col-left {
  padding-top: 91px;
  position: relative;
  height: calc(100% + 91px);
}

@media screen and (min-width: 200px) and (max-width: 767px) {
  #offshore .r-process .col-left {
    padding-top: 50px;
  }
}

#offshore .r-process .col-left h3 {
  padding-right: 15px;
  text-align: end;
  font-weight: 700;
  font-size: 40px;
  line-height: 47px;
  text-transform: uppercase;
  color: #ffffff;
  position: absolute;
  right: -20%;
  text-align: start;
}

@media screen and (min-width: 200px) and (max-width: 1199px) {
  #offshore .r-process .col-left h3 {
    text-align: start;
    font-size: 30px;
  }
}

@media screen and (min-width: 200px) and (max-width: 767px) {
  #offshore .r-process .col-left h3 {
    text-align: start;
    font-size: 18px;
  }
}

#offshore .r-process .col-left h3::after {
  content: '';
  width: 48px;
  height: 4px;
  background: #ffffff;
  position: absolute;
  bottom: -19px;
  left: 0;
  border-radius: 8px;
}

@media screen and (min-width: 200px) and (max-width: 767px) {
  #offshore .r-process .col-left h3::after {
    left: 0;
    width: 35px;
    bottom: 2px;
  }
}

#offshore .r-process .col-left p {
  text-align: end;
  padding-top: 25px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
}

@media (max-width: 1199px) {
  #offshore .r-process .col-left p {
    text-align: start;
    font-size: 14px;
    line-height: 24px;
  }
}

#offshore .r-process .col-left .subtract-2 {
  position: absolute;
  top: 0;
  left: -215px;
}

@media screen and (min-width: 200px) and (max-width: 991px) {
  #offshore .r-process .col-left .subtract-2 {
    display: none;
  }
}

#offshore .r-process .col-left .subtract {
  position: absolute;
  bottom: 0;
  left: -310px;
}

@media screen and (min-width: 200px) and (max-width: 991px) {
  #offshore .r-process .col-left .subtract {
    display: none;
  }
}

#offshore .r-process .col-right {
  /* padding-left: 45px; */
  position: relative;
  padding-bottom: 1px;
  margin-bottom: -28px;
}

@media screen and (min-width: 200px) and (max-width: 767px) {
  #offshore .r-process .col-right {
    /* padding-left: 15%; */
  }
}

#offshore .r-process .col-right ul {
  padding-top: 80px;
}

#offshore .r-process .col-right ul::before {
  content: '';
  width: 1px;
  height: calc(100% + 90px);
  position: absolute;
  left: 76px;
  z-index: 1;
  top: 0;
  background: #fff;
}

@media screen and (min-width: 200px) and (max-width: 767px) {
  #offshore .r-process .col-right ul::before {
    left: 48px;
  }
}

#offshore .r-process .col-right ul li {
  position: relative;
  color: #ffffff;
  margin-bottom: calc(69px + 1rem);
  display: flex;
  transition: 0.5s;
  cursor: pointer;
}

#offshore .r-process .col-right ul li:last-of-type {
  margin-bottom: 0;
}

#offshore .r-process .col-right ul li::before {
  content: '';
  background: rgba(255, 255, 255, 0.1);
  border-radius: 1400px 1991.68px 1991.68px 1400px;
  opacity: 0;
  width: 601px;
  height: 125px;
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
}

@media screen and (min-width: 200px) and (max-width: 1199px) {
  #offshore .r-process .col-right ul li::before {
    width: 550px;
  }
}

@media screen and (min-width: 200px) and (max-width: 991px) {
  #offshore .r-process .col-right ul li::before {
    width: calc(100% - 20px);
    height: 90px;
  }
}

@media screen and (min-width: 200px) and (max-width: 767px) {
  #offshore .r-process .col-right ul li::before {
    width: calc(100% + 24px);
  }
}

@media (min-width: 768px) {
  #offshore .r-process .col-right ul li:hover::before {
    opacity: 1;
  }

  #offshore .r-process .col-right ul li:hover .icon {
    transform: scale(1.9);
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  #offshore .r-process .col-right ul li:hover .icon {
    transform: scale(1.3);
  }
}

@media (min-width: 768px) {
  #offshore .r-process .col-right ul li:hover .content {
    padding-left: 50px;
  }

  #offshore .r-process .col-right ul li:hover .content p {
    padding-right: 52px;
    font-size: 87px;
    transition: 0.4s;
    margin-top: 4px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1199px) {
  #offshore .r-process .col-right ul li:hover .content p {
    font-size: 70px;
  }
}

#offshore .r-process .col-right ul li:hover .content .text strong {
  transition: 0.5s;
  font-size: 32px;
  margin-bottom: 10px;
}

/* @media screen and (min-width: 200px) and (max-width: 767px) {
  #offshore .r-process .col-right ul li:hover .content .text strong {
    font-size: 16px;
  }
} */

@media (min-width: 768px) {
  #offshore .r-process .col-right ul li:hover .content .text span {
    transition: 0.5s;
    bottom: -6px;
    font-size: 16px;
    line-height: 19px;
    max-width: 320px;
  }
}

/* @media screen and (min-width: 200px) and (max-width: 767px) {
  #offshore .r-process .col-right ul li:hover .content .text span {
    font-size: 8px;
  }
} */

#offshore .r-process .col-right ul li::after {
  content: '';
  width: 3px;
  height: 125px;
  background-color: #fff;
  position: absolute;
  left: -63px;
  opacity: 0;
  transition: 0.5s;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 5px;
}

@media screen and (min-width: 200px) and (max-width: 991px) {
  #offshore .r-process .col-right ul li::after {
    height: 70px;
  }
}

#offshore .r-process .col-right ul li:hover::after {
  opacity: 1;
}

#offshore .r-process .col-right ul li .icon {
  cursor: pointer;
  position: relative;
  z-index: 2;
  transition: 0.5s;
  display: flex;
}

#offshore .r-process .col-right ul li .content {
  padding-left: 30px;
  display: flex;
  transition: 0.5s;
  margin-top: 1px;
}

@media screen and (min-width: 200px) and (max-width: 767px) {
  #offshore .r-process .col-right ul li .content {
    padding-left: 17px;
  }
}

#offshore .r-process .col-right ul li .content p {
  font-weight: 700;
  font-size: 48px;
  line-height: 56px;
  padding-right: 39px;
  position: relative;
  transition: 0.5s;
}

@media screen and (min-width: 200px) and (max-width: 767px) {
  #offshore .r-process .col-right ul li .content p {
    font-size: 35px;
    padding-right: 20px;
  }

  #offshore .r-process .col-right ul li .content {
    padding-left: 10px;
  }
}

#offshore .r-process .col-right ul li .content p::before {
  content: '';
  width: 3px;
  position: absolute;
  right: 11px;
  top: 50%;
  transform: translateY(-50%);
  height: 65px;
  border-radius: 7px;
  background: #fff;
}

@media screen and (min-width: 200px) and (max-width: 767px) {
  #offshore .r-process .col-right ul li .content p::before {
    height: 60px;
  }
}

#offshore .r-process .col-right ul li .content .text strong {
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  transition: 0.5s;
}

@media screen and (min-width: 200px) and (max-width: 767px) {
  #offshore .r-process .col-right ul li .content .text strong {
    font-size: 16px !important;
  }
}

#offshore .r-process .col-right ul li .content .text span {
  position: absolute;
  margin-top: 5px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  transition: 0.5s;
  max-width: 300px;
}

@media screen and (min-width: 200px) and (max-width: 767px) {
  #offshore .r-process .col-right ul li .content .text span {
    font-size: 11px;
  }
}

@media (max-width: 1800px) {
  #offshore .r-step::before {
    display: none;
  }

  #offshore .r-service::before {
    display: none;
  }

  #offshore .r-services::before {
    display: none;
  }

  #offshore .r-service .inner {
    width: 100%;
    left: 0;
    background-position: center;
  }
}

@media (max-width: 1366px) {
  #offshore .r-big-banner {
    width: 100%;
  }
}

#offshore .r-archivement-img-mb {
  display: none;
}

@media (max-width: 1199px) {
  #offshore .r-archivement-img-mb {
    display: block;
    margin-top: -78px;
  }

  #offshore .r-reason {
    margin-top: -80px;
  }

  #offshore .r-archivement .box::before {
    width: 2px;
    height: 75px;
  }

  #offshore .r-archivement .box {
    padding-left: 20px;
  }

  #offshore .r-archivement .box p {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
  }

  #offshore .r-archivement .box h2 {
    font-size: 30px;
    line-height: 40px;
  }

  #offshore .r-archivement {
    margin-top: 80px;
  }

  #offshore .r-archivement .title {
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 30px;
  }

  #offshore .r-service .box h5 {
    font-size: 25px;
    line-height: 35px;
  }

  #offshore .r-service {
    margin-top: 40px;
  }

  #offshore .r-service h3.title {
    font-size: 30px;
    line-height: 40px;
  }

  #offshore .r-big-banner {
    margin-top: 0;
  }

  #offshore .r-bring {
    margin-top: 70px;
  }

  #offshore .r-bring .box .image {
    margin-top: 40px;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  #offshore .r-bring .box .content p {
    font-size: 14px;
    line-height: 24px;
    max-width: 100%;
    margin-bottom: 0;
    padding-right: 0;
  }

  #offshore .r-bring .box .content h3 {
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 20px;
    margin-top: 0;
  }

  #offshore .r-bring .box {
    flex-direction: column;
  }

  #offshore .r-bring .box .content {
    flex: 0 0 100%;
    max-width: 100%;
    text-align: center;
  }

  #offshore .r-what {
    margin-top: 30px;
  }

  #offshore .r-what .box .content p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 0;
    width: 100%;
  }

  #offshore .r-what .box .content h3 {
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 20px;
    margin-top: 0;
  }

  #offshore .r-what .box .content {
    width: 100%;
    margin-top: 50px;
    text-align: center;
  }

  #offshore .r-what .box .image {
    width: 100%;
    margin-right: 0;
    display: flex;
    justify-content: center;
  }

  #offshore .r-what .box {
    flex-direction: column;
    padding-left: 15px;
  }

  #offshore .r-page-title {
    margin-top: -90px;
  }

  #offshore .r-page-title h3 {
    padding: 25px;
    font-size: 30px;
    line-height: 40px;
  }

  #offshore .r-faq .title-center h3::after {
    width: 230px;
  }

  #offshore .r-faq .title-center h3 {
    font-size: 30px;
    line-height: 40px;
  }

  #offshore .r-cooperate .step.step-1 .inner {
    justify-content: flex-start;
  }

  #offshore .r-cooperate .step.step-1 .inner .content {
    margin-left: 39px;
    margin-bottom: 39px;
  }

  #offshore .r-cooperate .step.step-1 .inner .image {
    margin-left: 93px;
  }

  #offshore .r-cooperate .step.step-1 .inner .number::after,
  #offshore .r-cooperate .step.step-2 .inner .number::after {
    display: none;
  }
}

@media (max-width: 991px) {

  #offshore .title-left h3,
  #offshore .title-center h3 {
    font-size: 30px;
  }

  #offshore .r-big-banner .box .inner h3 {
    font-size: 20px;
    line-height: 30px;
    padding-right: 0;
  }

  #offshore .r-service .box .icon svg {
    width: 30px;
  }

  #offshore .r-services .box .icon svg {
    width: 30px;
  }

  #offshore .r-technology .box h3 {
    font-size: 20px;
  }

  #offshore .r-technology .box ul li {
    flex: 0 0 25%;
    max-width: 25%;
  }

  #offshore .r-cooperate .step .inner .content h3 {
    font-size: 30px;
  }

  #offshore .r-reason h1 {
    font-size: 40px;
  }

  #offshore .r-reason .box h3 {
    font-size: 20px;
  }

  #offshore .r-reason .box .number {
    font-size: 50px;
  }

  #offshore .r-reason .box {
    margin-bottom: 50px;
  }

  #offshore .r-step .step-carousel .item .top .text,
  #offshore .r-step .step-carousel .item .top .number {
    font-size: 30px;
  }

  #offshore .r-step {
    padding-bottom: 70px;
  }
}

@media (max-width: 767px) {
  #offshore .r-what .box .content {
    display: none;
  }

  #offshore .r-archivement-img-mb {
    display: block;
    margin-top: -58px;
  }

  #offshore .r-contact {
    margin-top: 70px;
  }

  #offshore .r-reason .title {
    margin-bottom: 0px !important;
  }

  #offshore .r-faq .title-center h3::after {
    width: 23px;
    bottom: -8px;
    height: 1px;
  }

  #offshore .r-faq .title-center h3 {
    font-size: 18px;
    line-height: 28px;
  }

  #offshore .r-reason {
    margin-top: -56px;
  }

  #offshore .r-archivement {
    margin-top: 50px;
    padding: 0 15px 0 11px;
  }

  #offshore .r-archivement .row>div {
    margin-bottom: 20px;
    padding-left: 6px;
  }

  #offshore .r-archivement .title {
    font-size: 18px;
    line-height: 28px;
  }

  #offshore .r-service .box h5 {
    font-size: 14px;
    line-height: 24px;
  }

  #offshore .r-service .box img {
    width: 70px;
  }

  #offshore .r-service h3.title {
    font-size: 18px;
    line-height: 28px;
  }

  #offshore .r-services {
    padding: 50px 0 45px;
  }

  #offshore .r-services .row>div {
    margin-bottom: 15px;
  }

  #offshore .r-services .box h3 {
    font-size: 14px;
  }

  #offshore .r-bring .box .image img {
    width: 100%;
    object-fit: cover;
  }

  #offshore .r-bring .box .content h3 {
    font-size: 18px;
    line-height: 28px;
  }

  #offshore .r-what .box .content h3 {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 20px;
    margin-top: 0;
  }

  #offshore .r-page-title {
    margin-top: -48px;
  }

  #offshore .r-page-title h3 {
    padding: 10px;
    font-size: 32px;
    line-height: 28px;
  }

  #offshore .r-what .box .image img {
    width: 100%;
    object-fit: cover;
  }

  #offshore .r-faq .title-center {
    margin-bottom: 30px;
  }

  #offshore .r-faq .card .card-body {
    font-size: 14px;
    line-height: 24px;
  }

  #offshore .r-faq .card .card-body {
    padding-right: 0;
  }

  #offshore .title-left h3,
  #offshore .title-center h3 {
    font-size: 18px;
  }

  #offshore .title-left .desc,
  #offshore .title-center .desc {
    font-size: 14px;
  }

  #offshore .r-service {
    padding: 50px 0 25px;
  }

  #offshore .r-service .row>div {
    margin-bottom: 15px;
  }

  #offshore .r-service .box h3 {
    font-size: 14px;
  }

  #offshore .r-technology {
    padding: 30px 0;
  }

  #offshore .r-cooperate {
    padding: 50px 0 0px;
  }

  #offshore .r-cooperate .title-left {
    margin-bottom: 20px;
  }

  #offshore .r-cooperate .step .inner .number {
    font-size: 16px;
    width: 30px;
    height: 30px;
  }

  #offshore .r-cooperate .step.step-1 .inner .number {
    margin-left: 0;
    margin-top: 0;
  }

  #offshore .r-cooperate .step.step-1 .inner .content {
    margin-bottom: 0;
    margin-top: 20px;
    margin-left: 0px;
  }

  #offshore .r-cooperate .step .inner .content p {
    font-size: 14px;
    line-height: 24px;
  }

  #offshore .r-cooperate .step .inner .content h3 {
    font-size: 18px;
  }

  #offshore .r-cooperate .step.step-1 .inner .image {
    margin-left: 0;
  }

  #offshore .r-cooperate .step.step-1 .inner .image img {
    display: block;
  }

  #offshore .r-cooperate .step.step-1 {
    padding-bottom: 40px;
  }

  #offshore .r-cooperate .step.step-2 .inner .number {
    margin-left: 0;
  }

  #offshore .r-cooperate .step.step-2 .inner .content {
    margin-top: 20px;
    margin-left: 0px;
    margin-bottom: 0;
  }

  #offshore .r-cooperate .step.step-2 .inner .image {
    margin-left: 0;
  }

  #offshore .r-cooperate .step.step-2 .inner .image .box .top h5 {
    font-size: 16px;
  }

  #offshore .r-cooperate .step.step-2 .inner .image .box .top p {
    font-size: 14px;
  }

  #offshore .r-cooperate .step.step-2 .inner .image .col-12 {
    margin-bottom: 15px;
  }

  #offshore .r-reason .number-1 {
    order: 1;
  }

  #offshore .r-reason .number-2 {
    order: 2;
  }

  #offshore .r-reason .number-3 {
    order: 3;
  }

  #offshore .r-reason .number-4 {
    order: 4;
  }

  #offshore .r-reason .number-5 {
    order: 5;
  }

  #offshore .r-reason .number-6 {
    order: 6;
  }

  #offshore .r-reason h1 {
    font-size: 20px;
    margin-bottom: 30px;
  }

  #offshore .r-reason .box h3 {
    font-size: 14px;
    line-height: 24px;
  }

  #offshore .r-reason .box .number {
    font-size: 30px;
    margin-right: 10px;
  }

  #offshore .r-reason .box {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
  }

  #offshore .r-reason .box .group-reason img {
    /* display: block !important; */
  }

  #offshore .r-reason .title {
    margin-top: 30px !important;
  }

  #offshore .r-reason {
    padding: 60px 0 40px;
  }

  #offshore .r-step .step-carousel {
    padding-right: 0;
  }

  #offshore .r-step {
    padding: 50px 0 227px;
  }

  #offshore .r-step .title-center {
    margin-bottom: 40px;
  }

  #offshore .r-step .title-center .desc {
    font-size: 14px;
  }

  #offshore .r-step .step-carousel .item h3 {
    font-size: 18px;
    line-height: 28px;
  }

  #offshore .r-step {
    padding-bottom: 40px;
  }

  #offshore .r-step .step-carousel .item .top {
    margin-bottom: 15px;
  }

  #offshore .r-step .step-carousel .item .top .text,
  #offshore .r-step .step-carousel .item .top .number {
    font-size: 18px;
  }
}

#project .row {
  margin-left: -15px;
  margin-right: -15px;
}

#project .row>div {
  /* padding-left: 30px;
    padding-right: 30px; */
}

#project .r-big-banner {
  max-width: 100%;
  margin-top: 100px;
  position: relative;
  background: url('/assets/images/project/big-banner_sub.png') no-repeat center;
  background-size: cover;
}

@media screen and (min-width: 200px) and (max-width: 1199px) {
  #project .r-big-banner {
    margin-top: 100px;
  }
}

#project .r-big-banner .r-page-title {
  max-height: 525.27px;
  text-align: center;
  padding: 195px 0 195px;
}

#project .r-big-banner .r-page-title>.container {
  text-align: center;
}

@media screen and (min-width: 200px) and (max-width: 1199px) {
  #project .r-big-banner .r-page-title {
    top: 20%;
    padding: 130px 0 180px;
  }
}

@media screen and (min-width: 200px) and (max-width: 767px) {
  #project .r-big-banner .r-page-title {
    padding: 50px 0;
  }
}

#project .r-big-banner .r-page-title h3 {
  font-weight: 900;
  font-size: 56px;
  line-height: 58px;
  text-align: center;
  color: #fdfdfd;
  margin-bottom: 35px;
}

@media screen and (min-width: 200px) and (max-width: 1199px) {
  #project .r-big-banner .r-page-title h3 {
    font-size: 40px;
    margin-bottom: 0;
  }
}

@media screen and (min-width: 200px) and (max-width: 767px) {
  #project .r-big-banner .r-page-title h3 {
    font-size: 32px;
  }
}

#project .r-big-banner .r-page-title p {
  margin: auto;
  width: 670px;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #fdfdfd;
  text-align: center;
}

@media screen and (min-width: 200px) and (max-width: 1199px) {
  #project .r-big-banner .r-page-title p {
    font-size: 18px;
    width: auto;
  }
}

@media screen and (min-width: 200px) and (max-width: 767px) {
  #project .r-big-banner .r-page-title p {
    font-size: 14px;
    line-height: 24px;
  }
}

#project .r-top {
  margin-top: 80px;
}

#project .r-top .search {
  position: relative;
  text-align: right;
}

#project .r-top .search input::placeholder {
  color: #a7a7a7;
  font-weight: 700;
}

#project .r-top .search input {
  background: #ffffff;
  border: 1px solid #e2e2e2;
  border-radius: 29px;
  width: 445px;
  height: 56px;
  padding: 0 23px;
  max-width: 100%;
  font-size: 16px;
  color: #434343;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

#project .r-top .search input:focus {
  outline: none;
}

#project .r-top .search input::placeholder {
  color: #a7a7a7;
}

#project .r-top .search button {
  position: absolute;
  right: 0;
  top: 0;
  background: transparent;
  border: none;
  width: 49px;
  height: 56px;
  z-index: 1;
}

#project .r-top .search button:hover path {
  stroke: #0063aa;
}

#project .r-tag {
  margin-top: 51px;
}

#project .r-tag h3 {
  font-size: 24px;
  font-weight: 400;
  color: #121212;
  margin-bottom: 16px;
}

#project .r-tag ul {
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  padding-right: 74px;
}

#project .r-tag ul li {
  margin-right: 8px;
  margin-bottom: 8px;
}

#project .r-tag ul li.active a,
#project .r-tag ul li:hover a {
  color: #fff;
  background-color: #0063aa;
  cursor: pointer;
}

#project .r-tag ul li a {
  font-size: 14px;
  color: #434343;
  background: #e2e2e2;
  border-radius: 55px;
  padding: 5px 24px;
  display: inline-block;
  font-weight: 700;
}

#project .r-project-list {
  margin-top: 40px;
  margin-bottom: 0px;
}

#project .r-project-list .col-12 {
  margin-bottom: 30px;
}

#project .r-project-list .image {
  max-width: 540px;
  border-radius: 18px;
  display: block;
  overflow: hidden;
}

#project .r-project-list .image:hover img {
  transform: scale(1.1);
}

#project .r-project-list .image img {
  width: 100%;
  border-radius: 18px;
  transition: 0.4s all;
  height: 350px;
  object-fit: cover;
}

@media (max-width: 991px) {
  #project .r-project-list .image img {
    height: 235px;
  }
}

@media (max-width: 767px) {
  #project .r-project-list .image img {
    height: 230px;
  }
}

#project .r-project-list .content {
  margin-top: 25px;
}

#project .r-project-list .content .title {
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #121212;
  display: block;
  margin-bottom: 7px;
}

#project .r-project-list .content .title:hover {
  color: #0063aa;
}

#project .r-project-list .content .tag {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 8px;
}

#project .r-project-list .content .tag li {
  margin-right: 8px;
  margin-bottom: 8px;
}

#project .r-project-list .content .tag li.active a,
#project .r-project-list .content .tag li:hover a {
  color: #fff;
  background-color: #0063aa;
}

#project .r-project-list .content .tag li a {
  font-size: 14px !important;
  color: #434343;
  background: #e2e2e2;
  border-radius: 55px;
  padding: 4px 24px 4px;
  display: inline-block;
  font-weight: 700;
  cursor: pointer;
}

#project .r-project-list .content .desc {
  font-size: 16px;
  color: #313131;
  font-weight: 400;
}

#project .r-project-list .content .readmore {
  display: inline-flex;
  align-items: center;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #0063aa;
}

#project .r-project-list .content .readmore:hover svg {
  margin-left: 28px;
}

#project .r-project-list .content .readmore img {
  margin-left: 18px;
}

#project .r-project-list .see-more {
  display: inline-block;
  background: #0063aa;
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  border-radius: 5px;
  /* padding: 33px 33px 28px; */
  margin-top: 50px;
  max-width: 100%;
  cursor: pointer;
}

#project .r-project-list .see-more:hover {
  background: #06436f;
}

#project .r-project-list .see-more svg {
  margin-left: 74px;
}

#project .r-contact {
  padding-top: 80px;
  /* margin-top: 74px; */
}

@media (max-width: 1199px) {
  #project .r-project-list .see-more svg {
    width: 40px;
  }

  #project .r-project-list .see-more {
    font-size: 18px;
    padding: 15px 33px 15px;
    width: 367px;
  }

  #project .r-tag ul {
    padding-right: 0;
  }

  #project .r-top .title-left h3 {
    font-size: 30px;
  }
}

@media (max-width: 767px) {
  #project .r-top .search input {
    width: 100%;
    box-shadow: none !important;
  }

  #project .r-tag .col-12 {
    margin-bottom: 30px;
  }

  #project .r-project-list {
    margin-top: 20px;
    margin-bottom: 50px;
  }

  #project .r-project-list .see-more {
    margin-top: 10px;
    font-weight: 600;
    width: auto;
  }
}

#news .r-big-banner {
  max-width: 100%;
  margin-top: 22px;
  position: relative;
  background: url('/assets/images/project/big-banner.png') no-repeat center;
  background-size: cover;
}

.content-news {
  color: #434343;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  display: -webkit-box;
  font-size: 16px;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

@media screen and (min-width: 200px) and (max-width: 1199px) {
  #news .r-big-banner {
    margin-top: 0;
  }
}

#news .r-big-banner .r-page-title {
  text-align: center;
  padding: 155px 0 195px;
}

@media screen and (min-width: 200px) and (max-width: 1199px) {
  #news .r-big-banner .r-page-title {
    top: 20%;
    padding: 130px 0 180px;
  }
}

@media screen and (min-width: 200px) and (max-width: 767px) {
  #news .r-big-banner .r-page-title {
    padding: 49px 0;
  }
}

#news .r-big-banner .r-page-title h3 {
  font-weight: 900;
  font-size: 56px;
  line-height: 58px;
  text-align: center;
  color: #fff;
  margin-bottom: 35px;
}

@media screen and (min-width: 200px) and (max-width: 1199px) {
  #news .r-big-banner .r-page-title h3 {
    font-size: 40px;
    margin-bottom: 0;
  }
}

@media screen and (min-width: 200px) and (max-width: 767px) {
  #news .r-big-banner .r-page-title h3 {
    font-size: 32px;
  }
}

#news .r-big-banner .r-page-title p {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #fff;
  text-align: center;
}

@media screen and (min-width: 200px) and (max-width: 1199px) {
  #news .r-big-banner .r-page-title p {
    font-size: 18px;
  }
}

@media screen and (min-width: 200px) and (max-width: 767px) {
  #news .r-big-banner .r-page-title p {
    font-size: 14px;
    line-height: 24px;
  }
}

#news .r-top {
  margin-top: 71px;
}

#news .r-top .title-left {
  display: flex;
  align-items: center;
}

#news .r-top .title-left h3 {
  font-size: 48px;
  font-weight: 900;
  color: #121212;
  position: relative;
  margin-bottom: 0;
}

#news .r-top .title-left h3::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -11px;
  width: 48px;
  height: 4px;
  background-color: #0063aa;
  z-index: 1;
}

#news .r-top .title-left .category {
  color: #0063aa;
  position: relative;
  margin-left: 54px;
  padding-top: 10px;
}

#news .r-top .title-left .category::before {
  content: '';
  width: 1px;
  height: 24px;
  background: #a7a7a7;
  position: absolute;
  left: -25px;
  top: 9px;
}

#news .r-top .title-left .category li a {
  font-size: 14px;
  color: #0063aa;
}

#news .r-top .search {
  position: relative;
  text-align: right;
}

#news .r-top .search input {
  background: #ffffff;
  border: 1px solid #e2e2e2;
  border-radius: 29px;
  width: 445px;
  height: 56px;
  padding: 0 23px;
  max-width: 100%;
  font-size: 16px;
  color: #434343;
  transition: 0.2s all;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

/* #news .r-top .search input:hover {
  background: #e7e7e7;
  border: 1px solid #bebebe;
} */

#news .r-top .search input:focus {
  outline: none;
}

#news .r-top .search input::placeholder {
  color: #a7a7a7;
}

#news .r-top .search button {
  position: absolute;
  right: 0;
  top: 0;
  background: transparent;
  border: none;
  width: 49px;
  height: 56px;
  z-index: 1;
}

#news .r-top .search button:hover path {
  stroke: #0063aa;
}

#news .r-news-list {
  margin: 42px 0 61px;
  position: relative;
}

#news .r-news-list::before {
  content: url('/assets/images//news/news-list-bg-1.png');
  position: absolute;
  bottom: 48px;
  left: -9px;
  z-index: -1;
}

#news .r-news-list::after {
  content: url('/assets/images//news/news-list-bg-2.png');
  position: absolute;
  top: -118px;
  right: -6px;
  z-index: -1;
}

#news .r-news-list .news-item {
  margin-bottom: 40px;
}

#news .r-news-list .news-item>.row {
  margin-left: -15px;
  margin-right: -15px;
}

#news .r-news-list .news-item>.row>div {
  padding: 0;
  width: 445px;
  margin-right: 30px;
}

#news .r-news-list .image {
  border-radius: 15px;
  overflow: hidden;
}

#news .r-news-list .image img {
  width: 445px;
  max-width: 100%;
  border-radius: 15px;
  height: 100%;
  max-height: 263px;
  object-fit: cover;
  transition: 0.4s all;
}

#news .r-news-list .image img:hover {
  transform: scale(1.1);
}

#news .r-news-list .content {
  margin-top: -3px;
}

#news .r-news-list .content .category {
  font-size: 14px;
  color: #0063aa;
  cursor: pointer;
}

#news .r-news-list .content>a {
  text-decoration: none;
}

#news .r-news-list .content .title {
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: #121212;
  display: block;
  margin-bottom: 0;
}

#news .r-news-list .content .title:hover {
  color: #0063aa;
}

#news .r-news-list .content .date {
  margin-bottom: 17px;
  color: #a7a7a7;
  line-height: 16px;
  font-size: 14px;
  margin-top: 5px;
}

#news .r-news-list .content .tag {
  padding-right: 0;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 8px;
}

#news .r-news-list .content .tag li {
  margin-right: 4px;
  margin-bottom: 8px;
}

#news .r-news-list .content .tag li.active a,
#news .r-news-list .content .tag li:hover a {
  color: #fff;
  background-color: #0063aa;
}

#news .r-news-list .content .tag li a {
  font-size: 16px;
  color: #434343;
  background: #e2e2e2;
  border-radius: 55px;
  padding: 4px 24px 4px;
  display: inline-block;
}

#news .r-news-list .content .desc {
  font-size: 16px;
  color: #313131;
  padding-right: 140px;
  margin-bottom: 48px;
}

#news .r-news-list .see-more {
  text-decoration: none;
  display: inline-block;
  background: #0063aa;
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  border-radius: 5px;
  /* padding: 33px 33px 28px; */
  margin-top: 121px;
  /* width: 156px;
    height: 56px; */
  max-width: 100%;
  cursor: pointer;
}

#news .r-news-list .see-more:hover {
  background: #06436f;
}

#news .r-news-list .see-more svg {
  margin-left: 74px;
}

#news .r-contact {
  /* margin-top: 106px; */
  padding-top: 0;
}

@media (max-width: 1800px) {
  #news .r-news-list::before {
    left: -200px;
  }

  #news .r-news-list::after {
    right: -340px;
  }
}

@media (max-width: 1199px) {
  #news .r-news-list .see-more svg {
    width: 40px;
  }

  #news .r-news-list .see-more {
    font-size: 18px;
    /* padding: 15px 33px 15px;
        width: 367px; */
  }

  #news .r-news-list::before,
  #news .r-news-list::after {
    display: none;
  }

  #news .r-news-list .content .desc {
    padding-right: 0;
  }
}

@media (max-width: 991px) {
  #news .r-news-list .content .desc {
    margin-bottom: 16px;
  }

  #news .r-top .title-left h3 {
    font-size: 30px;
  }
}

@media (max-width: 767px) {
  #news .r-top {
    margin-top: 30px;
  }

  #news .r-news-list .news-item {
    margin-bottom: 15px !important;
  }

  #offshore .r-reason .box ul li .icon {
    min-width: 90px !important;
  }

  #news .r-top .search input {
    width: 100%;
    box-shadow: none;
  }

  #news .r-top .search {
    margin-top: 30px;
  }

  #news .r-news-list .news-item .col-md-5 {
    margin-bottom: 25px;
  }

  #news .r-news-list .see-more {
    margin-top: 0;
  }

  #news .r-news-list .content .desc {
    font-size: 14px;
  }
}

#contact .r-contact {
  padding: 0 0 60px 0;
}

#contact .title-center {
  margin-bottom: 58px;
  text-align: center;
}

#contact .title-center h3 {
  font-size: 48px;
  font-weight: 700;
  color: #0063aa;
  position: relative;
  margin-bottom: 29px;
}

#contact .title-center h3::after {
  content: '';
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -11px;
  width: 48px;
  height: 4px;
  background-color: #0063aa;
  z-index: 1;
}

#contact .title-center .desc {
  font-size: 20px;
  color: #686868;
}

#contact .r-contact-form {
  padding-top: 80px;
  margin: 140px 0 0px;
  position: relative;
}

#contact .r-contact-form::before {
  content: url('/assets/images//contact/contact-form-bg-1.png');
  position: absolute;
  bottom: -156px;
  left: -7px;
  z-index: -1;
}

#contact .r-contact-form::after {
  content: url('/assets/images//contact/contact-form-bg-2.png');
  position: absolute;
  top: 15px;
  right: -9px;
  z-index: -1;
}

#contact .r-contact-form .info h1 {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  font-size: 40px;
  line-height: 56px;
  /* identical to box height, or 140% */

  text-transform: uppercase;

  color: #0063aa;
  position: relative;
  margin-bottom: 32px;
}

#contact .r-contact-form .info h1::after {
  position: absolute;
  content: '';
  width: 24px;
  height: 5px;
  background-color: rgba(0, 99, 170, 1);
  bottom: -8px;
  left: 0;
  border-radius: 5px;
}

#contact .r-contact-form .info .desc {
  color: #434343;
  line-height: 20px;
  margin-bottom: 37px;
}

#contact .r-contact-form .info .block+.block {
  border-top: 1px solid #e2e2e2;
  margin-top: 22px;
  padding-top: 24px;
}

#contact .r-contact-form .info .block h3 {
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: -0.02em;
  color: #121212;
  margin-bottom: 32px;
}

#contact .r-contact-form .info .block h3 svg {
  margin-left: 15px;
}

#contact .r-contact-form .info .block ul {
  padding: 0;
  width: 360px;
  max-width: 100%;
}

#contact .r-contact-form .info .block ul.link-list {
  margin-top: -6px;
}

#contact .r-contact-form .info .block ul.link-list li {
  margin-bottom: 15px;
}

#contact .r-contact-form .info .block ul li {
  /* display: flex; */
  align-items: center;
  font-size: 14px;
  color: #434343;
  letter-spacing: 0.01em;
  margin-bottom: 15px;
}

#contact .r-contact-form .info .block ul li .icon {
  margin-right: 17px;
}

#contact .r-contact-form .info .qr-code {
  margin-top: 25px;
}

@media (max-width: 767px) {
  #contact .r-contact-form .info .qr-code {
    display: none;
  }
}

#contact .r-contact-form .info .qr-code img {
  margin-left: -6px;
  max-width: 100%;
}

#contact .r-contact-form .info .social h4 {
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: -0.02em;
  color: #121212;
  margin-top: 44px;
  margin-bottom: 15px;
}

#contact .r-contact-form .info .social ul {
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}

#contact .r-contact-form .info .social ul li {
  margin-right: 17px;
  margin-bottom: 17px;
}

#contact .r-contact-form .info .social ul li a {
  width: 33px;
  height: 33px;
  background: #a7a7a7;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

#contact .r-contact-form .info .social ul li a:hover {
  background-color: #0063aa;
}

#contact .r-contact-form .form {
  /* background: #ffffff;
    box-shadow: 0px 26px 56px rgba(189, 189, 189, 0.54); */
  border-radius: 25px;
  padding: 0px 24px 20px;
  width: 445px;
  max-width: 100%;
  margin-left: auto;
}

#contact .r-contact-form .form .form-group {
  margin-bottom: 24px;
  position: relative;
}

#contact .r-contact-form .form .form-group label {
  z-index: 20;
  font-size: 16px;
  letter-spacing: -0.02em;
  color: #121212;
  margin-bottom: 0;
  background: #fff;
  position: absolute;
  top: -11px;
  left: 11px;
  padding: 0 5px;
}

#contact .r-contact-form .form input,
#contact .r-contact-form .form select {
  font-size: 16px;
  border: 1px solid #0063aa;
  border-radius: 5px;
  height: 44px;
  color: #0063aa;
  letter-spacing: -0.02em;
  padding: 0 15px;
}

#contact .r-contact-form .form input {
  /* opacity: 0; */
}

#contact .r-contact-form .form input,
#contact .r-contact-form .form textarea,
#contact .r-contact-form .form select {
  color: #0063aa;
}

#contact .r-contact-form .form select::-webkit-input-placeholder {
  color: deeppink !important;
}

#contact .ant-form-item-explain-error {
  padding: 0;
  color: red;
  font-size: 14px;
  margin: 2px 0 12px 0;
}

#contact .r-contact-form .form input::placeholder,
#contact .r-contact-form .form select::placeholder,
#contact .r-contact-form .form textarea::placeholder {
  color: rgba(0, 99, 170, 0.8);
}

#contact .r-contact-form .form select {
  appearance: none;
  background: url('/assets/images//icon/icon-down.png') no-repeat calc(100% - 7px) 11px;
  cursor: pointer;
}

#contact .r-contact-form .form .form-control:focus {
  box-shadow: none;
}

#contact .r-contact-form .form textarea {
  font-size: 16px;
  border: 1px solid #0063aa;
  border-radius: 5px;
  height: 140px;
  color: #0063aa;
  letter-spacing: -0.02em;
  padding: 15px;
}

#contact .r-contact-form .form textarea::placeholder {
  color: rgba(0, 99, 170, 0.8);
}

#contact .r-contact-form .form .custom-file {
  height: 44px;
}

#contact .r-contact-form .form .custom-file-label {
  cursor: pointer;
  padding: 0 15px;
  top: 0;
  width: 100%;
  position: absolute;
  text-align: right;
  height: 44px;
  border: 1px solid #0063aa;
  border-radius: 5px;
  overflow: hidden;
  text-align: left;
}

#contact .r-contact-form .form .custom-file-label svg {
  margin-top: 10px;
}

#contact .r-contact-form .form .custom-file-label::after {
  position: absolute;
  content: attr(data-content);
  left: 0;
  right: auto;
  background: transparent;
  border: none;
  color: #0063aa;
  font-size: 16px;
  letter-spacing: -0.02em;
  padding: 0px 15px 0px 50px;
  line-height: 44px;
  height: 100%;
  max-width: 90%;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-right {
  text-align: right;
}

#contact .r-contact-form .form button {
  /* font-size: 24px;
    color: #fff;
    font-weight: 700;
    background-color: #0063aa;
    border-radius: 5px;
    padding: 25px 32px 21px;
    border: none;
    margin-left: auto;
    margin-top: 60px;
    width: 100%; */
  background-color: #0063aa;
  border: none;
  border-radius: 5px;
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  margin-left: auto;
  margin-top: 24px;
  padding: 12px 43px 12px 41px;
}

#contact .r-contact-form .form button svg {
  margin-left: 45px;
}

#contact .r-contact-form .form button:hover {
  background-color: #06436f;
}

#contact .r-member {
  padding-top: 60px;
  padding-bottom: 277px;
  background: url('/assets/images//home/member-bg.png') no-repeat center;
}

#contact .r-member .label {
  position: absolute;
  left: calc(100% + 51px);
  top: calc(50% + 155px);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

#contact .r-member .row {
  margin-left: -8px;
  margin-right: -8px;
}

#contact .r-member .row .col-4 {
  padding-left: 8px;
  padding-right: 8px;
}

#contact .r-member .title-center {
  margin-bottom: 50px;
}

#contact .r-member .image-logo {
  margin-top: 0;
  margin-bottom: 52px;
}

#contact .r-member .box {
  border: 1px solid #e2e2e2;
  box-shadow: 0px 11px 12px rgba(189, 189, 189, 0.25);
  border-radius: 15px;
  height: 100%;
  padding: 10px 15px;
  background-color: #fff;
  max-width: 209px;
  max-height: 222px;
  margin: auto;
}

#contact .r-member .box .logo {
  height: 53px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 19px;
}

#contact .r-member .box .logo img {
  padding: 10px;
  width: auto;
}

#contact .r-member .box ul li {
  font-size: 16px;
  color: #656565;
  position: relative;
  padding-left: 13px;
  line-height: 20px;
}

#contact .r-member .box ul li::before {
  content: '';
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background-color: #656565;
  display: inline-block;
  position: absolute;
  left: 0;
  top: 8px;
}

@media (min-width: 1200px) {
  #contact .r-member .row .col-4 {
    flex: 0 0 20%;
    max-width: 20%;
  }
}

@media (max-width: 1770px) {
  #contact .r-contact-form::before {
    left: -230px;
  }
}

@media (max-width: 1366px) {

  #contact .r-contact-form::before,
  #contact .r-contact-form::after {
    display: none;
  }
}

@media (max-width: 1199px) {
  #contact .r-contact-form .form button {
    font-size: 18px;
  }

  #contact .r-contact-form .form button svg {
    width: 40px;
  }

  #contact .title-left h3,
  #contact .title-center h3 {
    font-size: 30px;
  }

  #contact .title-left .desc,
  #contact .title-center .desc {
    font-size: 14px;
  }

  #contact .r-contact-form::before,
  #contact .r-contact-form::after {
    display: none;
  }

  #contact .r-member .row {
    justify-content: center;
  }

  #contact .r-member .row .col-4 {
    margin-bottom: 16px;
  }

  #contact .r-member {
    padding-bottom: 100px;
  }
}

@media (max-width: 991px) {
  #contact .r-contact-form .info h1 {
    font-size: 30px;
  }

  #contact .r-contact-form .info .block h3 {
    font-size: 18px;
    margin-bottom: 16px;
  }
}

@media (max-width: 767px) {
  #contact .r-contact-form {
    margin: 20px 0 80px 0;
  }

  #contact .r-contact-form .form input,
  #contact .r-contact-form .form select,
  #contact .r-contact-form .form textarea,
  #contact .r-contact-form .form .custom-file-label::after {
    font-size: 14px;
  }

  #contact .r-contact-form .form button {
    font-size: 14px;
    padding: 10px 27px;
  }

  #contact .r-contact-form .form {
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
  }

  #contact .r-contact-form .info h1 {
    font-size: 20px;
  }

  #contact .r-contact-form .info .block h3 {
    font-size: 16px;
  }

  #contact .title-left h3,
  #contact .title-center h3 {
    font-size: 16px;
    margin-bottom: 15px;
  }

  #contact .title-left .desc,
  #contact .title-center .desc {
    font-size: 14px;
    line-height: 24px;
    color: #434343;
    padding-right: 40px;
  }

  #contact .title-left .desc br,
  #contact .title-center .desc br {
    display: none;
  }

  #contact .title-left h3::after {
    bottom: -4px;
    width: 14px;
    height: 1px;
  }

  #contact .r-member .title-center {
    margin-bottom: 31px;
  }

  #contact .r-member .title-center h3 {
    width: 180px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 26px;
  }

  #contact .r-member {
    padding-top: 23px;
    padding-bottom: 35px;
  }

  #contact .r-member .image-logo {
    margin-top: 0;
    margin-bottom: 22px;
  }

  #contact .r-member .image-logo img {
    width: 126.5px;
  }

  #contact .r-member .box .logo {
    height: 33px;
    margin-bottom: 7px;
  }

  #contact .r-member .box .logo.tsoft img {
    width: 59px;
  }

  #contact .r-member .box .logo.t-japan img {
    width: 73px;
  }

  #contact .r-member .box .logo.eyefire img {
    width: 77px;
  }

  #contact .r-member .box .logo.sky-live img {
    width: 43px;
  }

  #contact .r-member .box .logo.blockchain img {
    width: 44px;
  }

  #contact .r-member .box ul li {
    font-size: 8px;
    padding-left: 5px;
    line-height: 16px;
  }

  #contact .r-member .row {
    padding-bottom: 50px;
    margin-left: -4px;
    margin-right: -4px;
  }

  #contact .r-member .row .col-4 {
    padding-left: 4px;
    padding-right: 4px;
    margin-bottom: 7px;
  }

  #contact .r-member .box ul li::before {
    width: 2px;
    height: 2px;
  }

  #contact .r-member .box {
    padding: 6px 7px 10px;
  }
}

#solution .sidebar {
  position: fixed;
  width: calc((100% - 1110px) / 2);
  top: 10%;
  left: 0;
  z-index: 10;
  padding: 190px 20px 20px 60px;
}

#solution .sidebar.off-fixed {
  opacity: 0;
  visibility: hidden;
}

#solution .sidebar .js-open-sidebar {
  display: none;
}

#solution .sidebar h3.title {
  display: none;
}

#solution .sidebar ul {
  padding: 0;
  margin: 0;
  position: relative;
}

#solution .sidebar ul::before {
  content: '';
  width: 1px;
  height: 500px;
  background-color: #0063aa;
  position: absolute;
  left: -13px;
  top: 50%;
  z-index: 1;
  transform: translateY(-50%);
}

#solution .sidebar ul li {
  margin-bottom: 27px;
  position: relative;
}

#solution .sidebar ul li.active::before {
  opacity: 1;
}

#solution .sidebar ul li.active a {
  font-size: 18px;
  font-weight: 700;
  color: #0063aa;
}

#solution .sidebar ul li a {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #d0d0d0;
  display: inline-block;
  position: relative;
}

#solution .sidebar ul li a.active {
  font-size: 18px;
  font-weight: 700;
  color: #0063aa;
}

#solution .sidebar ul li a.active::before {
  opacity: 1;
}

/* #solution .sidebar ul li a:hover {
    color: #0063aa;
} */

#solution .sidebar ul li a::before {
  content: '';
  width: 8px;
  height: 8px;
  display: block;
  border-radius: 50%;
  background-color: #0063aa;
  position: absolute;
  left: -16px;
  top: 5px;
  opacity: 0;
  z-index: 2;
}

#solution .r-big-banner {
  max-width: 100%;
  margin-top: 20px;
  background: url('/assets/images/solution/big-banner.png') no-repeat center;
  background-size: cover;
}

@media screen and (min-width: 200px) and (max-width: 1199px) {
  #solution .r-big-banner {
    margin-top: -2px;
  }
}

@media screen and (min-width: 200px) and (max-width: 767px) {
  #solution .r-big-banner {
    margin-top: 46px;
  }
}

#solution .r-big-banner .r-page-title {
  text-align: center;
  padding: 155px 0 169px;
}

@media screen and (min-width: 200px) and (max-width: 767px) {
  #solution .r-big-banner .r-page-title {
    padding: 50px 0;
  }
}

#solution .r-big-banner .r-page-title h3 {
  margin-top: 32px;
  font-weight: 900;
  font-size: 56px;
  line-height: 66px;
  text-align: center;
  color: #fff;
  margin-bottom: 26px;
}

@media screen and (min-width: 200px) and (max-width: 1199px) {
  #solution .r-big-banner .r-page-title h3 {
    font-size: 40px;
  }
}

@media screen and (min-width: 200px) and (max-width: 767px) {
  #solution .r-big-banner .r-page-title h3 {
    font-size: 32px;
  }
}

#solution .r-big-banner .r-page-title p {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #fff;
  text-align: center;
}

@media screen and (min-width: 200px) and (max-width: 1199px) {
  #solution .r-big-banner .r-page-title p {
    font-size: 18px;
  }
}

@media screen and (min-width: 200px) and (max-width: 767px) {
  #solution .r-big-banner .r-page-title p {
    font-size: 14px;
    line-height: 24px;
  }
}

#solution .r-solution-list {
  margin-top: 146px;
  padding-bottom: 108px;
  position: relative;
}

#solution .r-solution-list ul li {
  position: relative;
  padding: 63px 0 62px 0;
  background-size: cover;
  margin: 50px 0;
}

#solution .r-solution-list ul li+li {
  margin-top: 95px;
}

#solution .r-solution-list ul li {
  background-size: cover !important;
}

@media (min-width: 768px) {
  #solution .r-solution-list ul li:nth-child(1) {
    background: url('/assets/images/solution/img1.jpg') no-repeat center;
    /* background-size: cover; */
  }

  #solution .r-solution-list ul li:nth-child(2) {
    background: url('/assets/images/solution/img2.jpg') no-repeat center;
  }

  #solution .r-solution-list ul li:nth-child(3) {
    background: url('/assets/images/solution/img3.jpg') no-repeat center;
  }

  #solution .r-solution-list ul li:nth-child(4) {
    background: url('/assets/images/solution/img4.jpg') no-repeat center;
  }

  #solution .r-solution-list ul li:nth-child(5) {
    background: url('/assets/images/solution/img5.jpg') no-repeat center;
  }

  #solution .r-solution-list ul li:nth-child(6) {
    background: url('/assets/images/solution/img6.jpg') no-repeat center;
  }

  #solution .r-solution-list ul li:nth-child(7) {
    background: url('/assets/images/solution/img7.jpg') no-repeat center;
  }

  #solution .r-solution-list ul li:nth-child(8) {
    background: url('/assets/images/solution/img8.jpg') no-repeat center;
  }

  #solution .r-solution-list ul li:nth-child(9) {
    background: url('/assets/images/solution/img9.jpg') no-repeat center;
  }
}

#solution .r-solution-list .img-banner {
  display: none;
}

@media (max-width: 767px) {
  #solution .r-solution-list .list-pc {
    /* display: none; */
  }

  #solution .r-solution-list .img-banner {
    display: block;
  }

  #solution .r-solution-list .list-mobile {
    display: block !important;
  }

  #solution .r-solution-list .list-mobile .img-banner img {
    min-height: 218px;
    object-fit: cover;
  }
}

#solution .r-solution-list ul li:nth-child(1)::before {
  content: '01';
  max-width: 177.36px;
  max-height: 122px;
}

#solution .r-solution-list ul li:nth-child(2)::before {
  content: '02';
  max-width: 177.36px;
  max-height: 122px;
}

#solution .r-solution-list ul li:nth-child(3)::before {
  content: '03';
  max-width: 177.36px;
  max-height: 122px;
}

#solution .r-solution-list ul li:nth-child(4)::before {
  content: '04';
  max-width: 177.36px;
  max-height: 122px;
}

#solution .r-solution-list ul li:nth-child(5)::before {
  content: '05';
  max-width: 177.36px;
  max-height: 122px;
}

#solution .r-solution-list ul li:nth-child(6)::before {
  content: '06';
  max-width: 177.36px;
  max-height: 122px;
}

#solution .r-solution-list ul li:nth-child(7)::before {
  content: '07';
  max-width: 177.36px;
  max-height: 122px;
}

#solution .r-solution-list ul li:nth-child(8)::before {
  content: '08';
  max-width: 177.36px;
  max-height: 122px;
}

#solution .r-solution-list ul li:nth-child(9)::before {
  content: '09';
  max-width: 177.36px;
  max-height: 122px;
}

#solution .r-solution-list ul li::before {
  color: transparent;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #0063aa;
  font-weight: 900;
  font-size: 152.82px;
  line-height: 1;
  position: absolute;
}

#solution .r-solution-list ul li:nth-child(odd)::before {
  top: -92px;
  left: -41px;
}

#solution .r-solution-list ul li:nth-child(even)::before {
  top: -92px;
  right: -45px;
}

#solution .r-solution-list ul li:nth-child(even) .box {
  margin-left: auto;
  padding-left: 31px;
}

#solution .r-solution-list ul li:nth-child(even) .box .readmore {
  left: 31px;
}

#solution .r-solution-list ul li .box {
  display: block;
  background: rgba(255, 255, 255, 0.9);
  width: calc(50% - 3px);
  padding: 38px 17px 60px;
  position: relative;
  min-height: 400px;
}

#solution .r-solution-list ul li .box h5 {
  font-weight: 700;
  font-size: 40px;
  line-height: 47px;
  color: #0063aa;
  margin-bottom: 24px;
}

#solution .r-solution-list ul li .box p {
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: #525252;
}

#solution .r-solution-list ul li .box .readmore {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #0063aa;
  position: absolute;
  /* bottom: 100px; */
  left: 21px;
  z-index: 1;
  display: flex;
  align-items: center;
}

#solution .r-solution-list ul li .box .readmore img {
  transition: 0.4s all;
}

#solution .r-solution-list ul li .box .readmore:hover img {
  margin-left: 34px !important;
}

#solution .r-solution-list ul li .box .readmore svg {
  margin-left: 24px;
}

#solution .r-contact {
  margin-top: 0;
}

@media (max-width: 1800px) {
  #solution .sidebar {
    padding-left: 50px;
    padding-top: 0;
    padding-bottom: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}

@media (max-width: 1600px) {
  #solution .r-solution-list .circle {
    display: none;
  }

  #solution .sidebar {
    padding: 0;
    transform: none;
    width: auto;
  }

  #solution .sidebar ul::before {
    height: 100%;
  }

  #solution .sidebar ul li {
    margin-bottom: 10px;
  }

  #solution .sidebar .js-open-sidebar {
    display: flex;
    align-items: center;
    font-size: 24px;
    font-weight: 500;
    color: #0063aa;
    background: #ffffff;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    padding: 16.4px 20px;
    width: 171px;
  }

  #solution .sidebar .js-open-sidebar svg {
    margin-right: 9px;
  }

  #solution .sidebar .box.open {
    display: block;
  }

  #solution .sidebar h3.title {
    display: flex;
  }

  #solution .sidebar .box {
    display: none;
    position: absolute;
    left: 100%;
    background: #fff;
    width: 283px;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    padding: 16px 39px;
    top: 50%;
    transform: translateY(-50%);
  }

  #solution .sidebar .box h3.title {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #0063aa;
    display: block;
    margin-bottom: 27px;
  }
}

@media (max-width: 1366px) {
  #solution .r-big-banner {
    width: 100%;
  }
}

@media (max-width: 1199px) {
  #solution .r-solution-list .top .desc {
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
  }

  #solution .r-solution-list .top h3 {
    font-size: 30px;
  }

  /* #solution .sidebar {
    display: none;
  } */
  #solution .r-page-title h3 {
    padding: 25px;
    font-size: 30px;
    line-height: 40px;
  }
}

@media (max-width: 991px) {
  #solution .r-big-banner .box .inner h3 {
    font-size: 20px;
    line-height: 30px;
    padding-right: 0;
  }

  #solution .r-solution-list ul li:nth-child(odd)::before {
    top: -66px;
    left: 0;
  }

  #solution .r-solution-list ul li:nth-child(even)::before {
    top: -66px;
    right: 0;
  }

  #solution .r-solution-list ul li::before {
    font-size: 100.5px;
  }

  #solution .r-solution-list ul li .box h5 {
    font-size: 30px;
    line-height: 34px;
  }

  #solution .r-solution-list ul li .box p {
    font-size: 14px !important;
    line-height: 24px !important;
  }

  #solution .r-solution-list ul li .box>.title-box-solution>p {
    font-size: 18px !important;
    line-height: 24px !important;
  }
}

@media (max-width: 767px) {
  #solution .r-solution-list .top {
    margin-bottom: 40px;
  }

  #solution .sidebar .js-open-sidebar {
    width: auto;
  }

  #solution .sidebar .js-open-sidebar span {
    display: none;
  }

  #solution .r-solution-list .top h3::after {
    width: 23px;
    height: 1px;
  }

  #solution .r-solution-list .top h3 {
    font-size: 18px;
    margin-bottom: 27px;
  }

  #solution .r-page-title {
    margin-top: -48px;
  }

  #solution .r-page-title h3 {
    padding: 10px;
    font-size: 18px;
    line-height: 28px;
  }

  #solution .r-solution-list ul li .box {
    display: block;
    width: calc(100% - 20px);
    padding: 38px 17px 60px;
    min-height: 350px;
    border: 1px solid #bdbdbd;
    border-top: none;
  }

  #solution .r-solution-list ul li .box h5 {
    font-size: 23px;
    line-height: 28px;
  }

  #solution .r-solution-list ul li .box .readmore {
    font-size: 14px;
  }

  #solution .r-solution-list ul li {
    padding: 35px 0;
  }

  #solution .r-solution-list ul li:nth-child(odd)::before {
    top: -30px;
    left: 0;
  }

  #solution .r-solution-list ul li:nth-child(even)::before {
    top: -30px;
    right: 0;
  }

  #solution .r-solution-list ul li::before {
    font-size: 50px;
  }

  #solution .r-solution-list {
    margin-top: 30px;
    padding-bottom: 50px;
  }

  #solution .r-solution-list>.container {
    padding: 0 24px !important;
  }

  #solution .r-solution-list ul li+li {
    margin-top: 20px;
  }
}

#news-details .handleBtnBackNews {
  margin-top: 77px;
}

@media (max-width: 1199px) {
  #news-details .handleBtnBackNews {
    margin-top: 37px;
  }
}

#news-details .handleBtnBackNews a svg {
  margin-right: 15px;
}

#news-details .handleBtnBackNews a:hover svg {
  margin-left: -10px;
  transition: 0.4s all;
}

#news-details .handleBtnBackNews a {
  color: #3e3c3c;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
}

#news-details .main-content .page-title {
  font-weight: 700;
  font-size: 40px;
  line-height: 47px;
  color: #474747;
  margin-bottom: 15px;
  margin-top: 28px;
}

#news-details .main-content .meta {
  display: flex;
  margin-bottom: 31px;
}

#news-details .main-content .meta .date {
  display: flex;
  align-items: center;
  font-size: 24px;
  color: #353535;
  margin-right: 29px;
  position: relative;
}

#news-details .main-content .meta .date::after {
  content: '';
  width: 2px;
  height: 22px;
  background-color: #525252;
  position: absolute;
  right: -15px;
  top: 10px;
}

#news-details .main-content .meta .date img {
  margin-right: 10px;
}

#news-details .main-content .meta .share {
  display: flex;
  align-items: center;
}

#news-details .main-content .meta .share h3 {
  font-size: 24px;
  font-weight: 400;
  color: #353535;
  margin-bottom: 0;
  margin-right: 14px;
}

#news-details .main-content .meta .share ul {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0;
}

#news-details .main-content .meta .share ul li {
  margin-right: 16px;
  margin-right: 23px;
}

#news-details .main-content .post p {
  font-size: 16px;
  line-height: 24px;
  color: #313131;
}

#news-details .main-content .post .image {
  margin-top: 33px;
  margin-bottom: 34px;
}

#news-details .main-content .post figure {
  display: table;
  margin: 1rem auto;
}

#news-details .main-content .post figure figcaption {
  font-style: italic;
  color: #999;
  text-align: center;
}

#news-details .main-content .post .author {
  font-size: 16px;
  font-weight: 500;
  color: #000000;
  border-bottom: 1px solid #a4a4a4;
  padding-bottom: 27px;
  margin-bottom: 60px;
  margin-top: 44px;
}

#news-details .r-news-list {
  margin: 42px 0 179px;
  position: relative;
}

#news-details .r-news-list .news-item {
  margin-bottom: 40px;
}

#news-details .r-news-list .image {
  padding-right: 10px;
}

#news-details .r-news-list .image img {
  width: 100%;
}

#news-details .r-news-list .content {
  padding-left: 6px;
  margin-top: -3px;
}

#news-details .r-news-list .content .category {
  font-size: 14px;
  color: #0063aa;
}

#news-details .r-news-list .content .title {
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: #121212;
  display: block;
  margin-bottom: 0;
}

@media (min-width: 767.5px) {
  #news-details .r-news-list .content .title:hover {
    color: #0063aa;
  }
}

#news-details .r-news-list .content .date {
  margin-bottom: 12px;
}

#news-details .r-news-list .content .tag {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 8px;
}

#news-details .r-news-list .content .tag li {
  margin-right: 8px;
  margin-bottom: 8px;
}

#news-details .r-news-list .content .tag li.active a,
#news-details .r-news-list .content .tag li:hover a {
  color: #fff;
  background-color: #0063aa;
}

#news-details .r-news-list .content .tag li a {
  font-size: 14px;
  color: #434343;
  background: #e2e2e2;
  border-radius: 55px;
  padding: 5px 24px 3px;
  display: inline-block;
}

#news-details .r-news-list .content .desc {
  font-size: 16px;
  color: #313131;
  padding-right: 140px;
  margin-bottom: 48px;
}

#news-details .r-news-list .see-more {
  display: inline-block;
  background: #0063aa;
  color: #fff;
  font-size: 32px;
  font-weight: 400;
  border-radius: 5px;
  padding: 33px 33px 28px;
  margin-top: 121px;
  width: 667px;
  max-width: 100%;
}

#news-details .r-news-list .see-more:hover {
  background: #06436f;
}

#news-details .r-news-list .see-more svg {
  margin-left: 74px;
}

@media (max-width: 1199px) {
  #news-details .r-news-list .see-more svg {
    width: 40px;
  }

  #news-details .r-news-list .see-more {
    font-size: 18px;
    padding: 15px 33px 15px;
    width: 367px;
  }

  #news-details .r-news-list .content .desc {
    padding-right: 0;
  }

  #news-details .main-content .page-title {
    font-size: 30px;
    margin-top: 50px;
  }

  #news-details .main-content .meta .date,
  #news-details .main-content .meta .share h3 {
    font-size: 18px;
  }

  #news-details .r-news-list::before,
  #news-details .r-news-list::after {
    display: none;
  }

  #news-details .r-news-list .content .desc {
    padding-right: 0;
  }
}

@media (max-width: 991px) {
  #news-details .r-news-list .content .desc {
    margin-bottom: 16px;
  }
}

@media (max-width: 767px) {
  #news-details .main-content .meta .date img {
    width: 25px;
  }

  #news-details .main-content .post p {
    font-size: 16px;
  }

  #news-details .main-content .meta .share ul li svg {
    width: 30px;
  }

  #news-details .main-content .meta .date {
    margin-right: 0;
    margin-bottom: 15px;
  }

  #news-details .main-content .meta .date::after {
    display: none;
  }

  #news-details .main-content .meta .date,
  #news-details .main-content .meta .share h3 {
    font-size: 14px;
  }

  #news-details .main-content .meta .share ul li {
    margin-right: 10px;
  }

  #news-details .main-content .meta {
    flex-direction: column;
  }

  #news-details .main-content .page-title {
    font-size: 18px;
    margin-top: 30px;
    line-height: 21px;
  }

  #news-details .r-news-list .news-item .col-md-5 {
    margin-bottom: 25px;
  }

  #news-details .r-news-list .see-more {
    margin-top: 0;
  }

  #news-details .r-news-list .content .desc {
    font-size: 14px;
  }

  #news-details .r-news-list {
    margin-bottom: 70px;
  }
}

#recruitment .r-big-banner {
  max-width: 100%;
  margin-top: 11px;
  background: url('/assets/images/recruitment/big-banner.png') no-repeat center;
  background-size: cover;
}

@media screen and (min-width: 200px) and (max-width: 1199px) {
  #recruitment .r-big-banner {
    margin-top: 87px;
  }
}

@media screen and (min-width: 200px) and (max-width: 767px) {
  #recruitment .r-big-banner {
    margin-top: 46px;
  }
}

#recruitment .r-big-banner .r-page-title {
  text-align: center;
  padding: 214px 0 203px;
}

@media screen and (min-width: 200px) and (max-width: 1199px) {
  #recruitment .r-big-banner .r-page-title {
    top: 20%;
    padding: 130px 0 180px;
  }
}

@media screen and (min-width: 200px) and (max-width: 767px) {
  #recruitment .r-big-banner .r-page-title {
    padding: 50px 0;
  }
}

#recruitment .r-big-banner .r-page-title h3 {
  font-weight: 900;
  font-size: 56px;
  line-height: 58px;
  text-align: center;
  color: #fff;
}

@media screen and (min-width: 200px) and (max-width: 1199px) {
  #recruitment .r-big-banner .r-page-title h3 {
    font-size: 40px;
    margin-bottom: 0;
  }
}

@media screen and (min-width: 200px) and (max-width: 767px) {
  #recruitment .r-big-banner .r-page-title h3 {
    font-size: 32px;
  }
}

#recruitment .r-big-banner .r-page-title h5 {
  text-align: center;
  font-weight: 900;
  font-size: 32px;
  line-height: 38px;
  color: #ffffff;
  margin-top: -5px;
}

@media screen and (min-width: 200px) and (max-width: 1199px) {
  #recruitment .r-big-banner .r-page-title h5 {
    font-size: 18px;
  }
}

#recruitment .r-list-job {
  margin-top: 35px;
}

@media screen and (min-width: 200px) and (max-width: 767px) {
  #recruitment .r-list-job .container {
    padding-left: 15px;
    padding-right: 15px;
  }
}

#recruitment .r-list-job h3.title {
  font-weight: 900;
  font-size: 48px;
  line-height: 56px;
  color: #0063aa;
  position: relative;
}

#recruitment .r-list-job h3.title::after {
  content: '';
  width: 48px;
  height: 4px;
  border-radius: 8px;
  background: #0063aa;
  position: absolute;
  left: 0;
  bottom: -15px;
}

#recruitment .r-list-job .content .group-btn {
  margin-top: 56px;
  display: flex;
  justify-content: space-between;
}

#recruitment .r-list-job .content .group-btn .item a {
  margin-right: 12px;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #0063aa;
  border: 1px solid #0063aa;
  border-radius: 30px;
  padding: 9px 35px;
}

#recruitment .r-list-job .content .group-btn .item a:hover {
  background: #0063aa;
  color: #fff;
}

@media screen and (min-width: 200px) and (max-width: 1199px) {
  #recruitment .r-list-job .content .group-btn .item a {
    font-size: 14px;
    padding: 9px 19px;
  }
}

@media screen and (min-width: 200px) and (max-width: 767px) {
  #recruitment .r-list-job .content .group-btn .item a {
    font-size: 14px;
    padding: 3px 17px;
    margin-right: 6px;
    margin-bottom: 6px;
    display: inline-block;
  }
}

#recruitment .r-list-job .content .group-btn .icon {
  margin-top: -12px;
}

@media screen and (min-width: 200px) and (max-width: 1199px) {
  #recruitment .r-list-job .content .group-btn .icon {
    text-align: end;
  }

  #recruitment .r-list-job .content .group-btn .icon svg {
    width: 50%;
  }
}

#recruitment .r-list-job .job {
  padding-top: 124px;
}

@media screen and (min-width: 200px) and (max-width: 1199px) {
  #recruitment .r-list-job .job {
    padding-top: 100px;
  }
}

@media screen and (min-width: 200px) and (max-width: 767px) {
  #recruitment .r-list-job .job {
    padding-top: 75px;
  }
}

#recruitment .r-list-job .job ul li {
  margin-bottom: 109px;
  position: relative;
}

@media screen and (min-width: 200px) and (max-width: 767px) {
  #recruitment .r-list-job .job ul li {
    margin-bottom: 80px;
  }
}

#recruitment .r-list-job .job ul li::after {
  content: '';
  background: #afafaf;
  height: 1px;
  width: 100%;
  position: absolute;
  bottom: -50px;
}

@media (max-width: 1199px) {
  #recruitment .r-list-job .job ul li::after {
    bottom: -38px;
  }
}

@media screen and (min-width: 200px) and (max-width: 767px) {
  #recruitment .r-list-job .job ul li .job-item {
    display: block;
  }
}

#recruitment .r-list-job .job ul li .job-item .event {
  display: flex;
}

@media screen and (min-width: 200px) and (max-width: 767px) {
  #recruitment .r-list-job .job ul li .job-item .event {
    padding-bottom: 30px;
  }
}

#recruitment .r-list-job .job ul li .job-item .event .date {
  width: 121px;
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
}

@media screen and (min-width: 200px) and (max-width: 1199px) {
  #recruitment .r-list-job .job ul li .job-item .event .date {
    font-size: 18px;
    text-align: left;
  }
}

#recruitment .r-list-job .job ul li .job-item .event .type {
  font-weight: 700;
  font-size: 24px;
  line-height: 46px;
  margin: 0 69px 0 21px;
  border: 1px solid #000000;
  border-radius: 30px;
  height: 48px;
  width: 168px;
  margin-top: -9px;
}

@media screen and (min-width: 200px) and (max-width: 1199px) {
  #recruitment .r-list-job .job ul li .job-item .event .type {
    font-size: 14px;
    width: 112px;
    margin-top: -2px;
    height: 36px;
    line-height: 33px;
  }
}

#recruitment .r-list-job .job ul li .job-item .title {
  margin-top: -12px;
  text-align: start;
  width: 517px;
  font-weight: 500;
  font-size: 24px;
  margin-right: 157px;
  font-style: normal;
  line-height: 32px;
}

#recruitment .r-list-job .job ul li .job-item .title a {
  color: #000;
}

#recruitment .r-list-job .job ul li .job-item .title::after {
  display: none;
}

@media screen and (min-width: 200px) and (max-width: 1199px) {
  #recruitment .r-list-job .job ul li .job-item .title {
    font-size: 14px;
    width: 100%;
    margin-right: 0;
    padding-right: 50px;
    margin-bottom: -7px;
  }

  #detailrecruitment .detail-mian .list-type p {
    font-size: 14px !important;
  }

  #detailrecruitment .detail-mian .list-type b {
    font-size: 16px;
  }
}

@media (max-width: 575px) {
  #recruitment .r-list-job .job ul li .job-item .title br {
    display: none;
  }
}

@media screen and (min-width: 200px) and (max-width: 1199px) {
  #recruitment .r-list-job .job ul li .job-item .icon svg {
    width: 40px;
    text-align: end;
  }
}

@media screen and (min-width: 200px) and (max-width: 767px) {
  #recruitment .r-list-job .job ul li .job-item .icon {
    position: absolute;
    right: 0;
    transform: translateY(-50%);
    top: 50%;
  }
}

#recruitment .r-list-job .job ul li .job-item .status {
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  color: #fdfdfd;
  background: #0063aa;
  border: 1px solid #0063aa;
  border-radius: 30px;
  width: 140px;
  margin-top: 22px;
  padding: 2px 0;
  text-align: center;
}

@media screen and (min-width: 200px) and (max-width: 1199px) {
  #recruitment .r-list-job .job ul li .job-item .status {
    font-size: 14px;
    line-height: 23px;
    width: 100px;
  }
}

#recruitment .r-list-job .job ul li .job-item {
  position: relative;
}

#recruitment .r-list-job .job ul li .job-item .date {
  margin-top: 21px;
}

#recruitment .r-list-job .job ul li .job-item .date svg {
  margin-right: 17px;
}

#recruitment .r-list-job .job ul li .job-item .date span {
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: rgba(0, 0, 0, 0.86);
}

@media (max-width: 767px) {
  #recruitment .r-list-job .job ul li .job-item .date span {
    font-size: 14px;
  }
}

#recruitment .r-list-job .job ul li .job-item .map {
  display: flex;
  color: #000;
  align-items: center;
}

@media (max-width: 767px) {
  #recruitment .r-list-job .job ul li .job-item .map {
    display: block;
  }
}

#recruitment .r-list-job .job ul li .job-item .map svg {
  margin-right: 25px;
}

#recruitment .r-list-job .job ul li .job-item .map span {
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
}

@media (max-width: 1199px) {
  #recruitment .r-list-job .job ul li .job-item .map span {
    font-size: 14px;
  }

  #detailrecruitment .detail-main .list-type p {
    font-size: 14px !important;
  }

  #detailrecruitment .detail-main .list-type p>b {
    font-size: 16px !important;
  }
}

#recruitment .r-list-job .job ul li .job-item .map .price {
  margin-left: 64px;
  display: flex;
  align-items: center;
}

@media (max-width: 767.5px) {
  #recruitment .r-list-job .job ul li .job-item .map .price {
    margin-left: 0px;
    margin-top: 13px;
  }
}

#recruitment .r-list-job .job ul li .job-item .map .price span {
  line-height: 28px;
  color: #0063aa;
}

#recruitment .r-list-job .job ul li .job-item .btnNext {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}

@media (max-width: 1199px) {
  #recruitment .r-list-job .job ul li .job-item .btnNext img {
    width: 65%;
  }

  #recruitment .r-list-job .job ul li .job-item .btnNext {
    right: -22px;
  }
}

#recruitment .r-contact {
  margin-top: 158px;
}

@media (max-width: 1366px) {
  #recruitment .r-big-banner {
    width: 100%;
  }
}

@media (max-width: 1199px) {
  #recruitment .r-list-job .item .apply-now svg {
    width: 40px;
  }

  #recruitment .r-list-job .item .apply-now {
    font-size: 18px;
    line-height: 28px;
  }

  #recruitment .r-list-job .item ul li+li {
    margin-top: 15px;
  }

  #recruitment .r-list-job .item ul li span {
    font-size: 18px;
    line-height: 28px;
  }

  #recruitment .r-list-job .item .name .label {
    font-size: 20px;
    line-height: 30px;
    padding: 5px 25px;
  }

  #recruitment .r-list-job .item .name h4 {
    font-size: 28px;
    line-height: 38px;
  }

  #recruitment .r-list-job h3.title {
    font-size: 30px;
    line-height: 40px;
  }

  #recruitment .r-list-job .see-more {
    font-size: 18px;
    padding: 15px 33px 15px;
    width: 367px;
  }

  #recruitment .r-list-job .see-more svg {
    width: 40px;
  }

  #recruitment .r-page-title {
    margin-top: -89px;
  }

  #recruitment .r-page-title h3 {
    padding: 25px;
    font-size: 30px;
    line-height: 40px;
  }
}

@media (max-width: 991px) {
  #recruitment .r-big-banner .box .inner h3 {
    font-size: 20px;
    line-height: 30px;
    padding-right: 0;
  }
}

@media (max-width: 767px) {
  #recruitment .r-contact {
    margin-top: 50px;
  }

  #recruitment .r-list-job .see-more {
    margin-top: 30px;
  }

  #recruitment .r-list-job .item ul li+li {
    margin-top: 10px;
  }

  #recruitment .r-list-job .item .name {
    margin-bottom: 10px;
  }

  #recruitment .r-list-job .item ul li span {
    font-size: 14px;
    line-height: 24px;
  }

  #recruitment .r-list-job .item ul li .icon {
    margin-right: 10px;
  }

  #recruitment .r-list-job .item ul li .icon img {
    width: 25px;
  }

  #recruitment .r-list-job .item .apply-now svg {
    width: 25px;
    margin-left: 10px;
  }

  #recruitment .r-list-job .item .apply-now {
    position: static;
    margin-top: 15px;
    font-size: 14px;
    line-height: 24px;
  }

  #recruitment .r-list-job .item .name .label {
    font-size: 14px;
    line-height: 24px;
    padding: 3px 20px;
  }

  #recruitment .r-list-job .item .name h4 {
    font-size: 18px;
    line-height: 28px;
  }

  #recruitment .r-list-job h3.title {
    font-size: 18px;
    line-height: 28px;
  }

  #recruitment .r-list-job {
    margin-top: 40px;
  }

  #recruitment .r-page-title {
    margin-top: -48px;
  }

  #recruitment .r-page-title h3 {
    padding: 10px;
    font-size: 18px;
    line-height: 28px;
  }
}

#not-found .main-content .inner {
  text-align: center;
  padding: 131px 0;
}

#not-found .main-content .inner .image {
  margin-bottom: 32px;
}

#not-found .main-content .inner h3 {
  font-size: 40px;
  font-weight: 900;
  color: #0063aa;
  margin-bottom: 60px;
}

#not-found .main-content .inner a {
  border-radius: 5px;
  color: #fff;
  background-color: #0063aa;
  padding: 16px 23px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 242px;
  max-width: 100%;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 24px;
  margin-left: auto;
  margin-right: auto;
}

#not-found .main-content .inner a:hover {
  background-color: #06436f;
}

@media (max-width: 767px) {
  #not-found .main-content .inner .image img {
    width: 200px;
  }

  #not-found .main-content .inner h3 {
    font-size: 18px;
    margin-bottom: 40px;
  }

  #not-found .main-content .inner a {
    font-size: 12px;
    padding: 7.5px 17px;
  }

  #not-found .main-content .inner {
    padding: 70px 0;
  }
}

.r-contact {
  /* background: url('/assets/images/bg-contact.png'); */
  position: relative;
  padding: 165px 0 103px 0;
  z-index: 1;
}

@media screen and (min-width: 200px) and (max-width: 1199px) {
  .r-contact {
    padding-bottom: 88px;
  }
}

@media screen and (min-width: 200px) and (max-width: 767px) {
  .r-contact {
    margin-top: 0 !important;
    background: none;
  }

  .r-contact-mb {
    background: url('/assets/images//bg-contact-mb.png') no-repeat;
    background-size: cover;
    position: relative;
    padding: 165px 0 103px 0;
    margin: -100px 0;
  }
}

.r-contact::after {
  /* content: url('/assets/images/bg-contact1.png'); */
  width: calc(100% - 15px);
  height: auto;
  z-index: -1;
  top: 0;
  position: absolute;
}

@media screen and (min-width: 200px) and (max-width: 1770px) {
  .r-contact::after {
    width: 100%;
    object-fit: cover;
  }
}

@media screen and (min-width: 200px) and (max-width: 1199px) {
  .r-contact::after {
    display: none;
  }
}

.r-contact .title {
  margin-top: -69px;
}

.r-contact .title h3 {
  font-weight: 900;
  font-size: 48px;
  line-height: 56px;
  text-align: center;
  color: #ffffff;
  position: relative;
}

/* .r-contact .title h3::after {
    content: '';
    width: 48px;
    height: 4px;
    bottom: 9px;
    left: 50%;
    transform: translate(-50%);
    position: absolute;
    background: #ffffff;
    border-radius: 8px;
} */

@media screen and (min-width: 200px) and (max-width: 1199px) {
  .r-contact .title h3::after {
    width: 123px;
    height: 3px;
    border-radius: 42px;
    bottom: 26px;
  }
}

@media screen and (min-width: 200px) and (max-width: 1199px) {
  .r-contact .title h3 {
    font-size: 18px;
  }
}

.r-contact .title p {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: #ffffff;
  padding-bottom: 12px;
}

@media screen and (min-width: 200px) and (max-width: 1199px) {
  .r-contact .title p {
    font-size: 14px;
    line-height: 24px;
  }
}

.r-contact .btn-contact {
  /* text-align: center; */
  margin-top: 21px;
}

.r-contact .btn-contact a {
  padding: 30px 80px;
  background: #ffffff;
  border-radius: 200px;
  font-weight: 700;
  font-size: 32px;
  line-height: 24px;
  text-align: center;
  color: #0063aa;
  position: relative;
  display: inline-block;
  margin-left: 12px;
}

@media screen and (min-width: 200px) and (max-width: 1199px) {
  .r-contact .btn-contact a {
    font-size: 16px;
    line-height: 24px;
    padding: 8px 36px;
  }

  .r-contact .btn-contact a svg {
    width: 40px;
  }
}

@media screen and (min-width: 200px) and (max-width: 520px) {
  .r-contact .btn-contact a svg {
    width: 26px;
  }
}

.r-contact .btn-contact a span {
  padding-right: 44px;
}

@media screen and (min-width: 200px) and (max-width: 767px) {
  .r-contact .btn-contact a span {
    padding-right: 16px;
  }
}

#footer {
  background: url('/assets/img/slider/footer_bgg.svg') no-repeat center;
  /* background-color: #045189; */
  background-size: cover;
  max-height: 1112px;
  position: relative;
  /* z-index: -1; */
}

#footer>.container {
  max-width: 1200px;
}

#footer .container-fluid {
  padding: 0% 15.43%;
}

#footer .main {
  padding: 100px 0 50px;
}

#footer .main .header-footer {
  display: flex;
}

#footer .main .header-footer img {
  padding-right: 61px;
}

@media screen and (min-width: 200px) and (max-width: 1199px) {
  #footer .main .header-footer img {
    max-width: 221px;
  }
}

/* @media screen and (min-width: 200px) and (max-width: 767px) {
  #footer .main .header-footer img {
    width: 100%;
    max-width: 119px;
    padding-right: 0;
  }
} */

@media screen and (min-width: 200px) and (max-width: 1199px) {
  #footer .main .header-footer {
    display: block;
  }
}

#footer .main .header-footer p {
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
}

@media screen and (min-width: 200px) and (max-width: 1770px) {
  #footer .main .header-footer p {
    font-size: 18px;
  }
}

@media screen and (min-width: 200px) and (max-width: 1199px) {
  #footer .main .header-footer p {
    font-size: 16px;
  }
}

#footer .main .custom-margin {
  margin-top: -29px;
}

@media screen and (min-width: 200px) and (max-width: 1199px) {
  #footer .main .custom-margin {
    padding-top: 40px;
  }
}

@media (max-width: 991px) {
  #footer .main .col-social {
    margin-left: 0;
  }
}

#footer .main .col-service {
  padding-left: 3px;
}

#footer .main .col-menu {
  margin-left: -14px;
}

#footer .customMarginLeft-5 {
  margin-left: 5px;
}

@media screen and (min-width: 200px) and (max-width: 767px) {
  .owl-nav {
    margin-top: -18px;
    margin-left: 8px;
  }

  #footer .main .col-menu {
    margin-left: 0;
  }

  #footer .customMarginLeft-5 {
    margin-left: 0;
  }
}

#footer .main .policy {
  margin-top: 98px;
  white-space: nowrap;
  display: none;
}

#footer .main .policy a {
  color: #fff;
  font-size: 14px;
  display: inline-block;
  letter-spacing: 0.01em;
  text-decoration: underline;
  position: relative;
  z-index: 1;
}

#footer .main .policy a+a {
  margin-left: 48px;
}

#footer .main .social-link ul {
  display: flex;
}

#footer .main .social-link ul li {
  margin-right: 16px;
  margin-bottom: 16px;
}

#footer .main .logo {
  display: block;
  margin-bottom: 90px;
}

#footer .main h3.title {
  font-size: 24px;
  font-weight: 700;
  letter-spacing: -0.02em;
  margin-bottom: 40px;
}

#footer .main h3.title svg {
  margin-left: 15px;
}

#footer .main ul.link-list li {
  margin-bottom: 15px;
}

#footer .main ul li {
  /* display: flex; */
  font-size: 16px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.68);
  line-height: 25px;
  margin-bottom: 15px;
}

#footer .main ul li strong {
  font-weight: 500;
  color: #fff;
  letter-spacing: 0.01em;
  position: relative;
  opacity: 1;
}

#footer .main ul li a {
  font-size: 16px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.68);
  line-height: 25px;
  width: 100%;
  /* word-break: break-all;
  word-wrap: break-word; */
  display: block;
  /* white-space: unset; */
}

#footer .main ul li a:hover {
  color: #fff;
}

#footer .main ul li .icon {
  margin-right: 11px;
}

#footer .copyright {
  display: flex;
  align-items: center;
  width: 100%;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
}

#footer .copyright p {
  font-size: 16px;
  padding: 24px;
  margin-bottom: 0 !important;
  padding-right: 77px;
  text-align: start;
}

.copyright-right {
  width: 80%;
}

.copyright-left {
  width: 50%;
}

#footer .copyright p img {
  margin-left: 65px;
}

@media (max-width: 1199px) {
  #footer .copyright p {
    padding-right: 15px;
  }

  #footer .copyright p img {
    margin-left: 20px;
  }
}

@media (max-width: 575px) {
  #footer .copyright {
    text-align: center;
  }

  #footer .copyright p img {
    margin-left: 0;
    margin-top: 20px;
  }
}

#footer .social.d-none.d-md-block {
  padding-top: 80px;
}

#footer .social.d-none.d-md-block h3.title {
  margin-bottom: 24px;
}

#footer .customMarginLeftFooter {
  margin-left: 14px;
}

@media (max-width: 1199px) {
  #footer .customMarginLeftFooter {
    margin-left: 41px;
  }
}

@media (max-width: 991px) {
  #footer .customMarginLeftFooter {
    margin-left: 0px;
  }
}

#footer .customMarginLeftFooter ul li {
  display: block;
}

#footer .customMarginLeftFooter ul li .icon svg {
  margin-right: 13px;
}

#footer .customMarginLeftFooter ul li p {
  letter-spacing: 0.2px;
  margin-top: 16px;
  margin-bottom: 44px;
}

@media (max-width: 1440px) {
  #footer .logo img {
    max-width: 70%;
  }

  #footer .main .col-social,
  #footer .main .col-service,
  #footer .main .col-menu {
    padding-left: 7px;
  }

  #footer .main .logo {
    margin-bottom: 128px;
    height: 80px;
  }
}

@media (max-width: 1199px) {
  #footer .main {
    padding: 80px 50px;
  }

  #footer .main .logo {
    height: auto;
    margin-bottom: 60px;
  }

  .r-contact .title-left h3 {
    font-size: 30px !important;
    margin-bottom: 35px !important;
  }

  .r-contact .title-left .desc {
    font-size: 14px !important;
    line-height: 24px;
  }

  .r-contact .contact-box .phone {
    font-size: 28px;
    line-height: 38px;
  }

  .r-contact .contact-box .btn-contact {
    justify-content: center;
    /* width: 300px; */
    font-size: 18px;
    padding: 10px 12px;
  }

  .r-contact .contact-box .btn-contact img {
    /* margin-left: 25px; */
    margin-right: 10px;
  }

  #footer .social-mobile h3.title {
    display: none;
  }
}

@media (max-width: 991px) {
  .r-contact {
    padding: 100px 0;
  }
}

@media (max-width: 767px) {
  .r-contact .contact-box .phone svg {
    width: 20px;
  }

  .r-contact .contact-box .phone {
    font-size: 16px;
    line-height: 26px;
  }

  .r-contact {
    margin-top: 50px;
  }

  .r-contact .title-left .desc {
    font-size: 14px;
    line-height: 24px;
  }

  .r-contact .title-left h3::after {
    bottom: -4px !important;
    width: 14px !important;
    height: 1px !important;
  }

  .r-contact .title-left .desc {
    font-size: 14px !important;
    line-height: 24px;
  }

  .r-contact .contact-box {
    flex-direction: column;
    padding: 20px;
    border-radius: 7px;
  }

  .r-contact .title-left h3 {
    font-size: 14px !important;
  }

  .r-contact .title-left .desc {
    padding-right: 0 !important;
  }

  .r-contact .title-left h3 {
    margin-bottom: 8px !important;
  }

  .r-contact .contact-box .btn-contact {
    font-size: 14px;
    min-width: auto;
    padding: 7.5px 17px;
  }

  .r-contact .contact-box .btn-contact svg {
    width: 16px;
  }

  #footer .main {
    padding: 56px 0 36px;
  }

  #footer .main h3.title {
    font-size: 14px;
    margin-bottom: 13px;
  }

  #footer .main .social-link ul li {
    margin-right: 11px;
    margin-bottom: 11px;
  }

  #footer .main ul li a svg {
    width: 36px;
  }

  #footer .main .logo {
    margin-bottom: 38px;
  }

  #footer .main h3.title svg {
    margin-left: 5px;
    width: 22px;
  }

  #footer .main ul li,
  #footer .main ul li a {
    font-size: 14px;
    line-height: 24px;
  }

  #footer .main ul li .icon svg {
    width: 16px;
  }

  #footer .social-mobile h3.title {
    margin-bottom: 0;
  }

  /* #footer .logo img {
    width: 119px;
  } */
  #footer .container-fluid {
    padding: 0 25px;
  }

  #footer .main ul li {
    margin-bottom: 4px;
  }

  #footer .main .col-social {
    margin-top: 23px;
    margin-bottom: 20px;
  }

  #footer .main ul.link-list li {
    margin-bottom: 3px;
  }

  #footer .main .col-service .title,
  #footer .main .col-menu .title {
    margin-bottom: 20px;
  }

  #footer .main .policy {
    margin-top: 29px;
  }

  #footer .main .policy a {
    font-size: 16px;
    display: block;
  }

  #footer .main .policy a+a {
    margin-left: 0;
    margin-top: 16px;
  }
}

#detailrecruitment .detail-top {
  background-color: #e0f3ff;
  margin-top: 0px;
  padding: 78px 24px 60px;
}

@media (max-width: 1199px) {
  #detailrecruitment .detail-top {
    padding: 60px 0 60px;
  }
}

#detailrecruitment .detail-top-main .handleBtnBackNews a {
  display: flex;
  justify-content: start;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
}

#detailrecruitment .detail-top-main .handleBtnBackNews a:hover {
  margin-left: -10px;
  transition: 0.4s all;
}

#detailrecruitment .detail-top-main .handleBtnBackNews a span {
  color: #3e3c3c;
  display: inline-block;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  margin-left: 13px;
}

@media (max-width: 991px) {
  #detailrecruitment .detail-top-main .handleBtnBackNews a span {
    font-size: 16px;
  }
}

#detailrecruitment .detail-top-main .ttl {
  color: #0063aa;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 42px;
  margin-bottom: 44px;
  margin-top: 20px;
}

#detailrecruitment .detail-top-main .box-main ul {
  display: flex;
  list-style: none;
  align-items: center;
  padding: 0;
}

#detailrecruitment .detail-top-main .box-main ul li {
  display: flex;
  gap: 15px;
  justify-content: left;
  margin-right: 70px;
}

#detailrecruitment .detail-top-main .box-main ul li:last-of-type {
  margin-right: 0;
}

#detailrecruitment .detail-top-main .box-main ul li .t-content .t-ttl {
  color: #0063aa;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 23px;
  margin-bottom: 8px;
}

#detailrecruitment .detail-top-main .box-main ul li .t-content .t-txt {
  color: #878181;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
}

#detailrecruitment .detail-main {
  margin-top: 27px;
  margin-bottom: 60px;
}

#detailrecruitment .inner {
  color: #2e2e2e;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-right: 40px;
  margin-top: 12px;
}

#detailrecruitment .inner h5.title {
  font-size: 16px;
  font-weight: 700;
}

#detailrecruitment .inner ul {
  margin-left: 15px;
  font-size: 16px;
  line-height: 24px;
}

#detailrecruitment .menu-job {
  background: #fff;
  -webkit-box-shadow: 0 0 6px rgb(0 0 0 / 10%);
  box-shadow: 0 0 6px rgb(0 0 0 / 10%);
  padding: 30px 20px 40px 20px;
  border-radius: 6px;
}

@media (max-width: 767px) {
  #detailrecruitment .menu-job {
    margin-top: 20px;
    display: block !important;
  }
}

#detailrecruitment .menu-job .name-job h4.title {
  color: #0063aa;
  font-size: 20px;
  font-weight: 700;
  line-height: 23px;
  margin-right: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  gap: 20px;
}

#detailrecruitment .menu-job .name-job h4.title span {
  align-items: center;
  background: #ff6071;
  border-radius: 4px;
  color: #fff;
  display: flex;
  font-size: 8px;
  font-style: normal;
  font-weight: 700;
  height: 15px;
  justify-content: center;
  line-height: 9px;
  text-align: center;
  width: 29px;
  margin-top: 5px;
}

#detailrecruitment .menu-job .content {
  margin-top: 30px;
}

#detailrecruitment .menu-job .content p.decs span {
  display: block;
}

#detailrecruitment .menu-job .group-btn a.btn {
  align-items: center;
  color: #0063aa;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
  border: 3px solid #0063aa;
  border-radius: 8px;
  margin-bottom: 15px;
  font-size: 16px;
  font-weight: 900;
  padding: 15px 0;
}

#detailrecruitment .menu-job .group-btn a.btn:hover {
  background-color: #0063aa;
  color: #fff;
}

#detailrecruitment .menu-job .group-btn a.btn.btn-active {
  background: #0063aa;
  color: #fff;
}

#detailrecruitment .menu-job .group-btn a.btn.btn-active:hover {
  background: #034f85;
  border-color: #034f85;
}

#detailrecruitment .menu-job .group-btn a.btn.btn-active img {
  margin-right: 10px;
}

#detailrecruitment .share ul {
  display: flex;
  list-style: none;
  padding: 0;
  gap: 8px;
}

#detailrecruitment #form-apply {
  margin-top: 60px;
  background: #fff;
  border-radius: 4px;
  -webkit-box-shadow: 0 0 6px rgb(0 0 0 / 10%);
  box-shadow: 0 0 6px rgb(0 0 0 / 10%);
  padding: 28px 19px 39px 28px;
  border-radius: 6px;
  position: relative;
  display: none;
}

#detailrecruitment #form-apply .btnClose a {
  width: 18px;
  height: 18px;
  text-align: center;
  position: absolute;
  right: 20px;
  border-radius: 50%;
  top: 10px;
}

#detailrecruitment #form-apply h3.title {
  text-align: center;
  color: #0063aa;
  font-weight: bold;
  padding-top: 30px;
  padding-bottom: 30px;
}

#detailrecruitment #form-apply form input {
  height: 40px;
}

#detailrecruitment #form-apply form .btn {
  width: 100%;
  padding: 15px 0;
  border-radius: 5px;
  color: #fff;
  font-weight: bold;
}

#detailrecruitment .menu-job .content .skill ul {
  display: flex;
  list-style: none;
  padding: 0;
  flex-wrap: wrap;
  gap: 8px;
  margin-left: 0;
}

#detailrecruitment .menu-job .content .skill ul li {
  align-items: center;
  border: 1px solid #0063aa;
  border-radius: 16px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #0063aa;
  display: flex;
  margin-bottom: 5px;
  min-height: 22px;
  padding-left: 8px;
  padding-right: 8px;
}

@media (max-width: 1199px) {
  #detailrecruitment .inner {
    margin-right: 0px !important;
  }

  #detailrecruitment .detail-top-main .box-main ul li {
    margin-right: 29px;
  }

  #detailrecruitment .map {
    font-size: 14px;
  }

  #detailrecruitment .map strong {
    font-size: 16px;
  }

  #detailrecruitment .web,
  #detailrecruitment .page,
  #detailrecruitment .apply {
    font-size: 14px;
  }

  #detailrecruitment .web strong,
  #detailrecruitment .page strong,
  #detailrecruitment .apply strong {
    font-size: 16px;
  }

  #detailrecruitment .detail-top-main .ttl {
    font-size: 20px;
    margin-bottom: 24px;
  }

  #detailrecruitment .detail-top-main .back a span {
    font-size: 14px;
  }

  #detailrecruitment .detail-top-main .back a img {
    width: 20px;
  }

  #detailrecruitment .detail-main .list-type p {
    font-size: 14px !important;
  }

  #detailrecruitment .detail-main .list-type p>b {
    font-size: 16px !important;
  }
}

@media (max-width: 991px) {
  #detailrecruitment .detail-top {
    padding-left: 3px;
  }

  #detailrecruitment .menu-job .group-btn a.btn {
    padding: 10px 0;
  }

  #detailrecruitment .detail-top-main .box-main ul li .t-content .t-ttl {
    font-size: 14px;
  }

  #detailrecruitment .detail-top-main .box-main ul li .t-content .t-txt {
    font-size: 12px;
  }
}

@media (max-width: 767px) {
  #detailrecruitment .inner ul {
    font-size: 14px;
  }

  /* #detailrecruitment .menu-job {
    display: none;
  } */
}

@media (max-width: 575px) {
  #detailrecruitment .detail-top-main .box-main ul li .t-icon {
    display: none;
  }

  #detailrecruitment .inner ul {
    margin-left: 0;
  }
}

/* @media (min-width: 768px) {
  #detailrecruitment .container {
      max-width: 820px;
  }
} */

/*# sourceMappingURL=style.css.map */

/* video youtube */

@media (min-width: 767.5px) {
  .btn-start {
    cursor: pointer;
  }
}

.c-lightbox-video {
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  visibility: visible;
  width: 100%;
  z-index: 10000;
}

.c-lightbox-video__transaction {
  background: rgba(0, 0, 0, 0.8);
  bottom: 0;
  height: 100%;
  left: 0;
  position: fixed;
  width: 100%;
  z-index: 10;
}

.c-lightbox-video__inner {
  background-color: #fff;
  -webkit-box-shadow: 0 0 1.5em rgb(0 0 0 / 35%);
  box-shadow: 0 0 1.5em rgb(0 0 0 / 35%);
  height: 570px;
  left: 50%;
  max-width: calc(100% - 30px);
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%) !important;
  transform: translate(-50%, -50%) !important;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  width: 1055px;
  z-index: 9999;
}

/* #home .r-office-tcom .col-left .btn-start {
  border: 2px solid #0063aa;
  filter: drop-shadow(0px 4px 41px rgba(189, 189, 189, 0.25));
  border-radius: 54px;
  padding: 12px 20px 12px 20px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 20px;
  line-height: 38px;
  color: #0063aa;
  background-color: #fff;
} */

/* #home .r-archivement .title-center p {
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
} */

#project .r-tag h3 {
  font-weight: 400;
}

/* vinh */

.content-news {
  overflow: hidden !important;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  font-size: 16px;
}

#news .r-top .title-left .handleBtnClose a {
  background: #e2e2e2;
  border-radius: 55px;
  color: #0063aa;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  padding: 4px 24px;
  position: relative;
}

#news .r-top .title-left .handleBtnClose a:hover {
  background: #0063aa;
  color: #fff;
  transition: 0.4s all;
}

#news .r-top .title-left .handleBtnClose a::before {
  content: url('/assets/images/news/bi_x-circle-fill.svg');
  position: absolute;
  top: -4px;
  right: -11px;
}

#news .r-top .title-left .handleBtnClose::before {
  content: '';
  position: absolute;
  height: 25px;
  width: 1px;
  left: -25px;
  background: #a7a7a7;
  transform: translateY(-50%);
  top: 50%;
}

#news .r-top .title-left h3::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -11px;
  width: 48px;
  height: 4px;
  background-color: #0063aa;
  z-index: 1;
}

#news .r-top .title-left .handleBtnClose {
  position: relative;
}

#news .r-top .title-left .category {
  position: relative;
  margin-left: 54px;
  padding-top: 10px;
}

#news .r-top .title-left .category::before {
  content: '';
  width: 1px;
  height: 24px;
  background: #a7a7a7;
  position: absolute;
  left: -25px;
  top: 9px;
}

#news .r-top .title-left .handleBtnClose {
  margin-left: 50px;
  margin-top: 12px;
}

.category,
.see-more,
.tag,
.title,
.url-hover {
  cursor: pointer;
}

#service .sidebar {
  position: fixed;
  width: calc((100% - 1110px) / 2);
  top: 0;
  left: -50px;
  z-index: 9;
  /* padding: 290px 20px 20px 122px; */
}

#service .sidebar.off-fixed {
  opacity: 0;
  visibility: hidden;
}

#service .sidebar ul {
  padding: 0;
  position: relative;
}

#service .sidebar ul::before {
  content: '';
  width: 1px;
  height: 100%;
  background-color: #e3e3e3;
  position: absolute;
  left: -13px;
  top: 0;
  z-index: 1;
}

#service .sidebar ul li {
  margin-bottom: 11px;
  position: relative;
}

#service .sidebar ul li::before {
  content: '';
  width: 8px;
  height: 8px;
  display: block;
  border-radius: 50%;
  background-color: #0063aa;
  position: absolute;
  left: -16px;
  top: 5px;
  opacity: 0;
  z-index: 2;
}

#service .sidebar ul li.active::before {
  opacity: 1;
}

#service .sidebar ul li.active a {
  font-size: 18px;
  font-weight: 700;
  color: #0063aa;
}

#service .sidebar ul li a {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #d0d0d0;
  display: inline-block;
}

@media (min-width: 1441px) {
  #service .sidebar ul li a:hover {
    color: #0063aa;
  }
}

@media (min-width: 1601px) {
  #solution .sidebar ul li a:hover {
    color: #0063aa;
  }
}

#service .main-content {
  margin-top: 100px;
  margin-bottom: 44px;
  overflow: hidden;
}

#service .page-title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  font-size: 40px;
  line-height: 47px;
  /* identical to box height */

  color: #0063aa;
}

#service .page-title::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -21px;
  width: 48px;
  height: 4px;
  background-color: #0063aa;
  z-index: 1;
}

#service .r-service-box {
  overflow: hidden;
}

#service .r-service-box .box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-bottom: 260px;
  position: relative;
}

#service #offshore {

  padding-bottom: 0;

}

#service .r-service-box .box::before {
  content: '';
  height: calc(100% - 0px);
  width: 1px;
  background-color: #0063aa;
  position: absolute;
  top: 60px;
  left: 35px;
  z-index: -1;
}

#service .r-service-box .box .col-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-right: 30px;
  width: calc(100% - 570px);
  padding-top: 47px;
}

#service .r-service-box .box .col-info .icon {
  margin-right: 24px;
  margin-top: 13px;
  width: 68px;
  height: 68px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #0063aa;
  min-width: 68px;
  border-radius: 50%;
  background-color: #fff;
}

#service .r-service-box .box .col-info .icon img {
  width: 70%;
}

#service .r-service-box .box .col-info .content h3 {
  font-weight: 900;
  font-size: 40px;
  line-height: 47px;
  color: #0063aa;
  position: relative;
  padding-left: 23px;
  min-height: 94px;
  display: flex;
  align-items: center;
}

#service .r-service-box .box .col-info .content h3::before {
  content: '';
  width: 7px;
  height: 77px;
  background-color: #0063aa;
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

#service .r-service-box .box .col-info .content p {
  font-size: 24px;
  line-height: 32px;
  color: #454545;
  padding-left: 28px;
}

#service .r-service-box .box .col-info .content .readmore {
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #0063aa;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-line-pack: center;
  align-content: center;
  margin-left: 30px;
  margin-top: 25px;
}

#service .r-service-box .box .col-info .content .readmore svg {
  margin-left: 19px;
}

#service .r-service-box .box .col-image {
  width: 540px;
}

#service .r-service-box .box .col-image img {
  width: 100%;
}

#service .sidebar h3.title,
#service .sidebar .js-open-sidebar {
  display: none;
}

@media (max-width: 1800px) {
  #service .sidebar {
    padding-left: 50px;
    padding-top: 0;
    padding-bottom: 0;
    top: 50%;
    left: 0;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}

.col-md-12.customImgMb {
  text-align: center;
}

@media (max-width: 1440px) {
  #service .sidebar {
    padding: 0;
    -webkit-transform: none;
    transform: none;
    width: auto;
  }

  #service .sidebar .js-open-sidebar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 24px;
    font-weight: 500;
    color: #0063aa;
    background: #ffffff;
    -webkit-box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    padding: 16.4px 20px;
    width: 171px;
    white-space: nowrap;
  }

  #service .sidebar .js-open-sidebar svg {
    margin-right: 9px;
  }

  #service .sidebar h3.title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  #service .sidebar .box.open {
    display: block;
  }

  #service .sidebar .box {
    display: none;
    position: absolute;
    left: 100%;
    background: #fff;
    width: 283px;
    -webkit-box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    padding: 16px 39px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  #service .sidebar .box h3.title {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #0063aa;
    display: block;
    margin-bottom: 27px;
  }
}

@media (max-width: 767px) {
  #service .sidebar .js-open-sidebar svg {
    margin-right: 0;
  }

  #service .sidebar .js-open-sidebar {
    font-size: 0;
    width: auto;
  }

  #service .sidebar .js-open-sidebar {
    font-size: 0;
    width: auto;
  }

  .customImgMb {
    display: none;
  }
}

@media (max-width: 1199px) {
  #service .r-service-box .box .col-image {
    width: 440px;
  }

  #service .r-service-box .box .col-info {
    padding-top: 0;
    width: calc(100% - 470px);
  }

  #service .r-service-box .box {
    padding-bottom: 100px;
  }
}

@media (max-width: 991px) {
  #service .r-service-box .box .col-info .content p {
    font-size: 16px;
    line-height: 24px;
  }

  #service .r-service-box .box .col-info .content h3 {
    font-size: 28px;
  }

  #service .page-title {
    font-size: 30px;
  }

  #service .page-title br {
    display: none;
  }

  #service .r-service-box .box {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  #service .r-service-box .box .col-info {
    width: 100%;
  }

  #service .r-service-box .box .col-image {
    width: 100%;
    padding-left: 120px;
    margin-top: 30px;
  }
}

@media (max-width: 767px) {
  #service .page-title {
    font-size: 18px;
    line-height: 28px;
    margin-top: 40px;
  }

  #service .page-title::after {
    bottom: -6px;
    width: 27px;
    height: 1px;
  }

  #service .r-service-box .box .col-info .content h3 {
    font-size: 18px;
    line-height: 28px;
    padding-left: 15px;
  }

  #service .r-service-box .box .col-info .content h3::before {
    width: 3px;
    height: 50px;
  }

  #service .r-service-box .box .col-info .icon {
    margin-right: 15px;
    margin-top: 0;
  }

  #service .r-service-box .box .col-info .content p {
    font-size: 14px;
    padding-left: 15px;
  }

  #service .r-service-box .box .col-info .content .readmore {
    font-size: 14px;
    line-height: 24px;
    margin-left: 15px;
    margin-top: 0;
  }

  #service .r-service-box .box .col-image {
    padding-left: 80px;
  }

  #service .r-service-box .box {
    padding-bottom: 50px;
  }

  #web-development .r-big-banner h1 {
    text-align: center;
  }

  #web-development .r-big-banner h1::after {
    display: none;
  }

  #web-development .r-big-banner .desc {
    text-align: center;
    width: auto !important;
  }
}

#web-development.Realtime.Solutions .r-big-banner .feature,
#web-development.Reseach .r-big-banner .feature,
#web-development .r-big-banner .feature {
  -webkit-columns: 2;
  /* max-width: 490px; */
}

#web-development .r-big-banner .customColumn {
  -webkit-columns: auto;
  /* max-width: 490px; */
}

#web-development .title-left {
  margin-bottom: 58px;
}

#web-development .title-left h3 {
  font-size: 48px;
  font-weight: 700;
  color: #0063aa;
  position: relative;
  margin-bottom: 35px;
}

#web-development .title-left h3::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -11px;
  width: 48px;
  height: 4px;
  background-color: #0063aa;
  z-index: 1;
}

#web-development .title-left .desc {
  font-size: 18px;
  color: #686868;
}

#web-development .title-center {
  margin-bottom: 58px;
  text-align: center;
}

#web-development .title-center h3 {
  font-size: 48px;
  font-weight: 700;
  color: #0063aa;
  position: relative;
  margin-bottom: 29px;
  text-transform: uppercase;
}

#web-development .title-center h3::after {
  content: '';
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: -11px;
  width: 48px;
  height: 4px;
  background-color: #0063aa;
  z-index: 1;
}

#web-development .title-center .desc {
  font-size: 20px;
  color: #686868;
}

#web-development.Development .r-big-banner {
  padding: 200px 0;
}

#web-development.Mobile .r-big-banner {
  padding: 230px 0;
}

#web-development.Mobile .r-big-banner>.items-service .customImgMb img {
  /* padding-top: 100px; */
}

#web-development.Development .r-big-banner>.items-service .customImgMb img {
  /* padding-top: 100px; */
}

#web-development.Video.Solutions .r-big-banner>.items-service .customImgMb img {
  /* padding-top: 100px; */
}

#web-development.Realtime.Solutions .r-big-banner>.items-service .customImgMb img {
  /* padding-top: 100px; */
}

#web-development.Design .r-big-banner>.items-service .customImgMb img {
  /* padding-top: 100px; */
}

#web-development.Blockchain .r-big-banner>.items-service .customImgMb img {
  /* padding-top: 100px; */
}

#web-development.Testing .r-big-banner>.items-service .customImgMb img {
  /* padding-top: 100px; */
}

.box {
  display: flex;
  align-items: center;
}

#web-development .r-big-banner .customColumn {
  margin-top: 55px;
}

#web-development .r-big-banner .customColumn .box .icon {
  width: 36px;
  height: 36px;
  /* background: rgba(0, 99, 170, 0.05); */
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#web-development .r-big-banner .customColumn .box+.box {
  margin-top: 39px;
}

#web-development .r-big-banner .customColumn .box p {
  font-size: 16px;
  line-height: 20px;
  color: #121212;
  margin-bottom: 0;
  width: 234px;
  margin-left: 15px;
}

#web-development .r-big-banner {
  padding: 230px 0;
  background-image: url('/assets/images/service/bg-bigbaner.png');
  background-size: cover;
  margin-top: -75px;
  background-position: center;
  background-repeat: no-repeat;
}

#web-development.ブロックチェーン開発 .r-big-banner {
  padding-bottom: 298px;
}

#web-development.モバイルアプリ開発 .r-big-banner {
  padding-bottom: 372px;
}

.imgBgMB {
  display: none;
}

@media (max-width: 767px) {
  #web-development.モバイルアプリ開発 .r-big-banner {
    padding-bottom: 0px;
  }

  #web-development.ブロックチェーン開発 .r-big-banner {
    padding-bottom: 2%;
  }

  .imgBgMB {
    display: block !important;
    margin-top: 10px;
  }

  .imgBgMB img {
    width: 100%;
  }
}

#web-development .r-big-banner h1 {
  font-size: 40px;
  font-weight: 900;
  color: #121212;
  position: relative;
  margin-bottom: 37px;
}

#web-development .r-big-banner h1::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -11px;
  width: 48px;
  height: 4px;
  background-color: #0063aa;
  z-index: 1;
}

#web-development .r-big-banner .desc {
  color: #121212;
  line-height: 24px;
  margin-bottom: 27px;
  width: 448px;
  max-width: 100%;
  font-size: 20px;
}

#web-development .r-big-banner .feature {
  margin-top: 81px;
}

#web-development .r-big-banner .feature .box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
}

#web-development .r-big-banner .feature .box+.box {
  margin-top: 39px;
}

#web-development .r-big-banner .feature .box .icon {
  width: 36px;
  height: 36px;
  /* background: rgba(0, 99, 170, 0.05); */
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

#web-development .r-big-banner .feature .box p {
  font-size: 14px;
  line-height: 20px;
  color: #121212;
  margin-bottom: 0;
  width: 234px;
  margin-left: 15px;
}

#web-development.Reseach .r-big-banner .feature .box p {
  min-height: 60px;
  display: flex;
  align-items: center;
}

#web-development.Reseach .r-big-banner {
  padding: 230px 0;
}

#web-development .r-strengths {
  margin-top: 41px;
}

@media (max-width: 390px) {
  #web-development .r-strengths {
    margin-top: -40px;
  }

  #web-development.モバイルアプリ開発 .r-strengths {
    margin-top: 15px;
  }
}

#web-development .r-strengths .title {
  font-weight: 700;
  font-size: 40px;
  line-height: 56px;
  text-transform: uppercase;
  color: #0063aa;
  position: relative;
  padding-left: 22px;
  margin-top: 23px;
}

#web-development .r-strengths .title::before {
  content: '';
  width: 6px;
  height: calc(100% - 8px);
  position: absolute;
  left: 0;
  top: 2px;
  background-color: #0063aa;
}

#web-development .r-strengths .col-right .col-12 {
  margin: 0 10px;
  max-width: 332px;
  margin-bottom: 21px;
}

#web-development .r-strengths .box {
  width: 331px;
  padding: 25px 26px;
  background: url('/assets/images/web-development/strength-box-bg.png') no-repeat center;
  background-size: cover;
  -webkit-box-shadow: 0px 8px 19px rgba(189, 189, 189, 0.25);
  box-shadow: 0px 8px 19px rgba(189, 189, 189, 0.25);
  border-radius: 15px;
  height: 100%;
  margin: auto;
  min-height: 177px;
}

#web-development .r-strengths .box p {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #121212;
  background: transparent;
  word-break: break-word;
}

#web-development .r-strengths .box>pre {
  background: transparent !important;
  border: none !important;
}

#web-development .r-technology {
  margin-top: 167px;
  position: relative;
}

#web-development .r-technology .container-fluid {
  width: 1740px;
  max-width: 100%;
}

#web-development .r-technology .title {
  font-weight: 700;
  font-size: 40px;
  line-height: 56.25px;
  text-align: center;
  text-transform: uppercase;
  color: #0063aa;
  margin-bottom: 98px;
}

#web-development .r-technology ul {
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}

#web-development .r-technology ul li {
  padding: 20px 38px;
  margin-bottom: 27px;
}

#web-development .r-technology ul li .icon {
  height: 61px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

#web-development .r-technology ul li .name {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #121212;
  margin-top: 10px;
}

@media (max-width: 390px) {
  #web-development .r-technology ul li .name {
    font-size: 14px;
  }
}

#web-development .r-step {
  margin-top: 183px;
  padding: 79px 0 227px;
  position: relative;
}

#web-development .r-step::before {
  content: url('/assets/images/offshore/step-bg.png');
  position: absolute;
  bottom: -14px;
  left: -8px;
  z-index: 2;
}

#web-development .r-step .title-center {
  margin-bottom: 125px;
}

#web-development .r-step .title-center h3 {
  margin-bottom: 23px;
}

#web-development .r-step .title-center .desc {
  font-size: 18px;
  color: #494949;
}

#web-development .r-step .slick-slider {
  padding-right: 65px;
}

#web-development .r-step .slick-slider::before {
  content: '';
  position: absolute;
  right: 100%;
  top: 0;
  width: 10000%;
  height: 100%;
  background-color: #fff;
  z-index: 1;
}

#web-development .r-step .slick-slider .owl-stage-outer {
  overflow: visible;
}

#web-development .r-step .slick-slider .owl-stage {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

#web-development .r-step .slick-slider .item {
  padding: 31px 15px 12px 24px;
  color: #fff;
  background: #0063aa;
  -webkit-box-shadow: 0px 4px 9px rgba(189, 189, 189, 0.45);
  box-shadow: 0px 4px 9px rgba(189, 189, 189, 0.45);
  border-radius: 10px;
  height: 100%;
}

#web-development .r-step .slick-slider .item .top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 55px;
}

#web-development .r-step .slick-slider .item .top .number {
  font-size: 48px;
  font-weight: 900;
  line-height: 1;
}

#web-development .r-step .slick-slider .item .top .text {
  font-size: 48px;
  font-weight: 900;
  -webkit-text-stroke-width: 0.3px;
  -webkit-text-stroke-color: #fff;
  color: transparent;
  text-transform: uppercase;
  line-height: 1;
}

#web-development .r-step .slick-slider .item h3 {
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 14px;
}

#web-development .r-step .slick-slider .item p {
  font-size: 14px;
  line-height: 16px;
  color: #e2e2e2;
}

#web-development .r-fields {
  margin-top: 96px;
  margin-bottom: 0px;
  position: relative;
}

/* #web-development .r-fields::after {
  content: url("/assets/images/home/fields-img-2.png");
  position: absolute;
  right: 100px;
  top: -92px;
  z-index: 1;
} */

#web-development .r-fields .container::before {
  content: url('/assets/images/home/fields-img-4.png');
  position: absolute;
  right: calc(100% + 23px);
  top: 74px;
  z-index: 2;
  display: none;
}

#web-development .r-fields .col-left .title-left h3 {
  margin-bottom: 19px;
}

#web-development .r-fields .col-left .title-left .desc {
  margin-right: -1px;
  color: #676767;
  font-size: 20px;
}

#web-development .r-fields .col-left img {
  display: block;
  margin: -4px auto 0;
}

#web-development .r-fields .col-right {
  margin-top: 11px;
}

#web-development .r-fields .field-item {
  margin-bottom: 19px;
  padding-left: 62px;
}

#web-development .r-fields .field-item .icon {
  height: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  margin-bottom: 15px;
}

#web-development .r-fields .field-item h3 {
  color: #646464;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 0;
  min-height: 40px;
}

@media (min-width: 1200px) {
  #web-development .r-fields .col-left {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 27%;
    flex: 0 0 27%;
    max-width: 27%;
  }

  #web-development .r-fields .col-right {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 73%;
    flex: 0 0 73%;
    max-width: 73%;
  }
}

@media (max-width: 1800px) {
  #web-development .r-step::before {
    display: none;
  }
}

@media (max-width: 1199px) {
  #web-development .r-big-banner {
    margin-top: 0;
  }

  #contact .r-contact-form .info .block .content {
    color: #000;
    white-space: unset;
  }

  #web-development .r-fields::before,
  #web-development .r-fields::after {
    display: none;
  }

  #web-development .r-fields .field-item {
    padding-left: 0;
  }

  #web-development .r-fields .col-left img {
    display: none;
  }
}

@media (max-width: 991px) {
  #web-development .r-strengths .title {
    font-size: 30px;
    line-height: 38px;
  }

  #web-development .r-big-banner {
    padding: 64px 0 100px;
  }

  #web-development .r-technology {
    margin-top: 50px;
  }

  #web-development .r-technology ul li {
    padding: 10px 30px;
    margin-bottom: 20px;
  }

  #web-development .r-technology::before,
  #web-development .r-technology::after {
    display: none;
  }

  #web-development .r-technology .title {
    font-size: 30px;
    margin-bottom: 20px;
  }

  #web-development .r-big-banner h1 {
    font-size: 30px;
  }

  #web-development .title-left h3,
  #web-development .title-center h3 {
    font-size: 30px;
  }

  #web-development .r-step .slick-slider .item .top .text,
  #web-development .r-step .slick-slider .item .top .number {
    font-size: 30px;
  }

  #web-development .r-step {
    padding-bottom: 70px;
    margin-top: 0;
  }

  #web-development .r-step .title-center {
    margin-bottom: 40px;
  }

  #web-development .r-fields {
    margin-top: 60px;
    margin-bottom: 50px;
  }
}

@media (max-width: 767px) {
  #web-development .r-technology ul li {
    padding: 10px 18px;
    margin-bottom: 15px;
  }

  #web-development .r-technology .title {
    font-size: 18px;
  }

  /* #web-development .r-strengths .box {
    min-height: auto;
  } */
  #web-development .r-strengths .title {
    font-size: 18px;
    line-height: 28px;
  }

  #web-development .r-big-banner {
    padding: 40px 0 40px;
  }

  #web-development .r-big-banner {
    background: transparent;
    background-image: none !important;
  }

  #web-development .r-big-banner .feature {
    margin-top: 35px;
  }

  #web-development.Development .r-big-banner {
    padding: 0px 0 40px;
  }

  #web-development .r-big-banner h1 {
    font-size: 20px;
    color: #0063aa;
    margin-bottom: 21px;
  }

  #web-development .r-fields {
    margin-top: 30px;
  }

  #web-development .r-fields .col-left .title-left h3 {
    margin-bottom: 13px;
  }

  #web-development .r-fields .col-left .title-left .desc {
    padding-right: 0;
    font-size: 14px;
  }

  #web-development .r-fields .field-item {
    margin-bottom: 3px;
  }

  #web-development .r-fields .field-item>svg {
    width: 79px;
    margin-top: -30px;
  }

  #web-development .r-fields .field-item>svg rect {
    height: 1.62px;
  }

  #web-development .r-fields .field-item .icon img {
    width: 40px !important;
  }

  #web-development .r-fields .field-item .icon svg {
    width: 40px;
  }

  #web-development .r-fields .field-item h3 {
    font-size: 14px;
  }

  #web-development .r-fields .title-left {
    margin-bottom: -8px;
  }

  #web-development .r-fields .read-more {
    margin-top: 3px;
    margin-right: -6px;
  }

  #web-development .title-left h3,
  #web-development .title-center h3 {
    font-size: 18px;
  }

  #web-development .title-left .desc,
  #web-development .title-center .desc {
    font-size: 14px;
  }

  #web-development .r-step .slick-slider {
    padding-right: 0;
  }

  #web-development .r-step {
    padding: 50px 0 227px;
  }

  #web-development .r-step .title-center {
    margin-bottom: 40px;
  }

  #web-development .r-step .title-center .desc {
    font-size: 14px;
  }

  #web-development .r-step .slick-slider .item h3 {
    font-size: 18px;
    line-height: 28px;
  }

  #web-development .r-step {
    padding-bottom: 40px;
  }

  #web-development .r-step .slick-slider .item .top {
    margin-bottom: 15px;
  }

  #web-development .r-step .slick-slider .item .top .text,
  #web-development .r-step .slick-slider .item .top .number {
    font-size: 18px;
  }
}

@media (max-width: 575px) {
  #web-development .r-technology ul li {
    width: 33.33333%;
  }
}

.r-process {
  margin-top: 169px;
  padding-bottom: 102px;
  background: #0063aa;
}

@media (max-width: 1199px) {
  .r-process {
    margin-top: 50px;
  }
}

@media (max-width: 390px) {
  .r-process {
    margin-top: 70px;
  }
}

.r-process .col-left {
  padding-top: 91px;
  position: relative;
  height: calc(100% + 102px);
}

@media screen and (min-width: 200px) and (max-width: 767px) {
  .r-process .col-left {
    padding-top: 20px;
  }
}

.r-process .col-left h3 {
  padding-right: 15px;
  text-align: end;
  font-weight: 700;
  font-size: 40px;
  line-height: 47px;
  text-transform: uppercase;
  color: #ffffff;
  position: absolute;
  right: -20%;
  text-align: start;
  width: 100%;
}

.r-process .col-left p {
  width: 100%;
  position: absolute;
  right: -20%;
  text-align: start !important;
  top: 140px;
}

#offshore .r-process .col-left p {

  position: absolute;
  right: -20%;
  top: 15%;
  text-align: start !important;
}

@media screen and (min-width: 200px) and (max-width: 1199px) {
  .r-process .col-left h3 {
    text-align: start;
    font-size: 30px;
  }
}

@media screen and (min-width: 200px) and (max-width: 767px) {
  .r-process .col-left h3 {
    font-size: 18px;
  }
}

.r-process .col-left h3::after {
  content: '';
  width: 48px;
  height: 4px;
  background: #ffffff;
  position: absolute;
  bottom: -19px;
  left: 0;
  border-radius: 8px;
}

@media screen and (min-width: 200px) and (max-width: 767px) {
  .r-process .col-left h3::after {
    left: 0;
    width: 35px;
    bottom: 2px;
  }
}

.r-process .col-left p {
  text-align: end;
  padding-top: 25px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}

@media (max-width: 1199px) {
  .r-process .col-left p {
    text-align: start;
    font-size: 14px;
    line-height: 24px;
  }
}

.r-process .col-left .subtract-2 {
  position: absolute;
  top: 0;
  left: -215px;
}

@media screen and (min-width: 200px) and (max-width: 1200px) {
  .r-process .col-left .subtract-2 {
    display: none;
  }
}

.r-process .col-left .subtract {
  position: absolute;
  bottom: 0;
  left: -310px;
  /* top: 325px; */
}

.customWidthSubtract img {
  width: 85%;
}

@media screen and (min-width: 200px) and (max-width: 1200px) {
  .r-process .col-left .subtract {
    display: none;
  }
}

/* @media screen and (min-width: 200px) and (max-width: 767px) {
    .r-process .col-right {
        padding-left: 15px;
    }
} */

.r-process .col-right ul {
  padding-top: 85px;
}

.r-process .col-right ul::before {
  content: '';
  width: 1px;
  height: calc(100% + 73px);
  position: absolute;
  left: 76px;
  z-index: 1;
  top: 0;
  background: #fff;
}

@media screen and (min-width: 200px) and (max-width: 767px) {
  .r-process .col-right ul::before {
    left: 48px;
  }
}

.r-process .col-right ul li {
  position: relative;
  color: #ffffff;
  margin-bottom: calc(69px + 1rem);
  display: flex;
  transition: 0.5s;
  cursor: pointer;
}

.r-process .col-right ul li:last-of-type {
  margin-bottom: 0;
}

.r-process .col-right ul li::before {
  content: '';
  background: rgba(255, 255, 255, 0.1);
  border-radius: 1400px 1991.68px 1991.68px 1400px;
  opacity: 0;
  width: 648px;
  height: 125px;
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
}

@media screen and (min-width: 200px) and (max-width: 1199px) {
  .r-process .col-right ul li::before {
    width: 550px;
  }
}

@media screen and (min-width: 200px) and (max-width: 991px) {
  .r-process .col-right ul li::before {
    width: calc(100% + 10px);
    height: 90px;
  }
}

@media screen and (min-width: 200px) and (max-width: 767px) {
  .r-process .col-right ul li::before {
    width: calc(100% + 24px);
  }
}

/* @media (min-width: 768px) {
    .r-process .col-right ul li:hover::before {
        opacity: 1;
    }

    .r-process .col-right ul li:hover .icon {
        transform: scale(1.9);
        margin-top: 0;
    }
} */

/* @media screen and (min-width: 200px) and (max-width: 390px) {
  .r-process .col-right ul li:hover {
    opacity: 0;
  }
  .r-process .col-right ul li:hover::before {
    opacity: 0;
  }
} */

/* @media screen and (min-width: 768px) and (max-width: 991px) {
    .r-process .col-right ul li:hover .icon {
        transform: scale(1.3);
    }
} */

/*@media (min-width: 768px) {*/
/*    .r-process .col-right ul li:hover .content {*/
/*        padding-left: 30px;*/
/*    }*/
/*}*/

/*@media screen and (min-width: 200px) and (max-width: 767px) {*/
/*  .r-process .col-right ul li:hover .content {*/
/*    padding-left: 19px;*/
/*  }*/
/*}*/

/* @media (min-width: 768px) {
    .r-process .col-right ul li:hover .content p {
        padding-right: 52px;
        font-size: 87px;
        transition: 0.4s;
    }
} */

/* @media screen and (min-width: 768px) and (max-width: 1199px) {
    .r-process .col-right ul li:hover .content p {
        font-size: 70px;
    }
} */

/* @media screen and (min-width: 200px) and (max-width: 767px) {
  .r-process .col-right ul li:hover .content p {
    font-size: 40px;
    padding-right: 32px;
  }
} */

/* @media screen and (min-width: 200px) and (max-width: 390px) {
  .r-process .col-right ul li:hover .content p {
    font-size: 35px;
    padding-right: 21px;
  }
} */

@media (max-width: 768px) {
  .r-process .col-right {
    padding-left: 5%;
  }
}

@media (min-width: 768px) {
  .r-process .col-right {
    padding-left: 45px;
    position: relative;
    padding-bottom: 1px;
    margin-bottom: -28px;
  }
}

@media (min-width: 1024px) {
  .r-process .col-right ul li:hover .content p {
    padding-right: 52px;
    font-size: 87px;
    transition: 0.4s;
  }

  .r-process .col-right ul li:hover .content .text strong {
    transition: 0.3s;
    font-size: 32px;
  }

  .r-process .col-right ul li:hover::before {
    opacity: 1;
  }

  .r-process .col-right ul li:hover::after {
    opacity: 1;
  }

  .r-process .col-right ul li:hover .icon {
    transform: scale(1.9);
    margin-top: 0px;
  }
}

/* @media (min-width: 768px) {
    .r-process .col-right ul li:hover .content .text strong {
        transition: 0.5s;
        font-size: 32px;
        margin-bottom: 7px;
        line-height: 33px;
        white-space: nowrap;
    }
} */

@media screen and (min-width: 200px) and (max-width: 767px) {
  .r-process .col-right ul li:hover .content .text strong {
    font-size: 16px;
    /* line-height: 20px; */
  }
}

/* @media (min-width: 768px) {
    .r-process .col-right ul li:hover .content .text span {
        transition: 0.5s;
        bottom: -6px;
        font-size: 16px;
        line-height: 19px;
    }
} */

/* .r-process .col-right ul li:hover .content p::before {
  top: 1px;
} */

/* @media screen and (min-width: 200px) and (max-width: 767px) {
  .r-process .col-right ul li:hover .content .text span {
    font-size: 10px;
  }
  .r-process .col-right ul li:hover .content .text span {
    bottom: 5px;
  }
} */

.r-process .col-right ul li::after {
  content: '';
  width: 3px;
  height: 125px;
  background-color: #fff;
  position: absolute;
  left: -63px;
  opacity: 0;
  transition: 0.5s;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 5px;
}

@media screen and (min-width: 200px) and (max-width: 991px) {
  .r-process .col-right ul li::after {
    height: 70px;
  }
}

/* .r-process .col-right ul li:hover::after {
    opacity: 1;
} */

.r-process .col-right ul li .icon {
  cursor: pointer;
  position: relative;
  z-index: 2;
  transition: 0.5s;
  display: flex;
}

.r-process .col-right ul li .content {
  padding-left: 30px;
  display: flex;
  transition: 0.5s;
  margin-top: 1px;
}

@media screen and (min-width: 200px) and (max-width: 767px) {
  .r-process .col-right ul li .content {
    padding-left: 17px;
  }
}

@media screen and (min-width: 200px) and (max-width: 390px) {
  .r-process .col-right ul li .content {
    padding-left: 5px;
  }
}

.r-process .col-right ul li .content p {
  font-weight: 700;
  font-size: 48px;
  line-height: 56px;
  padding-right: 39px;
  position: relative;
  transition: 0.5s;
  margin-bottom: 0;
}

@media screen and (min-width: 200px) and (max-width: 767px) {
  .r-process .col-right ul li .content p {
    font-size: 30px;
    padding-right: 14px;
  }
}

.r-process .col-right ul li .content p::before {
  content: '';
  width: 3px;
  position: absolute;
  right: 11px;
  top: 50%;
  transform: translateY(-50%);
  height: 65px;
  border-radius: 7px;
  background: #fff;
}

@media screen and (min-width: 200px) and (max-width: 767px) {
  .r-process .col-right ul li .content p::before {
    height: 60px;
    right: 7px;
  }
}

.r-process .col-right ul li .content .text strong {
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  transition: 0.5s;
}

@media screen and (min-width: 200px) and (max-width: 767px) {
  .r-process .col-right ul li .content .text strong {
    font-size: 16px;
  }
}

.r-process .col-right ul li .content .text span {
  position: absolute;
  bottom: -1px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  transition: 0.5s;
  max-width: 300px;
}

@media screen and (min-width: 200px) and (max-width: 767px) {
  .r-process .col-right ul li .content .text span {
    font-size: 13px;
  }

  .r-process .col-right ul li .content .text span {
    /* position: absolute; */
    font-weight: 400;
    line-height: 16px;
    transition: 0.5s;
  }
}

@media screen and (min-width: 200px) and (max-width: 390px) {
  /* .r-process .col-right ul li .content .text span {
    bottom: 5px;
  } */
}

.category,
.see-more,
.tag,
.title,
.url-hover,
.box,
.inner {
  cursor: pointer;
}

/*
duy17/8 */

.owl-next,
.owl-prev {
  background: transparent;
  border: none;
}

.group-slide-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#project-details .owl-nav button>svg {
  /* position: absolute;
    top: 50%;
    transform: translate(-50%, -50%); */
}

@media (max-width: 767px) {
  .group-slide-button {
    justify-content: start;
  }

  .owl-nav button {
    padding: 3px;
  }
}

/* #home .r-process .col-right ul li .content p {
  line-height: 64px;
} */

/* #home .r-process .col-right ul li .content .text strong {
  line-height: 29px;
} */

/* #home .r-process .col-right ul li .content .text span {
  line-height: 21px;
} */

/* @media screen and (min-width: 768px) {
  #home .r-process .col-right ul li:hover .content .text span {
    bottom: 5px;
    top:23px;
    line-height: 21px;
    font-size: 16px;
  }
  #home .r-process .col-right ul li:hover .content .text strong {
    font-size: 30px;
  }
} */

/* duy19/8 */

@media (max-width: 767.5px) {
  #about .r-customer .title-center {
    margin-top: -20px;
    margin-bottom: -20px;
  }

  /* #offshore .r-page-title h3 {
    font-size: 20px;
  } */
  #offshore .r-service {
    margin-top: 50px;
  }

  #offshore .r-archivement .inner .content .text-content h3 {
    margin-top: 10px;
  }

  #detailrecruitment .detail-top {
    margin-top: 0px;
  }

  #detailrecruitment .detail-top-main .box-main ul {
    align-items: flex-start;
  }
}

.form-application form .form-group .form-control {
  border: 1px solid #c5c5c5;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #6d6d6d;
  display: block;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  min-height: 40px;
  padding-left: 13px;
  width: 100%;
}

.form-application {
  background-color: #fff;
  border-radius: 4px;
  -webkit-box-shadow: 0 0 16px rgb(0 0 0 / 15%);
  box-shadow: 0 0 16px rgb(0 0 0 / 15%);
  display: none;
  margin-top: 25px;
  margin-bottom: 25px;
  padding: 49px 19px 56px;
  position: relative;
}

.close-popup-pc {
  position: absolute;
  right: 21px;
  top: 21px;
  border: 1px solid #818181;
  border-radius: 50%;
}

.form-application .ttl {
  color: #0063aa;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 35px;
  margin-bottom: 16px;
  text-align: center;
}

.ant-form {
  -webkit-font-feature-settings: 'tnum', 'tnum';
  -moz-font-feature-settings: 'tnum', 'tnum';
  font-feature-settings: 'tnum', 'tnum';
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  margin: 0;
  padding: 0;
}

.form-application form .form-group label {
  color: #6d6d6d;
  display: block;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  margin-bottom: 4px;
}

.ant-form-item {
  -webkit-font-feature-settings: 'tnum', 'tnum';
  -moz-font-feature-settings: 'tnum', 'tnum';
  font-feature-settings: 'tnum', 'tnum';
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  margin: 0 0 24px;
  padding: 0;
  vertical-align: top;
}

.ant-row {
  flex-flow: row wrap;
  min-width: 0;
}

.row {
  cursor: auto;
}

.ant-form-item-control-input {
  align-items: center;
  display: flex;
  min-height: 32px;
  position: relative;
}

.ant-form-item-control-input-content {
  flex: auto;
  max-width: 100%;
}

.form-application .drop_box {
  align-items: center;
  background: #f3f3f3;
  background-image: repeating-linear-gradient(-25deg,
      #bfbfbf,
      #bfbfbf 8px,
      transparent 0,
      transparent 14px,
      #bfbfbf 0),
    repeating-linear-gradient(65deg,
      #bfbfbf,
      #bfbfbf 8px,
      transparent 0,
      transparent 14px,
      #bfbfbf 0),
    repeating-linear-gradient(155deg,
      #bfbfbf,
      #bfbfbf 8px,
      transparent 0,
      transparent 14px,
      #bfbfbf 0),
    repeating-linear-gradient(245deg,
      #bfbfbf,
      #bfbfbf 8px,
      transparent 0,
      transparent 14px,
      #bfbfbf 0);
  background-position: 0 0, 0 0, 100% 0, 0 100%;
  background-repeat: no-repeat;
  background-size: 1.5px 100%, 100% 1.5px, 2px 100%, 100% 1.5px;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 104px;
  padding: 15px;
}

.ant-form-item-explain-error {
  color: #ff4d4f;
  font-weight: 400;
}

.detail-main .sidebar {
  display: block !important;
}

@media (max-width: 767.5px) {
  .detail-main .sidebar {
    margin-top: 23px;
  }

  .detail-main .sidebar .box-desc {
    background: #fff;
    border-radius: 4px;
    -webkit-box-shadow: 0 0 6px rgb(0 0 0 / 10%);
    box-shadow: 0 0 6px rgb(0 0 0 / 10%);
    padding: 28px 19px 39px 28px;
  }

  .detail-main .sidebar .box-desc .box-desc-top {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 24px;
  }
}

.h-pc {
  display: block;
}

@media (max-width: 991.5px) {
  .detail-main .sidebar .box-desc {
    padding: 28px 20px 39px 21px;
    background: #fff;
    border-radius: 4px;
  }

  #detailrecruitment .detail-top {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.detail-main .sidebar .box-desc .box-desc-top .ttl {
  color: #0063aa;
  font-size: 20px;
  font-weight: 700;
  line-height: 23px;
  margin-right: 18px;
}

@media (max-width: 991.5px) {
  .detail-main .sidebar .box-desc .box-desc-top .ttl {
    font-weight: 800;
  }
}

.detail-main .sidebar .box-desc .box-desc-top .label {
  align-items: center;
  background: #ff6071;
  border-radius: 4px;
  color: #fff;
  display: flex;
  font-size: 8px;
  font-style: normal;
  font-weight: 700;
  height: 15px;
  justify-content: center;
  line-height: 9px;
  text-align: center;
  width: 29px;
}

@media (max-width: 991.5px) {
  .detail-main .sidebar .box-desc .box-desc-main {
    margin-bottom: 19px;
  }
}

.detail-main .sidebar .box-desc ul {
  list-style-type: none;
}

@media (max-width: 767.5px) {
  .detail-main ul {
    margin-left: 0;
  }
}

@media (max-width: 991.5px) {
  .detail-main .sidebar .box-desc .ttl-small {
    margin-bottom: 8px;
  }
}

.detail-main .sidebar .box-desc .ttl-small {
  color: #333;
  display: block;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
}

.detail-main .sidebar .box-desc .txt-ct {
  color: #484848;
}

.detail-main .sidebar .box-desc .box-desc-main ul>li {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
}

@media (max-width: 991.5px) and (max-width: 767.5px) {
  .detail-main .c-detail-content ul li {
    padding-left: 0;
  }
}

.detail-main .sidebar .box-desc .box-desc-main ul>li .label-ct ul li {
  align-items: center;
  border: 1px solid #0063aa;
  border-radius: 16px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #0063aa;
  display: flex;
  margin-bottom: 5px;
  min-height: 22px;
  padding-left: 8px;
  padding-right: 8px;
}

.detail-main .sidebar .box-desc .box-desc-main ul>li {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
}

.detail-main .sidebar .box-desc .box-desc-main ul>li .label-ct ul {
  display: flex;
  flex-wrap: wrap;
}

.detail-main .sidebar .box-desc .box-desc-main ul>li .label-ct ul li+li {
  margin-bottom: 5px;
  margin-left: 8px;
  margin-top: 0;
}

.detail-main .sidebar .box-desc .box-desc-btn .box.bd-box {
  border: 3px solid #0063aa;
  border-radius: 8px;
  color: #0063aa;
}

.detail-main .sidebar .box-desc .box-desc-btn .box {
  align-items: center;
  display: flex;
  font-size: 15px;
  font-weight: 900;
  height: 56px;
  justify-content: center;
  line-height: 19px;
  text-align: center;
}

.detail-main .sidebar .box-desc .box-desc-btn .box.bd-box a {
  align-items: center;
  color: #0063aa;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.detail-main.sidebar .box-desc .box-desc-btn .box.bd-box a .txt-decoration {
  margin-left: 2px;
  text-decoration: underline;
}

.detail-main .sidebar .box-desc .box-desc-btn .box.bg-box a {
  align-items: center;
  background: #0063aa;
  border: 1px solid #0063aa;
  border-radius: 8px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #fff;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.detail-main .sidebar .box-desc .box-desc-btn .box.bg-box {
  margin-top: 16px;
}

.detail-main .sidebar .box-desc .box-desc-btn .box.bg-box a img {
  display: block;
  margin-right: 16px;
}

@media (max-width: 991.5px) {
  .detail-main .sidebar .box-desc .ttl-small {
    margin-bottom: 8px;
  }
}

.detail-main .sidebar .box-desc .ttl-small {
  color: #333;
  display: block;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  margin-bottom: 4px;
}

.detail-main .sidebar .box-desc .box-desc-share {
  margin-top: 24px;
}

.detail-main .sidebar .box-desc .box-desc-share ul {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 991.5px) and (max-width: 767.5px) {
  .detail-main .c-detail-content ul li {
    padding-left: 0;
  }
}

.detail-main .sidebar .box-desc .box-desc-share ul li a,
.detail-main .sidebar .box-desc .box-desc-share ul li img {
  display: block;
}

.detail-main .sidebar .box-desc .box-desc-share ul li a {
  margin-right: 6px;
}

.detail-main .sidebar .box-desc {
  background: #fff;
  border-radius: 4px;
  -webkit-box-shadow: 0 0 6px rgb(0 0 0 / 10%);
  box-shadow: 0 0 6px (rgba(0, 0, 0, 0.1));
  padding: 28px 19px 39px 28px;
}

.detail-main .form-application form .form-upload .drop_box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #f3f3f3;
  box-sizing: border-box;
  border-radius: 4px;
  min-height: 104px;
  background-image: repeating-linear-gradient(-25deg,
      #bfbfbf,
      #bfbfbf 8px,
      rgba(0, 0, 0, 0) 8px,
      rgba(0, 0, 0, 0) 14px,
      #bfbfbf 14px),
    repeating-linear-gradient(65deg,
      #bfbfbf,
      #bfbfbf 8px,
      rgba(0, 0, 0, 0) 8px,
      rgba(0, 0, 0, 0) 14px,
      #bfbfbf 14px),
    repeating-linear-gradient(155deg,
      #bfbfbf,
      #bfbfbf 8px,
      rgba(0, 0, 0, 0) 8px,
      rgba(0, 0, 0, 0) 14px,
      #bfbfbf 14px),
    repeating-linear-gradient(245deg,
      #bfbfbf,
      #bfbfbf 8px,
      rgba(0, 0, 0, 0) 8px,
      rgba(0, 0, 0, 0) 14px,
      #bfbfbf 14px);
  background-size: 1.5px 100%, 100% 1.5px, 2px 100%, 100% 1.5px;
  background-position: 0 0, 0 0, 100% 0, 0 100%;
  background-repeat: no-repeat;
  padding: 15px;
}

@media (max-width: 991.5px) {
  .detail-main .form-application .drop_box {
    justify-content: flex-end;
    padding-bottom: 18px;
  }
}

.detail-main .form-application .drop_box a,
.detail-main .form-application .drop_box img {
  display: block;
}

.drop-icon img {
  margin-left: auto;
  margin-right: auto;
}

.detail-main .form-application .drop_box h4 {
  text-align: center;
  color: #6d6d6d;
  font-weight: 500;
  font-size: 1rem;
}

@media (max-width: 991.5px) {
  .form-application1 #form-popup {
    margin: 0px;
    padding: 0px;
    display: block;
  }
}

@media (max-width: 991.5px) {
  #form-popup {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background-color: #f2f5f6;
    overflow-y: scroll;
    z-index: 10;
    margin-top: 0;
    padding-top: 0;
    transition: all 1s ease-in;
  }
}

@media (max-width: 991.5px) {
  .form-application1 #form-popup .popup-container {
    position: relative;
    z-index: 2;
  }
}

@media (max-width: 991.5px) {
  .popup-header {
    width: 320px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 11px;
    margin-bottom: 14.82px;
  }
}

.detail-main .form-application form button {
  border-radius: 4px;
  border: none;
  outline: none;
  height: 56px;
  width: 100%;
  font-style: normal;
  font-weight: 900;
  background: #0063aa;
  color: #ffffff;
  font-size: 16px;
  line-height: 19px;
  font-family: 'Roboto', sans-serif;
  transition: all 0.25s;
  margin-top: 21px;
}

@media (max-width: 991.5px) {
  .logo {
    width: 100%;
  }
}

/* .close-popup {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #818181;
} */

.close-popup {
  position: absolute;
  right: 21px;
  top: 21px;
  border: 1px solid #818181;
  border-radius: 50%;
}

@media (max-width: 991.5px) and (min-width: 767.5px) {

  #detailrecruitment .menu-job .group-btn a.btn,
  #detailrecruitment .menu-job .group-btn a.btn.btn-active {
    font-size: 14px;
  }
}

label.form-label.ttl-upload {
  color: #6d6d6d;
  display: block;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  margin-bottom: 4px;
}

/* duy23/8 */

.row .list-type ul li {
  list-style: inside !important;
}

#detailrecruitment .menu-job .name-job h4.title span {
  margin-top: 0px;
}

.wrapper__copy {
  display: flex;
  justify-content: center;
  align-items: stretch;
  position: relative;
  min-width: 2vw;
  max-height: 2vw;
  min-height: 2vw;
  border-radius: 100%;
  background: rgba(74, 77, 251, 0.1);
  text-align: center;
  color: white;
}

.wrapper__copy::before {
  position: absolute;
  display: inline-block;
  min-width: 70px;
  top: -34px;
  left: 14px;
  right: 0;
  color: white;
  display: block;
  background: rgba(70, 67, 67, 0.55);
  font-weight: 500;
  line-height: 25px;
  border-radius: 2px;
  font-size: 1rem;
}

@media (min-width: 767.5px) {
  .copy:hover::before {
    content: 'Copy';
    padding: 5px 10px;
    border-radius: 3px;
    font-weight: 500;
  }
}

.copied::before {
  content: 'Copied';
  background: #21bf73;
  padding: 5px 10px;
  border-radius: 3px;
  font-size: 1rem;
  font-weight: 500;
}

@media (max-width: 991.5px) {
  #form-popup {
    position: fixed;
    z-index: 110;
  }
}

/* duy23/8ver2 */

#footer .main ul li strong::after {
  background: #fff;
  border-radius: 1px;
  margin-top: 23px;
  content: '';
  height: 1px;
  left: 0;
  position: absolute;
  width: 30px;
}

@media (max-width: 767px) {
  #footer .main ul li strong::after {
    left: 0;
  }
}

/* duy24/8 */

@media screen and (min-width: 200px) and (max-width: 390px) {
  #about .r-our-team .inner ul li .image {
    margin-top: 45px;
  }
}

#offshore .r-archivement .inner .content .text-content {
  margin-top: 40px;
}

@media (max-width: 767px) {
  #offshore .r-cooperate .step.step-1 {
    padding-bottom: 25px;
  }
}

/* @media (max-width: 767.5px) {
    #footer .main ul li strong::after {
        left: 36px;
    }
} */

@media (max-width: 360px) {
  #solution .sidebar .js-open-sidebar {
    width: 85%;
  }

  #solution .sidebar .js-open-sidebar {
    padding: 10px 10px;
  }

  #solution .sidebar .js-open-sidebar svg {
    margin-right: 0px;
  }

  #solution .sidebar .box {
    width: 260px;
    margin-left: -8px;
  }
}

@media screen and (min-width: 200px) and (max-width: 767px) {
  #offshore .r-process .col-left {
    padding-top: 40px;
  }
}

.form-application form .form-group .form-control {
  color: black;
}

.form-application .drop_box {
  cursor: pointer;
}

#web-development .r-strengths .box p {
  font-family: 'Roboto';
}

input#customFile {
  cursor: pointer;
}

.btn-arrow-right {
  margin-left: -1.5px;
}

/* duy25/8 */

@media (max-width: 1199px) {
  #detailrecruitment .detail-top-main .box-main ul li {
    margin-right: 25px;
  }
}

/* #detailrecruitment .detail-top {
  background-color: #e0f3ff;
  margin-top: 0px;
  padding: 78px 0px 60px;
} */

/* .r-contact { */
/* background: url('/assets/images/bg-contact0.png'); */
/* z-index: -5; */
/* } */

.form-application .drop_box {
  cursor: auto;
}

#contact .r-contact-form .form textarea::placeholder {
  color: rgba(0, 99, 170, 0.8);
}

.form-application .ant-form-item-control-input {
  display: block;
}

.ant-message {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #000000d9;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: fixed;
  top: 8px;
  left: 0;
  z-index: 1010;
  width: 100%;
  pointer-events: none;
}

.ant-message-notice {
  padding: 8px;
  text-align: center;
}

.ant-message-notice-content {
  display: inline-block;
  padding: 10px 16px;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014,
    0 9px 28px 8px #0000000d;
  pointer-events: all;
}

.ant-message-success .anticon {
  color: var(--ant-success-color);
}

.ant-message-error .anticon {
  color: var(--ant-error-color);
}

.ant-message-warning .anticon {
  color: var(--ant-warning-color);
}

.ant-message-info .anticon,
.ant-message-loading .anticon {
  color: var(--ant-info-color);
}

.ant-message .anticon {
  position: relative;
  top: 1px;
  margin-right: 8px;
  font-size: 16px;
}

.ant-message-notice.ant-move-up-leave.ant-move-up-leave-active {
  -webkit-animation-name: MessageMoveOut;
  animation-name: MessageMoveOut;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
}

@-webkit-keyframes MessageMoveOut {
  0% {
    max-height: 150px;
    padding: 8px;
    opacity: 1;
  }

  to {
    max-height: 0;
    padding: 0;
    opacity: 0;
  }
}

@keyframes MessageMoveOut {
  0% {
    max-height: 150px;
    padding: 8px;
    opacity: 1;
  }

  to {
    max-height: 0;
    padding: 0;
    opacity: 0;
  }
}

.ant-message-rtl,
.ant-message-rtl span {
  direction: rtl;
}

.ant-message-rtl .anticon {
  margin-right: 0;
  margin-left: 8px;
}

html {
  --ant-primary-color: #1890ff;
  --ant-primary-color-hover: #40a9ff;
  --ant-primary-color-active: #096dd9;
  --ant-primary-color-outline: rgba(24, 144, 255, 0.2);
  --ant-primary-1: #e6f7ff;
  --ant-primary-2: #bae7ff;
  --ant-primary-3: #91d5ff;
  --ant-primary-4: #69c0ff;
  --ant-primary-5: #40a9ff;
  --ant-primary-6: #1890ff;
  --ant-primary-7: #096dd9;
  --ant-primary-color-deprecated-pure: ;
  --ant-primary-color-deprecated-l-35: #cbe6ff;
  --ant-primary-color-deprecated-l-20: #7ec1ff;
  --ant-primary-color-deprecated-t-20: #46a6ff;
  --ant-primary-color-deprecated-t-50: #8cc8ff;
  --ant-primary-color-deprecated-f-12: rgba(24, 144, 255, 0.12);
  --ant-primary-color-active-deprecated-f-30: rgba(230, 247, 255, 0.3);
  --ant-primary-color-active-deprecated-d-02: #dcf4ff;
  --ant-success-color: #52c41a;
  --ant-success-color-hover: #73d13d;
  --ant-success-color-active: #389e0d;
  --ant-success-color-outline: rgba(82, 196, 26, 0.2);
  --ant-success-color-deprecated-bg: #f6ffed;
  --ant-success-color-deprecated-border: #b7eb8f;
  --ant-error-color: #ff4d4f;
  --ant-error-color-hover: #ff7875;
  --ant-error-color-active: #d9363e;
  --ant-error-color-outline: rgba(255, 77, 79, 0.2);
  --ant-error-color-deprecated-bg: #fff2f0;
  --ant-error-color-deprecated-border: #ffccc7;
  --ant-warning-color: #faad14;
  --ant-warning-color-hover: #ffc53d;
  --ant-warning-color-active: #d48806;
  --ant-warning-color-outline: rgba(250, 173, 20, 0.2);
  --ant-warning-color-deprecated-bg: #fffbe6;
  --ant-warning-color-deprecated-border: #ffe58f;
  --ant-info-color: #1890ff;
  --ant-info-color-deprecated-bg: #e6f7ff;
  --ant-info-color-deprecated-border: #91d5ff;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-thumb {
  background-color: #d9d9d9;
}

.warper-popup {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.35);
  position: fixed;
  z-index: 9;
  top: 0;
  margin: 0;
  left: 0;
}

#popup-apply {
  position: fixed;
  width: 1093px;
  max-width: 100%;
  height: 760px;
  top: calc(49% + 20px);
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  overflow: hidden;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  overflow: scroll;
}

@media (max-width: 991px) {
  #popup-apply {
    width: 750px;
    height: 95vh;
    overflow-y: auto;
  }
}

@media (max-width: 767px) {
  #popup-apply {
    width: calc(100% - 30px);
  }

  #popup-apply .row {
    padding: 0 10px !important;
  }
}

#popup-apply .hide-pop-up {
  position: absolute;
  right: 25px;
  top: 18px;
  border-radius: 50%;
  display: flex;
  overflow: hidden;
  z-index: 1;
}

#popup-apply .container {
  padding: 15px;
}

#popup-apply .row {
  margin-left: -15px;
  margin-right: -15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0 50px;
}

#popup-apply .col {
  padding-left: 15px !important;
  padding-right: 15px !important;
  max-width: 100%;
}

#popup-apply .col.left {
  width: calc(100% - 677px);
}

@media (max-width: 991px) {
  #popup-apply .col.left {
    display: none;
  }
}

#popup-apply .col.left h2 {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 47px;
  color: #0063aa;
  text-align: center;
  margin-top: 87px;
}

#popup-apply .col.left img {
  margin-top: 44px;
}

#popup-apply .col.right {
  width: 529px;
  position: relative;
}

#popup-apply .col.right h3 {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: rgba(0, 0, 0, 0.86);
  margin-top: 43px;
}

@media (max-width: 767px) {
  #popup-apply .col.right h3 {
    font-size: 16px;
    margin-top: 15px;
  }
}

#popup-apply .box-upload {
  height: 132.18px;
  width: 100%;
  background: #eaf6ff;
  /* border: 1px dashed #0063AA; */
  border-radius: 4px;
  margin-top: 14px;
  /* border-width: initial; */
}

#popup-apply .box-upload ingput {
  background: #eaf6ff;
}

#popup-apply .box-upload .icon-upload {
  text-align: center;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0 15px;
}

@media (max-width: 767px) {
  #popup-apply .box-upload .icon-upload svg {
    width: 40px;
  }
}

#popup-apply .box-upload p {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #6d6d6d;
}

@media (max-width: 767px) {
  #popup-apply .box-upload p {
    font-size: 14px;
  }
}

#popup-apply .box-upload p span {
  color: red;
}

#popup-apply .ant-row {
  display: block;
}

#popup-apply .col.right .info h3 {
  margin-top: 33px;
  margin-bottom: 12px;
}

#popup-apply .ant-row label {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.68);
}

#popup-apply .ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none;
}

#popup-apply .ant-input,
#popup-apply .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: none;
  border-bottom: 1px solid rgba(0, 99, 170, 0.86);
  background: none;
  color: rgba(0, 0, 0, 0.86);
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  padding: 0;
  border-radius: 0;
}

/* #popup-apply .ant-form-item-has-success label {
    color: #CCCCCC;
} */

#popup-apply .ant-input-status-error {
  border-bottom: 1px solid #ff7875 !important;
}

#popup-apply .ant-input:focus,
#popup-apply .ant-input-focused,
#popup-apply .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none;
}

#popup-apply .ant-btn-primary {
  width: 100%;
  background: #0063aa;
  border: 2px solid #0063aa;
  border-radius: 4px;
  padding: 27px 0;
  text-align: center;
  position: relative;
  margin-top: 9px;
  margin-bottom: 20px;
}

#popup-apply .ant-btn-primary>span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
}

#popup-apply .ant-form-item {
  margin-bottom: 10px;
}

#popup-apply .title-apply-sp {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 47px;
  color: #0063aa;
  text-align: center;
  margin-top: 30px;
  display: none;
}

@media (max-width: 991.5px) {
  #popup-apply .title-apply-sp {
    display: block;
  }
}

@media (max-width: 767.5px) {
  #popup-apply .title-apply-sp {
    font-size: 20px;
    margin-top: 10px;
  }
}

#popup-apply .ant-select-arrow {
  background: url('/assets/images/icon-drop-select.svg') no-repeat;
  width: 18px;
}

#icon-upload {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.ant-form-item-explain-error {
  /* align-items: center;
    display: flex;
    justify-content: center;
    align-content: center; */
  transition: all 0.09s ease-in-out;
}

/* .warper-popup{
    z-index: 10000;
  } */

select#f-location {
  width: 100%;
  /* height: 30px; */
  border: none;
  border-bottom: 1px solid rgba(0, 99, 170, 0.86);
  border-radius: 0;
  color: rgba(0, 0, 0, 0.86);
  padding-left: 0px;
  cursor: pointer;
}

.label-location {
  color: rgba(0, 0, 0, 0.68);
}

@media (max-width: 767.5px) {
  .detail-main .c-detail-main .c-detail-content+.sidebar {
    display: flex;
  }

  .detail-main .c-detail-main {
    max-width: 1111px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
  }

  .detail-main .c-detail-main .sidebar .box-desc {
    width: 100%;
  }
}

@media (max-width: 767px) {
  #popup-apply .box-upload p {
    font-size: 13px;
  }
}

#popup-apply .ant-input,
#popup-apply .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: none;
  border-bottom: 1px solid rgba(0, 99, 170, 0.86);
  background: none;
  color: rgba(0, 0, 0, 0.86);
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  padding: 0;
}

.file_name_upload {
  font-size: 1.1rem;
}

/* dung0610 */

#header .col-right .megamenu ul li a {
  font-size: 21px;
  line-height: 81px;
  color: #696969;
  font-weight: 500;
  position: relative;
  padding: 0 25px;
  display: block;
}

@media (max-width: 1800px) {
  #header .col-right .megamenu ul li a {
    font-size: 16px;
    padding: 0 20px;
  }
}

@media (max-width: 767px) {
  #service .r-service-box .col-info .content {
    margin-top: -12px;
  }

  #about .r-company-profile .row-mobile .ant-tabs-tabpane-hidden {
    display: none;
  }
}

@media (max-width: 767.5px) {
  #about .r-our-team .inner ul li:hover::before {
    display: none;
  }
}

@media (max-width: 767.5px) {
  #about .r-our-team .inner ul li .image img {
    position: relative;
    z-index: 1;
    max-width: 100%;
    object-fit: contain;
  }
}

@media (min-width: 767.5px) and (max-width: 991.5px) {
  .padding-Ipad {
    padding-right: 30px;
  }
}

#news-details .post img {
  width: 100%;
}

.file_name_upload {
  font-size: 13px;
  text-align: center;
  width: 100%;
  margin-top: 10px;
}

@media (max-width: 767px) {
  #popup-apply .box-upload p {
    font-size: 13px;
    text-align: center;
  }

  .file_name_upload {
    font-size: 13px;
    text-align: center;
    width: 100%;
    margin-top: 10px;
  }

  .homeIcon {
    position: fixed;
    right: -40px;
    bottom: 30px;
  }
}

@media (min-width: 767.5px) {
  .homeIcon {
    position: fixed;
    right: -30px;
    bottom: 20px;
  }
}

.file_name_upload {
  font-size: 17px;
  text-align: center;
  width: 100%;
  margin-top: 10px;
}

#project-details .r-treatsure .col-left p {
  font-size: 14px;
  color: #434343;
  padding-right: 65px;
  font-weight: 500;
  width: 100%;
}

#news-details .post img {
  width: 100%;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

/* phduy1010 */
#detailrecruitment .detail-top-main .handleBtnBackNews a span,
#project-details .main-content .handleBlack a {
  font-weight: 500;
}

#detailrecruitment .inner ul {
  margin-left: 0px;
}

.img-new-left {
  cursor: pointer;
}

.owl-nav {
  display: flex;
}

@media (max-width: 1800px) {
  #header .col-right .megamenu ul li a {
    font-size: 16px;
    padding: 0px 18px;
  }
}

@media (max-width: 767.5px) {
  #about .r-our-team {
    margin-top: 40px;
  }
}

@media screen and (min-width: 767.5px) and (max-width: 1199px) {
  #about .r-our-team {
    margin-top: 60px;
  }
}

@media screen and (min-width: 200px) and (max-width: 1199px) {
  .r-contact .title h3::after {
    width: 123px;
    height: 3px;
    border-radius: 42px;
    bottom: 40px;
  }
}

#footer .social.d-none.d-md-block {
  padding-top: 0px;
}

#contact .r-contact-form .info .social ul {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}

img.mail-icon {
  width: 40px;
  margin: 0px 10px;
}

.r-contact .title h3 {
  font-weight: 900;
  font-size: 48px;
  line-height: 56px;
  text-align: center;
  color: #ffffff;
  display: flex;
  position: relative;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}

@media (max-width: 767.5px) {
  element.style {}

  .r-contact .title h3 {
    font-weight: 900;
    font-size: 20px;
    line-height: 56px;
    text-align: center;
    color: #ffffff;
    display: flex;
    position: relative;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
  }

  img.mail-icon {
    width: 30px;
    margin: 0px 7px;
  }
}

#recruitment .r-list-job .job ul li .job-item .btnNext {
  cursor: pointer;
}

@media (max-width: 767.5px) {
  #detailrecruitment .detail-top-main .box-main ul li {
    margin-right: 20px;
  }

  .file_name_upload {
    font-size: 13px;
    text-align: center;
    width: 100%;
    margin-top: 10px;
  }
}

#about .r-our-team .inner .label {
  font-size: 10.6vw;
}

/* popupInfo */
@media (min-width: 1199.5px) {
  #about #exampleModal .modal-dialog {
    width: 800px;
  }
}

#about #exampleModal .modal-body .wrap-profiles .profiles {
  text-align: center;
}

/* popupInfo */

@media (max-width: 1199.5px) {
  .ct-mb {
    display: block;
  }

  .ct-pc {
    display: none;
  }
}

@media (min-width: 1199.5px) {
  .ct-mb {
    display: none;
  }

  .ct-pc {
    display: block;
  }
}

.content-contact {
  border: 2px solid white;
  border-radius: 15px;
  padding-top: 15px;
  padding-bottom: 5px;
  margin: 0px 361px;
}

.r-contact .title p {
  margin-top: 20px;
}

@media (max-width: 1199.5px) and (min-width: 991.5px) {
  .content-contact {
    margin: 0px 265px;
  }
}

@media (max-width: 991.5px) and (min-width: 767.5px) {
  .content-contact {
    margin: 0px 145px;
  }
}

@media (max-width: 767.5px) {
  .content-contact {
    margin: 0px 110px;
  }
}

@media (max-width: 499.5px) {
  .content-contact {
    margin: 0px 0px;
  }
}

@media (min-width: 767.5px) {
  #about #exampleModal .modal-body .wrap-avatar .avatar img {
    position: relative;
    z-index: 1;
    width: 328px;
  }
}

#about #exampleModal .modal-body .wrap-avatar .avatar::before {
  content: '';
  background: url(/assets/images/about/bg-avt.png) no-repeat;
  width: 86%;
  position: absolute;
  height: 100%;
  left: 18%;
  top: 0;
}

#about #exampleModal .modal-body .wrap-avatar {
  padding: 0 35px 35px 35px;
  position: relative;
}

.content-notext {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* align-content: center; */
}

@media (min-width: 767.5px) and (max-width: 991.5px) {
  .r-contact .title {
    margin-top: 0px;
  }
}

#web-development .r-strengths .box p {
  font-size: 16px;
  line-height: 24px;
}

#project-details .r-treatsure .col-left p {
  font-size: 16px;
  line-height: 28px;
  padding-right: 0px;
}

/*@media (min-width:1366.5px) {*/
/*    #home .r-big-banner {*/
/*        padding: 53px 0 403px;*/
/*    }*/
/*    #home .r-featured-news {*/
/*        padding: 25px 0;*/
/*    }*/
/*    #home .r-big-banner .content {*/
/*        margin-top: 180px;*/
/*    }*/
/*}*/
/*@media (max-width:1366.5px) and (min-width:767.5px){*/
/*    #home .r-big-banner {*/
/*        padding: 1px 0 154px;*/
/*    }*/
/*    #home .r-featured-news {*/
/*        padding: 25px 0;*/
/*    }*/
/*    #home .r-big-banner .content {*/
/*        margin-top: 275px;*/
/*    }*/
/*    #home .r-big-banner .content {*/
/*        margin-top: 160px;*/
/*    }*/
/*}*/

/* phduy1010 */

/* dung1110 */
#solution .sidebar ul li::before {
  content: '';
  width: 8px;
  height: 8px;
  display: block;
  border-radius: 50%;
  background-color: #0063aa;
  position: absolute;
  left: -16px;
  top: 5px;
  opacity: 0;
  z-index: 2;
}

/* dung1110 */
@media screen and (min-width: 200px) and (max-width: 1199px) {
  #header .header-mobile {
    display: block;
  }

  .update_contact_a img {
    width: 24px;
  }

  .update_contact_a span {

    font-size: 14px !important;
  }

  .update_contact_block_all {
    margin-bottom: 25px;
  }
}

.update_contact_a {
  display: flex;
  align-items: center;
}

.update_contact_a>span {
  color: #F9F9F9;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  /* 156.25% */
  margin-left: 12px;
}