@media (min-width:1200px) {

    .lg-and-tech-containers:nth-child(2) {
        margin: 0 20px;
    }


    #home .about-slide-home .r-office-tcom-home .col-right .tcom-image .slick-slide .item img {
        height: 505px;
        padding: 0;
    }

    /* Slide-besides */
    .p-home__text-mobile {
        display: none;
    }

    #home .about-slide-home .r-office-tcom-home .col-right .tcom-image .slick-current {
        width: 946px !important;
        height: 612.94px !important;
    }

    #home .about-slide-home .r-office-tcom-home .col-right .tcom-image .slick-current>div {
        width: 946px !important;
        height: 612.94px !important;
    }

    #home .about-slide-home .r-office-tcom-home .col-right .tcom-image .slick-current>div>.slide-home {
        width: 946px !important;
        height: 612.94px !important;
    }

    #home .about-slide-home .r-office-tcom-home .col-right .tcom-image .slick-current .item {
        width: 946px !important;
        height: 612.94px !important;
        overflow: hidden;
        border-radius: 25px !important;
    }

    /* #home .about-slide-home .r-office-tcom-home .col-right .tcom-image .slick-slide .item img {
        padding: 15px;
    } */

    #home .about-slide-home .r-office-tcom-home .col-right .tcom-image .slick-current .item img {
        height: 612.94px !important;
        padding: 0 !important;
        object-fit: cover;
    }

    /* Slide-besides-about */

    #about .r-office-tcom .col-right .tcom-image .slick-slide .item img {
        height: 700px;
        padding: 0;
        object-fit: cover;
    }

    #about .r-office-tcom .col-right .tcom-image .slick-current {
        width: 1295.04px !important;
        height: 730.49px;
    }

    #about .r-office-tcom .col-right .tcom-image .slick-current>div>div {
        width: 1295.04px;
        height: 730.49px;
    }

    #about .r-office-tcom .col-right .tcom-image .slick-current>div {
        width: 1295.04px;
        height: 730.49px;
    }

    #about .r-office-tcom .col-right .tcom-image .slick-current .item {
        width: 1295.04px;
        height: 730.49px;
        overflow: hidden;
        border-radius: 0;
        transition: .3s;
    }

    /* #about .r-office-tcom .col-right .tcom-image .slick-slide .item img {
        padding: 15px;
    } */

    #about .r-office-tcom .col-right .tcom-image .slick-current .item img {
        border-radius: 0px !important;
        height: 730.49px;
        padding: 0;
    }

}

@media (min-width:1024px) and (max-width:1200px) {
    .p-home__slider__content {
        left: 50%;
        transform: translateX(-50%);
    }

    #footer {
        max-height: none;
    }

    .slick-next {
        display: none;
    }

    .slider-home {
        flex: 0 0 73% !important;
        max-width: 73% !important;
        overflow: hidden !important;
        padding-bottom: 0 !important;

    }

    #about .r-office-tcom .col-right .tcom-image .slick-current>div>div>.item-slide-office>img {
        height: auto;
    }

    #home .r-featured-project>.container {
        max-width: 720px;
    }

    #home .r-featured-project .col-right .image>a {
        width: auto;
        height: auto;
    }

    .p-home__our-service-items {
        width: 100%;
    }

    .p-home__our-service-item {
        width: calc(100%/2 - 16px);
        height: auto;
        max-height: max-content;
    }

    .language_technologys {
        width: calc(100% - 20px);
    }

    .p-home__our-service-methods-items {
        width: 100%;
    }

    .p-home__our-service-content-project-left {
        display: none;
    }

    .copyright {
        padding-top: 15px;
        display: block !important;
        text-align: center;
    }

    .copyright-left,
    .copyright-right {
        width: 100%;
    }

    .copyright-right>p {
        padding: 10px 80px !important;
        display: flex;
        justify-content: center;
        text-align: center !important;
    }

    .r-cooperate>.container {
        margin-left: 24px;
        margin-right: 24px;
        width: calc(100% - 48px);
    }

    #about .r-office-tcom .col-right .tcom-image .slick-dots {
        display: none !important;

    }

    .r-featured-solution-content {
        width: 650px;
    }

    .p-home__achievement-comtenet {
        display: block !important;
    }

    .p-home__slider__item_a {
        height: 1300px !important;
        background-position: center !important;
    }

    .p-home__text-mobile {
        margin-top: 100px;
    }

    .p-home_txt {
        display: none;
    }

    .p-home__slider__content {
        text-align: center;
    }

    .p-home__text {
        font-size: 20px;
        text-align: center;
        line-height: 40px;
        height: 160px;
        font-weight: 700;
        /* margin: 0 12% 0 12%; */
        padding-top: 60px;
        max-width: 100%;
    }

    .p-home__slider__content>div>p {
        font-size: 40px;
        line-height: 76px;
        max-width: 805px;
        font-weight: 900;
        font-family: 'Roboto';
        font-style: normal;
        margin: auto;
    }


    .project-btn {
        margin: auto !important;
    }

    .scroll-link {
        bottom: 50px;
    }



    .p-home__slider__content p {

        margin-bottom: 15px;
    }

    .p-home__slider__follow {
        display: none !important;
        position: absolute;
        /* right: 90%; */
        left: 50%;
        transform: translateX(-50%);
        /* top: 100%; */
        padding-top: 25px;
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: 1;
    }

    .p-home__slider__content {
        /* padding: 0 50px; */
    }

    .p-home__slider__follow ul {
        padding: 0;
    }

    .p-home__slider__follow ul {
        background-position: top;
    }

    .p-home__icon-item>div {
        margin: auto;
        width: 80px;
        height: 80px;
    }

    .p-home__icon-item>div>img {
        width: 100%;
    }

    .text-home {
        width: 100% !important;
    }

    .p-home__icon-item>div>p {
        font-size: 18px;
    }

    .p-home__icon {
        padding-top: 40px;
    }


    .p-home__our-service-item {
        /* width: calc(100%/2 - 6px); */
    }

    .p-home__our-service {
        width: calc(100% - 50px);
        margin: 0 25px;
    }

    .p-home__our-service-title>div {
        display: block;
    }

    .p-home__our-service-title>h1 {
        line-height: 25px;
        padding-top: 50px;
        font-size: 18px;
    }

    .p-project-service-title>h1 {
        line-height: 30px;
        padding-top: 0px;
        font-size: 24px;
    }

    .p-home__our-service-read-icon {
        padding-top: 20px;
    }

    .p-home__our-service-methods-item>div>img {
        width: 100%;
    }

    .p-home__our-service-methods-item-des>p {
        width: 150px;
    }

    .p-home__procedure-content-left>.img-procedure-2,
    .p-home__procedure-content-left>.img-procedure-1 {
        display: none;
    }

    .p-home__procedure-content-right {
        width: 100%;
        height: 80%;
        position: absolute;
        left: 5%;
        top: 60%;
        transform: translateY(-50%);
        padding-left: 10px;
    }

    .p-home__procedure-content-bar {
        position: absolute;
        content: "";
        width: 1px;
        height: 100%;
        background-color: #FFFFFF;
        left: 10%;
        transform: translateX(-50%);
    }

    .p-home__procedure-content-left-text>p {
        display: none;
    }

    .p-home__procedure-content-left-text>.p-home__procedure-content-left-text-title {
        display: block;
        position: relative;
        font-weight: 700;
        font-size: 40px;
        line-height: 47px;
        text-align: start;
        text-transform: uppercase;
        color: #FFFFFF;
    }

    .p-home__procedure-content-left {
        width: 100%;
    }

    .p-home__procedure-content-left-text {
        right: 25%;
        top: 5%;
    }

    .p-home__achievement {
        width: calc(100% - 50px);
        margin: 0 25px;
    }

    .p-home__achievement-title-items {
        flex-wrap: wrap;
    }

    .p-home__achievement-title-item {
        margin: 10px;
        width: calc(100%/2 - 20px);
    }

    #home #about>.r-office-tcom .col-right .tcom-image {
        margin-right: 370px !important;
        margin-left: -5px !important;
    }

    .responsive-about {
        text-align: center;
    }

    .responsive-about>.inner>h3::before {
        content: "";
        width: 123px;
        height: 5px;
        background-color: #0063aa;
        border-radius: 43px;
        position: absolute;
        left: 0;
        left: 50% !important;
        bottom: -20px !important;
        transform: translateX(-50%);
    }

    .slider-home {
        flex: 0 0 100% !important;
        max-width: 100% !important;
        overflow: hidden !important;
    }

    .r-contact {
        padding: 0 0 85px 0;
    }

    .lg-img {
        width: 100%;
        height: 80px;
        display: flex;
        justify-content: end;
        bottom: 10px;
        text-align: end;
    }

    .lg-img>img {
        width: 80%;
        aspect-ratio: 3/2;
        object-fit: contain;
    }


}

@media (min-width:740px) and (max-width:1024px) {


    #service .r-service-box .box .col-info .icon {
        margin-left: 20px;
    }

    .oqijfajajada-left,
    .jjqjfsasfbshsafsfashf {
        width: unset !important;
        height: unset !important;
    }

    .member-system-items {
        display: block;
    }

    .kdqhfsiqihjfsf iframe {
        width: 100%;
        height: 500px;
    }

    .member-system-item {
        margin-bottom: 25px;
    }

    .p-home__slider__content {
        left: 50%;
        transform: translateX(-50%);
    }

    #footer {
        max-height: none;
    }

    .slick-next {
        display: none;
    }

    .slider-home {
        flex: 0 0 73% !important;
        max-width: 73% !important;
        overflow: hidden !important;
        padding-bottom: 0 !important;

    }

    #home .r-featured-project>.container {
        max-width: 720px;
    }

    #home .r-featured-project .col-right .image>a {
        width: auto;
        height: auto;
        padding: 10px;
    }

    .p-home__our-service-item {
        width: calc(100%/2 - 16px);
        height: auto;
        max-height: max-content;
    }

    .p-home__our-service-methods-items {
        max-width: 100%;
        width: 100%;
        padding: 0 10px;
        height: auto !important;
    }

    .p-home__our-service-items {
        width: 100%;
    }

    .img-project-bg>div>h1 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 29px;
        line-height: 11px;
        color: #FFFFFF;
        margin-bottom: 24.28px;
    }

    .p-home__our-service-item {
        width: calc(100%/2 - 8px);
    }

    .language_technologys {
        width: calc(100% - 8px);
        padding: 18.18px 35.29px 18.18px 35.29px;
    }

    .p-home__our-service-content-project-left {
        display: none;
    }

    .copyright {
        padding-top: 15px;
        display: block !important;
        text-align: center;
    }

    .copyright-left,
    .copyright-right {
        width: 100%;
    }

    .copyright-right>p {
        padding: 10px 80px !important;
        display: flex;
        justify-content: center;
        text-align: center !important;
    }

    .r-what {
        margin-top: 0 !important;
    }

    .r-cooperate>.container {
        width: calc(100% - 48px);
        margin-left: 24px;
        margin-right: 24px;
    }

    #about .r-office-tcom .col-right .tcom-image .slick-dots {
        display: none !important;
    }

    .r-featured-solution-content {
        width: 450px;
    }

    .p-home__achievement-comtenet {
        display: block !important;
    }

    .lg-and-tech {
        padding: 0;
    }

    .lg-and-tech-container {
        width: calc(100%/4);
    }

    .p-home__slider__item_a {
        height: 1300px !important;
        background-position: center !important;
    }

    .p-home__text-mobile {
        margin-top: 100px;
    }

    .p-home_txt {
        display: none;
    }

    .p-home__slider__content {
        text-align: center;
    }

    .p-home__text {
        font-size: 20px;
        text-align: center;
        line-height: 40px;
        height: 160px;
        font-weight: 700;
        /* margin: 0 12% 0 12%; */
        padding-top: 60px;
        max-width: 100%;
    }

    .p-home__slider__content>div>p {
        width: auto;
        font-size: 40px;
        line-height: 76px;
        max-width: 805px;
        font-weight: 900;
        font-family: 'Roboto';
        font-style: normal;
        margin: auto;
    }

    .project-btn {
        margin: auto !important;
    }

    .scroll-link {
        bottom: 50px;
    }



    .p-home__slider__content p {

        margin-bottom: 15px;
    }

    .p-home__slider__follow {
        display: none !important;
        position: absolute;
        /* right: 90%; */
        left: 50%;
        transform: translateX(-50%);
        /* top: 100%; */
        padding-top: 25px;
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: 1;
    }

    .p-home__slider__content {
        /* padding: 0 50px; */
    }

    .p-home__slider__follow ul {
        padding: 0;
    }

    .p-home__slider__follow ul {
        background-position: top;
    }

    .p-home__icon-item>div {
        margin: auto;
        width: 80px;
        height: 80px;
    }

    .p-home__icon-item>div>img {
        width: 100%;
    }

    .text-home {
        width: 100% !important;
    }

    .p-home__icon-item>div>p {
        font-size: 18px;
    }

    .p-home__icon {
        padding-top: 40px;
    }


    .p-home__our-service-item {
        /* width: calc(100%/2 - 6px); */
    }

    .p-home__our-service {
        width: calc(100% - 50px);
        margin: 0 25px;
    }

    .p-home__our-service-title>div {
        display: block;
    }

    .p-home__our-service-title>h1 {
        line-height: 25px;
        padding-top: 50px;
        font-size: 18px;
    }


    .p-project-service-title>h1 {
        line-height: 30px;
        padding-top: 0px;
        font-size: 24px;
    }

    .p-home__our-service-read-icon {
        padding-top: 20px;
    }

    .p-home__our-service-methods-item>div>img {
        width: 100%;
    }

    .p-home__our-service-methods-item-des>p {
        width: 150px;
    }

    .p-home__our-service-methods-item-title>img {
        width: 80%;
    }

    .p-home__procedure-content-left>.img-procedure-2,
    .p-home__procedure-content-left>.img-procedure-1 {
        display: none;
    }

    .p-home__procedure-content-right {
        width: 100%;
        height: 80%;
        position: absolute;
        left: 5%;
        top: 60%;
        transform: translateY(-50%);
        padding-left: 10px;
    }

    .p-home__procedure-content-bar {
        position: absolute;
        content: "";
        width: 1px;
        height: 100%;
        background-color: #FFFFFF;
        left: 10%;
        transform: translateX(-50%);
    }

    .p-home__procedure-content-left-text>p {
        display: none;
    }

    .p-home__procedure-content-left-text>.p-home__procedure-content-left-text-title {
        display: block;
        position: relative;
        font-weight: 700;
        font-size: 40px;
        line-height: 47px;
        text-align: start;
        text-transform: uppercase;
        color: #FFFFFF;
    }

    .p-home__procedure-content-left {
        width: 100%;
    }

    .p-home__procedure-content-left-text {
        right: 25%;
        top: 5%;
    }

    .p-home__achievement {
        width: calc(100% - 100px);
        margin: 0 50px;
    }

    .p-home__achievement-title-items {
        flex-wrap: wrap;
    }

    .p-home__achievement-title-item {
        margin: 10px;
        width: calc(100%/2 - 20px);
    }

    #home #about>.r-office-tcom .col-right .tcom-image {
        margin-right: 370px !important;
        margin-left: -5px !important;
    }

    .responsive-about {
        text-align: center;
    }

    .responsive-about>.inner>h3::before {
        content: "";
        width: 123px;
        height: 5px;
        background-color: #0063aa;
        border-radius: 43px;
        position: absolute;
        left: 0;
        left: 50% !important;
        bottom: -20px !important;
        transform: translateX(-50%);
    }

    .about-row {
        display: block !important;
    }

    .about-col-left {
        width: 100% !important;
    }

    .about-col-left>img {
        width: 100% !important;
    }

    .about-col-left {
        width: 100% !important;
    }

    .slider-home {
        flex: 0 0 100% !important;
        max-width: 100% !important;
        overflow: hidden !important;
    }

    .r-contact {
        padding: 0 0 50px 0;
    }

    .lg-img {
        width: 100%;
        height: 80px;
        display: flex;
        justify-content: end;
        bottom: 10px;
        text-align: end;
    }

    .lg-img>img {
        width: 80%;
        aspect-ratio: 3/2;
        object-fit: contain;
    }

}

@media (max-width:739px) {

    #project-details .main-content .handleBlack {
        margin-top: 80px;
    }

    #service-details .r-contact {
        padding: 65px 0 50px 0;
    }

    #footer {
        max-height: none;
    }

    .slick-next {
        display: none;
    }

    .slider-home {
        flex: 0 0 73% !important;
        max-width: 73% !important;
        overflow: hidden !important;
        padding-bottom: 0 !important;

    }

    #home .r-featured-project .col-right .image>a {
        width: auto;
        height: auto;
        padding: 10px;
    }

    .p-home__our-service-item {
        width: calc(100%/2 - 16px);
        height: auto;
        max-height: max-content;
    }

    .p-home__our-service-methods-items {
        display: block;
        max-width: 100%;
        width: 100%;
        /* padding: 0 10px; */
        height: auto !important;
        border: none;
        background-color: unset;
    }

    .p-home__our-service-methods-item>div {
        width: 100%;
    }

    .p-home__our-service-methods-item>.img-service {
        width: 191.04px !important;
        height: 182.69px !important;
    }

    .p-home__our-service-items {
        width: 100%;
        padding-bottom: 20px;
    }

    .p-home__our-service-item {
        flex: 0 0 calc(100% - 10px);
        border: 1px solid #D9D9D9;
        border-radius: 8px;
    }

    .p-home__achievement-comtenet {
        display: block !important;
    }

    .p-home__text {
        width: 282px;
        font-size: 18px;
        text-align: center;
        line-height: 30px;
        /* height: 160px; */
        font-weight: 600;
        margin: auto;
        padding-top: 30px;
    }

    .p-home__slider__content>div>p {
        font-size: 24px;
        line-height: 32px;
        max-width: 805px;
        font-weight: 900;
        font-family: 'Roboto';
        font-style: normal;
    }

    .sub_description_home {
        font-size: 16px !important;
        line-height: 24px;
    }

    .scroll-link {
        bottom: 50px;
    }

    .p-home__slider__content p {
        /* margin-bottom: 15px; */
        font-weight: 700;
        font-size: 20px;
        line-height: 32px;

        /* or 160% */

        text-align: center;

        color: #FFFFFF;
    }

    .p-home__slider__follow {
        position: absolute;
        right: 90%;
        top: 30%;
        padding-top: 25px;
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: 1;
    }

    .p-home__slider__content {
        /* padding: 0 50px; */
    }

    .p-home__slider__follow ul {
        padding: 0;
    }

    .p-home__slider__follow ul {
        background-position: top;
    }

    .p-home__icon-item>div {
        margin: auto;
        width: 50px;
        height: 50px;
    }

    .p-home__icon-item>div>img {
        width: 100%;
    }

    .text-home {
        width: 100% !important;
    }

    .p-home__icon-item>div>p {
        font-size: 16px;
        font-weight: 600;
    }



    .p-home__icon {
        padding-top: 170px;
    }


    .p-home__our-service-item {
        width: calc(100% - 6px);
    }

    .p-home__our-service {
        width: calc(100% - 50px);
        margin: 0 25px;
    }

    .p-home__our-service-title>div {
        display: block;
    }

    .p-home__our-service-title>h1 {

        padding-top: 50px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 900;
        font-size: 18px;
        line-height: 24px;
        /* or 133% */


        color: #0063AA;
        max-width: 293px;
    }


    .p-project-service-title>h1 {

        padding-top: 0px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 900;
        font-size: 24px;
        line-height: 30px;
        /* or 133% */


        color: #0063AA;
    }


    .p-home__our-service-read-icon {
        padding-top: 16px;
        margin-top: 0;
        margin-bottom: 24px;
    }

    .p-home__our-service-methods-item>div>img {
        width: 100%;
    }

    .p-home__our-service-methods-item-des>p {
        width: 260px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        /* or 143% */

        display: flex;
        align-items: center;

        color: #0063AA;
    }

    .p-home__procedure-content-left>.img-procedure-2,
    .p-home__procedure-content-left>.img-procedure-1 {
        display: none;
    }

    .p-home__procedure-content-right {
        width: 100%;
        height: 80%;
        position: absolute;
        left: -1%;
        top: 50%;
        transform: translateY(-50%);
        padding-left: 10px;
    }

    .p-home__procedure-content-bar {
        position: absolute;
        content: "";
        width: 1px;
        height: 100%;
        background-color: #FFFFFF;
        left: 10%;
        transform: translateX(-50%);
    }

    .p-home__procedure-content-left-text>p {
        display: none;
    }

    .p-home__procedure-content-left-text>.p-home__procedure-content-left-text-title {
        display: block;
        position: relative;
        font-weight: 700;
        font-size: 40px;
        line-height: 47px;
        text-align: start;
        text-transform: uppercase;
        color: #FFFFFF;
    }

    .p-home__procedure-content-left {
        width: 100%;
    }

    .p-home__procedure-content-left-text {
        right: 15%;
        top: 0;
    }

    .p-home__achievement {
        width: calc(100% - 50px);
        margin: 0 25px;
    }

    .p-home__achievement-title-items {
        flex-wrap: wrap;
    }

    .p-home__achievement-title-item {
        margin: 10px;
        width: calc(100%/2 - 20px);
    }

    /* #home #about>.r-office-tcom .col-right .tcom-image {
        margin-right: 370px !important;
        margin-left: -5px !important;
    } */

    .responsive-about {
        text-align: center;
    }


    .p-home__our-service-methods-item {
        position: relative;
        padding: 20px;
        width: 100%;
        background: #F8FCFF;
        border: 1px solid #0063AA;
        border-radius: 8px;
        margin-bottom: 20px;
    }

    .p-home__our-service-methods-items::after {
        display: none;
    }

    .p-home__our-service-methods-item {
        display: block;
    }

    .p-home__our-service-methods-item>div:nth-child(1) {
        margin: 0 0 20px 0;
    }

    .p-home__procedure-content-left-text-title {
        font-size: 24px !important;
    }

    .p-home__procedure-content-right-content-number>p {
        font-size: 24px;
    }

    .p-home__procedure-content-right-content-number {

        margin: 0 15px;

    }

    .p-home__procedure-content-right-content-text>h1 {
        font-size: 18px;
        margin: 0;
        padding-bottom: 5px;

    }

    .p-home__procedure-content-right-content-text>p {
        width: 150px;
    }

    .lg-and-tech {
        padding: 0;
    }

    .p-home__our-service-item {
        padding: 19px 15px;
    }

    .lg-and-tech-container {
        width: calc(100%/5);
        padding: 0 2px;
    }

    .lg-and-tech-containers {
        width: calc(100%/3);
    }

    .responsive-project {
        display: block !important;
    }

    .p-home__our-service-content-project-right {
        margin-top: 50px;
        width: 100%;
    }

    .p-home__our-service-content-project-right>img {
        width: 100%;
    }

    .p-home__our-service-read-icon>div {
        display: flex;
    }

    #about {
        padding-top: 20px !important;
    }

    .r-featured-solution-content>h1 {
        font-size: 36px;
        line-height: 46px;
    }

    .r-featured-solution-content>p {
        font-size: 18px;
    }

    .p-home__slider__item_a {
        background-position: center !important;
        height: 1460px !important;
    }

    .p-offShore>p {
        font-size: 24px;
        text-align: center;
        line-height: 30px;
        height: 160px;
        font-weight: 600;
        margin: 0 8% 0 8%;
        padding-top: 30px;
    }

    .text-offSore>p {
        font-size: 18px !important;
        text-align: center !important;
        line-height: 30px !important;
        height: 160px !important;
        font-weight: 600 !important;
        margin: 0 8% 0 8% !important;
        padding-top: 30px !important;
    }

    .p-offShore-icon {
        padding-top: 60px;
    }

    .p-offShore__icon-item {
        height: auto !important;
    }

    .p-home__slider__follow {
        display: none;
    }

    .project-btn {
        margin: auto !important;
    }

    .p-home_txt {
        display: none;
    }

    .p-home__icon-item {
        width: auto;
        height: auto;
    }

    .p-home__text>p {
        width: 282px;
    }

    .p-home__text-mobile {
        margin-top: 100px;
    }

    .p-home__slider__item-mobile {
        display: block;
    }

    .p-home__slider__item {
        display: none;
    }

    .p-home__our-service-title>div>p {
        width: 350px;
    }

    #about .r-office-tcom .col-right .tcom-image-home {
        margin-right: 100px !important;
        margin-left: -5px !important;
    }

    .slider-home {
        flex: 0 0 100% !important;
        max-width: 100% !important;
        overflow: hidden !important;
    }

    .r-contact {
        padding: 0 0 50px 0;
    }

    .p-home__our-service-methods-item-title>p {
        margin-left: 0;
        margin-bottom: 0;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 24px;
        /* identical to box height, or 100% */

        display: flex;
        align-items: center;

        color: #0063AA;

        padding: 12px 0;
    }

    .p-home__our-service-methods-item-des>p {
        margin-left: 0;
    }

    .p-home__our-service-methods-item-title {
        /* height: 60px; */
    }

    .u21uwfsnfsnvnnewnfwne>a {
        white-space: unset !important;
    }

    .lg-img {
        width: 100%;
        height: 80px;
        display: flex;
        justify-content: end;
        bottom: 10px;
        text-align: end;
    }

    .lg-img>img {
        width: 80%;
        aspect-ratio: 3/2;
        object-fit: contain;
    }

    .lg-and-tech-container>p {
        font-size: 12px;
    }

    .p-home__achievement-comtenet {
        padding: 0;
    }

    .p-home__achievement-title {
        padding: 0;
    }

    .p-home__achievement-title>h1 {
        font-size: 18px;
    }

    .p-home__achievement-title>p {
        font-size: 14px;
    }

    .p-home__achievement-title-item {
        padding: 15px;
    }

    .p-home__achievement-title-item-icon {

        width: 70px;
        height: 70px
    }

    .p-home__achievement-title-item-icon>img {

        width: 80%;
    }

    .p-home__achievement-title-item-number {
        font-size: 18px;
        line-height: 18px;
    }

    .p-home__achievement-title-item-text {
        font-size: 16px;
        line-height: 24px;
    }

    .p-home__achievement-title-item {
        height: 150px;
    }

    .main-content {
        padding-top: 20px;
    }

    #project .main-content {
        padding-top: 0;
    }

    .about-containers {
        margin-top: 25px;
    }

    #about .title-center {
        margin-bottom: 25px;
    }

    .p-home__achievement-title-item-number {
        padding-top: 10px;
    }

    .main-content-solution {
        padding: 0;
    }

    .r-featured-solution-content {
        width: 300px;
    }

    .r-featured-solution {
        height: 400px;
    }

    .container-news {
        padding: 0 24px;
    }

    .content-news>.row {
        margin-left: 0;
        margin-right: 0;
    }

    #news .r-news-list .news-item>.row>div {
        padding: 0 24px;
    }

    #news .r-news-list .see-more {
        margin-bottom: 0;
    }

    .container-contact {
        padding: 0 24px !important;
    }

    .main-content-service {
        padding: 0;
        margin-top: 100px;
    }

    .main-content-service>.container {
        padding-left: 24px !important;
        padding-right: 24px !important;
    }

    /* .container-service {
        padding: 0 24px;
        margin: 0 24px;
    } */
    .r-service-box>.container {
        padding: 0 24px;
    }

    #web-development>.main-content {
        padding: 0;
        margin-top: 100px;
    }

    .items-service>.row {
        padding: 0 24px;
    }

    .item-box>.box {
        min-height: auto !important;
    }

    .item-box>.box>pre {
        margin-bottom: 0;
    }

    .item-box>.box>pre>p {
        margin-bottom: 0;
    }

    .field_s::before {
        content: "";
    }

    .r-fields>.container {
        padding-left: 24px;
        padding-right: 24px;
    }

    .r-project-list>.container {
        padding: 0 24px !important;
    }

    #footer>.container {
        padding: 24px 24px 0 24px !important;
    }

    .r-featured-news>.container {
        padding: 0 24px !important;
    }

    #about .r-office-tcom .col-right .tcom-image .slick-dots {
        display: none !important;
    }

    .r-what {
        margin-top: 0 !important;
    }

    .copyright {
        padding-top: 15px;
        display: block !important;
        text-align: center;
    }

    .copyright-left,
    .copyright-right {
        width: 100%;
    }

    .copyright-right>p {
        padding: 10px 80px !important;
        display: flex;
        justify-content: center;
        text-align: center !important;
    }

    .slider-home {
        padding-bottom: 0 !important;
    }

    .p-home__our-service-content-project-left {
        display: none;
    }

    #home .r-featured-project {
        padding: 0 24px !important;
    }

    #home .r-featured-project-dt {
        display: none;
    }

    .p-home__icon-item {
        text-align: center;
        width: calc(100%/3);
    }

    .p-home__icon-item:first-child>.text-home>p {
        margin-right: 15px;
        margin-left: 15px;
    }

    .p-home-title-service-abc {
        /* display: none; */
        max-width: 315px;
    }

    .p-home-title-service-abc>p {
        display: inline;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        /* or 143% */


        /* #686868 */

        color: #686868;
        max-width: 315px;
    }

    .p-home__our-service-title>div>p {
        display: block;

        max-width: 327px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        /* or 143% */


        /* #434343 */

        color: #434343;

    }

    .p-home__our-service-title>div {
        margin-top: 35px;
        padding-bottom: 25px;
    }


    .r-process>.container {
        padding: 0 24px !important;
    }

    #about {
        padding-top: 50px !important;
    }

    /* 
    .p-home__achievement-title>h1::after {
        position: absolute;
        content: "";
        background-color: #0063AA;
        width: 20px;
        height: 2px;
        border-radius: 30px;
        left: 50%;
        transform: translateX(-50%);
        bottom: 5px;

    } */
    #about .r-about-us .col-right .title-left {
        max-width: 100%;
    }

    .language_technology:nth-child(4)>.lg-img {
        width: 125px;
    }

    .webdetail {
        width: 80%;
    }

    #contact .r-member .title-center>div {
        max-width: 300px;
        font-size: 14px !important;
    }

    #service .r-service-box .box::before {
        left: 15px;
    }

    #about .r-top-banner {
        padding-top: 00px;
    }

    #home .r-contact {
        padding: 0 0 10px 0;
    }

    .tcomJapanMB {
        margin-top: 25px !important;
        display: block !important;
    }

    #about .title-center h3::after {
        bottom: -5px;
    }

    .p-news-details__related-group {
        display: block !important;
    }

    .p-news-details__related-time {
        margin: 0 !important;
    }

    #contact .r-member .box .logo img {
        padding: 0 !important;
    }

    #offshore .title-left-ser {
        margin-bottom: 40px;
    }


    #offshore .title-left-ser h3::after {
        bottom: 0;
    }

    #offshore .p-home__achievement-title>h1 {
        margin-bottom: 0 !important;
    }

    #offshore .p-home__achievement-title>h1::after {
        bottom: 0;
    }

    #offshore .r-service .box h5 {
        margin: 25px 0 0 0 !important;
    }

    #offshore .r-service .row>div:nth-child(1) h5 {
        margin: 25px 0 0 0 !important;
    }

    #service-details .r-strengths .title {
        margin: 20px 15px;
    }

    #service-details .items-service>.row>* {
        padding: 0 !important;
    }

    .p-home__our-service-read-icon>p {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        /* identical to box height, or 171% */


        color: #0063AA;
        margin-right: 8px;

    }

    .p-home__our-service-read-icon>img {
        width: 23px;
    }

    .p-home__our-service-item-icon {
        width: 32px;
        height: 32px;
    }

    .p-home__our-service-item-text>p {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;

        /* #121212 */

        color: #121212;
    }

    .p-home__our-service-item-des>p {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        /* or 167% */


        /* #686868 */

        color: #686868;
        margin-bottom: 5px;

    }

    .p-home__our-service-item-des>ul>li {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        /* or 167% */


        /* #686868 */

        color: #686868;
    }

    .p-home__our-service-methods-item-control {
        position: absolute;
        bottom: 38.62px;
        right: 35.09px;
    }

    .language_technologys>.p-home__our-service-item-text>p {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        /* identical to box height, or 150% */


        color: #0063AA;
        margin-bottom: 20px !important;
    }

    .language_technology>.p-home__our-service-item-text>p {
        margin-bottom: 20px !important;
    }

    .lg-and-tech-containers:nth-child(4) {
        width: 100%;
    }

    .oqjbfbab1bbfbaf {
        margin: 10px 0;
        height: 1px !important;
    }

    .oqjbfbab1bbfbaf>span {
        width: 135px;
        height: 1px;
    }



    .lg-and-tech-container>div>img {
        width: 35px;
    }

    .lg-and-tech-container {
        text-align: center;
        word-wrap: inherit;
    }

    .p-home__our-service-item-text>p {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 16px !important;
        line-height: 24px !important;
        /* identical to box height, or 150% */


        color: #0063AA;
        margin-bottom: 0px !important;
    }


    .container-img-project {
        transform: unset;
    }

    .koquuruanv {
        display: block;
    }

    .koquuruanv>.desc {
        font-family: 'Roboto' !important;
        font-style: normal !important;
        font-weight: 400 !important;
        font-size: 14px !important;
        line-height: 20px !important;
        /* or 143% */


        color: #FFFFFF !important;
        margin-bottom: 0;
    }

    .img-project-bg>div>h1 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;

        color: #FFFFFF;
    }

    .img-project-bg>div>p {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 600;
        font-size: 10px;
        line-height: 12px;
        /* or 120% */
        color: #FFFFFF;
    }

    .img-project-img {
        width: 120px;
        height: 160px;
    }

    #home .r-featured-project .owl-nav {
        display: none;
    }

    #home .read-more {
        display: none;
    }

    .container-img-project {
        max-width: 100%;
    }

    .img-project-bg>div {
        padding: 0 4px;
        bottom: 0px;
    }

    .img-project-bg>div>h1 {
        margin-bottom: 5px;
    }

    .img-project-bg {
        height: 70%;
    }

    #i182ywehehqehq {
        height: 160px;
    }

    #home #i182ywehehqehq .slick-slide {
        width: 120px !important;
        height: 160px !important;
        margin: 0 4px;
    }

    .member-system-items {
        display: block;
    }

    #contact .r-contact-form {
        margin-bottom: 0;
    }

    #contact .r-contact-form .form {
        padding: 0;
    }

    .member-system-item {
        margin-bottom: 32px;
    }

    .member-system-container {
        margin-bottom: 20px;
        padding: 0 12px;
    }

    .container-img-project-mb>.title-left>h3 {
        font-family: 'Roboto' !important;
        font-style: normal !important;
        font-weight: 900 !important;
        font-size: 18px !important;
        line-height: 21px !important;
        /* identical to box height */


        color: #FFFFFF !important;
    }

    .iaihbaj1jsbdfbs {
        flex: 0 0 calc(100%/2 - 8px);
        margin: 4px;
        height: 260px;
        max-height: 260px;
        padding: 10px;
    }

    .r-featured-project-mb {
        display: block;
        padding: 33px 0 38px 0;
    }

    #home .r-customer .title-center p {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        /* or 143% */
        text-align: center;
        color: #676767;
        max-width: 305px;
    }

    .ndahhqheq {
        max-width: 100%;
    }

    #about .r-office-tcom .col-left p {
        margin-bottom: 0;
    }

    #about .r-office-tcom .col-left .j2eqwuueeqwieqwueqweq::before {
        width: 13.99px;
        height: 1.17px;
        bottom: -5px !important;
        left: 50%;
        transform: translateX(-50%);
    }

    #home .r-customer .container-fluid .brands-list {
        padding: 0;
    }

    .dnhuqjsnfnf {
        display: none;
    }

    .diqwfnsasdnasdnad {
        display: block;
    }

    #contact .r-contact-form .info p {

        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        /* or 143% */


        color: #686868;
    }

    .member-system-item iframe {
        width: 100%;
    }

    .text-mb-slide {
        margin: auto;
    }





    #home .r-customer .container-fluid .brands-list li:nth-child(14) img {
        width: 50%;
    }


    #home .r-customer .container-fluid .brands-list li:nth-child(17) img {
        width: 80%;
    }

    #home .r-customer .container-fluid .brands-list li:nth-child(19) img {
        width: 80%;
    }

    #home .r-customer .container-fluid .brands-list li:nth-child(13) {
        flex: 0 0 calc(100%/4);
    }

    #home .r-customer .container-fluid .brands-list li:nth-child(14) {
        flex: 0 0 calc(100%/4);
    }

    #home .r-customer .container-fluid .brands-list li:nth-child(15) {
        flex: 0 0 calc(100%/4);
    }

    #home .r-customer .container-fluid .brands-list li:nth-child(16) {
        flex: 0 0 calc(100%/4);
    }


    .oqijfajajada-left,
    .jjqjfsasfbshsafsfashf {
        width: 100% !important;
        height: 100% !important;
    }

    .form-group-title {
        display: none;
    }

    #contact .r-contact-form .form .custom-file-label::after {
        padding: 0 15px 0 45px;
    }

    #contact .r-contact-form .form .custom-file-label {
        text-align: left;
    }

    .lg-and-tech-containers:nth-child(1)>p {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        /* identical to box height */


        color: #000000;

    }

    .lg-and-tech-containers:nth-child(2)>p {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        /* identical to box height */


        color: #000000;

    }

    .lg-and-tech-containers:nth-child(3)>p {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        /* identical to box height */


        color: #000000;

    }

    .lg-and-tech-containers:nth-child(1)>div>img {
        width: 60px !important;
    }

    .lg-and-tech-containers:nth-child(2)>div>img {
        width: 50px !important;
    }

    .lg-and-tech-containers:nth-child(3)>div>img {
        width: 60px !important;
    }

    .hsadhadh1237dhas {
        padding: 0 !important;
        display: flex;
        justify-content: center;
    }

    .jdsadqwyeadas {
        width: 338px !important;
        height: 196.63px !important;
    }

    .language_technology {
        margin-right: 0 !important;
        margin-left: 0 !important;
        flex: 0 0 100%;
    }

    .language_technologys {
        width: 100%;
    }

    .p-home__our-service-item-more>p {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        /* identical to box height, or 171% */


        color: #0063AA;
    }

    .ehjwbeb-dt {
        display: none;
    }

    .ehjwbeb-mb {
        display: block;
    }

    .u12uahdasbdabd {

        width: calc(100% - 30px);
        margin: 0 15px;

    }

    .a2u32u3quajda {


        font-family: 'Roboto' !important;
        font-style: normal !important;
        font-weight: 700 !important;
        font-size: 14px !important;
        line-height: 16px !important;

        /* #121212 */

        color: #121212 !important;

    }


}

@media (max-width: 374px) {
    .slide-feature-mb {
        width: 115%;
    }

    .r-process .col-right ul li .content .text span {
        position: relative;
    }

    .iaihbaj1jsbdfbs {
        height: 350px;
        max-height: 350px;
    }
}

@media (min-width:740px) and (max-width:1120px) {

    .oqijfajajada-left {
        /* flex: 0 0 100%; */
        width: unset !important;
        height: unset !important;
    }

    .jj12dhw1hfasd {
        flex: 0 0 100% !important;
        width: 100% !important;
    }

    .njuuqeqwehqeheqwheq {
        margin-top: 20px !important;
        justify-content: space-between;

    }

    .jjqjfsasfbshsafsfashf {
        flex: 0 0 49% !important;
    }

    #news .r-featured-news .content .col-right .custom-padding {
        margin-bottom: 0;
        border-radius: 0px;
    }


}

@media (min-width:1200px) and (max-width:1380px) {
    .p-home__slider__content {
        left: 55%;
    }

    .p-home__text>p {
        width: 900px !important;
        margin-right: auto;
        margin-left: auto;
        font-size: 28px;
    }
}




@media (min-width:1500px) and (max-width:1730px) {
    .p-home__slider__content {
        left: 55%;
    }
}

@media (min-width:1381px) and (max-width:1499px) {
    .p-home__slider__content {
        left: 55%;
    }
}


@media (min-width: 740px) and (max-width: 1200px) {
    .ndahhqheq {
        max-width: 100%;
    }
}

@media (max-width: 1024px) {
    .p-project-title-service-abc-mb {
        display: block !important;
    }

    .p-project-title-service-abc-dt {
        display: none;
    }

    .oadisjdh22 {
        width: 80% !important;
    }

    .r-process .col-right ul {
        margin-top: 100px !important;

    }

    /* .r-process .col-left p {
        top: 80% !important;
    } */


    #offshore .r-process .col-right ul {
        margin-top: 100px !important;

    }


}

@media (max-width: 991px) {

    #offshore .r-process .col-left p {
        top: 80% !important;
    }
}

@media (max-width: 768px) {
    .r-process .col-left p {
        right: 0;
        top: 50% !important;
    }

    .r-process .col-left h3 {
        right: 0;
    }


    #offshore .r-process .col-left p {
        right: 0;
        top: 75% !important;
    }

    #offshore .r-process .col-left h3 {
        right: 0;
    }

    #offshore .r-process .col-left p {
        right: 0;
    }
}

@media (max-width: 767px) {

    #offshore .r-process .col-left p {
        right: 0;
        top: 50% !important;
    }

    #offshore .r-process .col-left p {
        right: 0;
    }
}



@media (min-width: 1250px) {
    .dahqwuyaydaw>div {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }
}

@media (max-width: 1624px) {
    .pagi-slick-f {
        display: none !important;
    }

    #offshore .r-service .row>div:nth-child(1) h5 {
        margin: 25px 0 0 0;
    }

    #offshore .r-service .box h5 {
        margin: 24px 25px 0 25px;
    }
}